/* ******************************************************  Imports  ****************************************************** */
import { useTranslation } from 'react-i18next';
import { AboutPeopleStyles } from './AboutPeople.styles';
import { PEOPLE } from './people.data';
import { Person } from './person/Person';

/* ******************************************************  Component  ****************************************************** */
export const AboutPeople = () => {
  /* ******************************************************  State  ****************************************************** */
  const { t } = useTranslation();

  /* ******************************************************  Template  ****************************************************** */
  return (
    <AboutPeopleStyles.PeopleContainer>
      <AboutPeopleStyles.Content>
        {/* <AboutPeopleStyles.PeopleGroupContainer>
          <h2 className="mainText">{t('about.management.title')}</h2>
          <AboutPeopleStyles.DescriptionText>
            {t('about.management.text')}
          </AboutPeopleStyles.DescriptionText>

          {PEOPLE.management.map((person) => (
            <Person key={person.name + person.job} {...person} />
          ))}
        </AboutPeopleStyles.PeopleGroupContainer> */}

        <AboutPeopleStyles.PeopleGroupContainer>
          <h2 className="mainText">{t('about.engineers.title')}</h2>
          <AboutPeopleStyles.DescriptionText>
            {t('about.engineers.text')}
          </AboutPeopleStyles.DescriptionText>

          {PEOPLE.engineers.map((person) => (
            <Person key={person.name + person.job} {...person} />
          ))}
        </AboutPeopleStyles.PeopleGroupContainer>

        <AboutPeopleStyles.PeopleGroupContainer>
          <h2 className="mainText">{t('about.creative.title')}</h2>
          <AboutPeopleStyles.DescriptionText>
            {t('about.creative.text')}
          </AboutPeopleStyles.DescriptionText>

          {PEOPLE.creative.map((person) => (
            <Person key={person.name + person.job} {...person} />
          ))}
        </AboutPeopleStyles.PeopleGroupContainer>

        <AboutPeopleStyles.PeopleGroupContainer>
          <h2 className="mainText">{t('about.support.title')}</h2>
          <AboutPeopleStyles.DescriptionText>
            {t('about.support.text')}
          </AboutPeopleStyles.DescriptionText>

          {PEOPLE.support.map((person) => (
            <Person key={person.name + person.job} {...person} />
          ))}
        </AboutPeopleStyles.PeopleGroupContainer>
      </AboutPeopleStyles.Content>
    </AboutPeopleStyles.PeopleContainer>
  );
};
