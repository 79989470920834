/* ******************************************************  Imports  ****************************************************** */
import { useTranslation } from 'react-i18next';

import { StoreButtons } from '../store-buttons/StoreButtons';

import { AppsNavigationStyles } from './AppsNavigation.styles';
import phoneImage from '../../../../assets/images/apps/apps-2.png';
import { GetCustomTranslation } from '../../../shared/components/GetCustomTranslation';
import { useAppAnalytics } from 'analytics';
import { Fade } from 'react-awesome-reveal';

/* ******************************************************  Component  ****************************************************** */
export const AppsNavigation = () => {
  /* ******************************************************  Component  ****************************************************** */
  const { t } = useTranslation();

  /* *************************************************** APP INSIGHTS **************************************************** */
  const { trackEvent } = useAppAnalytics();

  const handleButtonClicked = (button: string) => {
    if (button === 'apple') {
      trackEvent({
        action: '[Mobile & Web App - Navigation Section] App Store Button Clicked',
        category: 'Mobile & Web App',
      });
    } else {
      trackEvent({
        action: '[Mobile & Web App - Navigation Section] Google Play Button Clicked',
        category: 'Mobile & Web App',
      });
    }
  };

  /* ******************************************************  Template  ****************************************************** */
  return (
    <AppsNavigationStyles.NavigationContainer>
      <AppsNavigationStyles.NavigationContent>
        <Fade direction="left" duration={1500} fraction={0.79} triggerOnce={true}>
          <AppsNavigationStyles.PhoneImageContainer>
            <AppsNavigationStyles.PhoneImage
              src={phoneImage}
              alt="3D smartphone model with ElectricRoutes application screen"
            />
          </AppsNavigationStyles.PhoneImageContainer>
        </Fade>

        <Fade direction="right" duration={1500} fraction={0.79} triggerOnce={true}>
          <AppsNavigationStyles.TextWrapper>
            <h2
              className="mainText black"
              dangerouslySetInnerHTML={GetCustomTranslation('apps.navigation.main')}
            ></h2>
            <p className="subText">{t('apps.navigation.sub')}</p>

            <StoreButtons buttonClicked={handleButtonClicked} />
          </AppsNavigationStyles.TextWrapper>
        </Fade>
      </AppsNavigationStyles.NavigationContent>
    </AppsNavigationStyles.NavigationContainer>
  );
};
