/* ******************************************************  Imports  ****************************************************** */
import styled from '@emotion/styled';
import { Button } from '@material-ui/core';

import { getColor } from '../../../../theme/colors/colors';

/* ******************************************************  Styles  ****************************************************** */
const CustomInput = styled.div`
  position: relative;
  width: 100%;
  background: ${getColor('whiteContrast', 0.1)};
  padding: 0.5rem 2.5rem;
  border-radius: 1.8rem;
  height: 7.2rem;
  display: flex;
  align-items: center;
  margin-bottom: 3.2rem;
  color: ${getColor('primaryContrast')};

  & * {
    color: ${getColor('primaryContrast')} !important;
  }

  &.blackText {
    background: ${getColor('whiteContrast')};
    color: ${getColor('adminBlack')};

    & *:not(.errorMessage) {
      color: ${getColor('adminBlack')} !important;
    }
  }

  &.textarea {
    height: 28rem;
    margin-bottom: 0;

    & .MuiFormControl-marginDense.customInput .MuiInput-formControl,
    & textarea {
      height: calc(28rem - 9rem) !important;
      font-size: 2rem !important;
    }

    & .MuiFormControl-marginDense.customInput .MuiInput-formControl {
      margin-top: 3rem;
    }

    & .MuiFormControl-marginDense.customInput .MuiInputLabel-marginDense {
      font-weight: 700;
      font-size: 2rem;
    }
  }

  &:focus-within {
    box-shadow: inset 0 0 0 0.1rem currentColor;
  }

  &.error {
    box-shadow: inset 0 0 0 0.2rem ${getColor('error')};

    .MuiFormControl-marginDense.customInput .MuiInputLabel-marginDense {
      color: ${getColor('error')} !important;
    }
  }
`;

const CustomSelectInput = styled(CustomInput)`
  padding: 0.5rem 0;
`;

const ErrorMessage = styled.div`
  &.errorMessage {
    position: absolute;
    color: ${getColor('error')} !important;
    left: 2.5rem;
    bottom: -2rem;
    font-size: 1.2rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 3.2rem;
`;

const SendButton = styled(Button)`
  &.erButton.noLink {
    min-width: 18.5rem !important;
    margin: 0;
  }
`;

const SentMessage = styled.span`
  display: flex;

  & > svg {
    margin-left: 1.5rem;
  }
`;

/* ******************************************************  Export  ****************************************************** */
export const ContactFormStyles = {
  CustomInput,
  CustomSelectInput,
  ErrorMessage,
  ButtonContainer,
  SendButton,
  SentMessage,
};
