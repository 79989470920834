import { Reveal } from 'react-awesome-reveal';
import { Button } from '@material-ui/core';
/* ******************************************************  Imports  ****************************************************** */
import styled from '@emotion/styled';
import { getColor } from '../../../theme/colors/colors';
import { getVar } from '../../../theme/ui-variables/ui-variables';

/* ******************************************************  Styles  ****************************************************** */
const OperatorsContainer = styled.div`
  width: 100%;
  min-height: 100%;
  padding: 16.5rem 0 24rem 0;
  background-color: ${getColor('adminBlack')};
  color: ${getColor('textGrey')};

  h2 {
    color: ${getColor('primaryContrast')};
  }
`;

const OperatorsHeading = styled.h2`
  width: 100%;
  max-width: 54rem;
  margin-left: calc((100% - ${getVar('maxWidth')}) / 2);
  margin-bottom: 19.5rem;

  @media screen and (max-width: 1150px) {
    max-width: ${getVar('maxWidth')};
  }
`;

const ApiContainer = styled.div`
  width: 100%;
  max-width: ${getVar('maxWidth')};
  margin: 0 auto;
  display: flex;
  align-items: normal;
  justify-content: space-between;

  & > div {
    &:first-of-type {
      display: flex;
    }

    &:last-of-type {
      width: 53.1rem;

      @media screen and (max-width: 1150px) {
        width: ${getVar('mobileContentWidth')};
        max-width: ${getVar('maxWidth')};
      }
    }
  }

  @media screen and (max-width: 1150px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ApiTextContainer = styled.div`
  width: 47.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 1150px) {
    width: ${getVar('mobileContentWidth')};
    max-width: ${getVar('maxWidth')};
    margin-bottom: 10rem;
  }
`;

const ButtonNoMargin = styled(Button)`
  margin: 0 !important;
`;

const LogoImage = styled.img`
  margin-bottom: 4rem;
  //For responsive > FullHD
  height: 5.3rem;
  //For responsive > FullHD
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const ApiListContainer = styled.div`
  width: 53.1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 1150px) {
    width: ${getVar('mobileContentWidth')};
    max-width: ${getVar('maxWidth')};
  }
`;

const ApiListWrapper = styled.div`
  width: 100%;
  height: 49.5rem;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 1150px) {
    margin-bottom: 5rem;
  }
`;

const ApiList = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 14.5rem;
  padding-right: 1.5rem;
  overflow: auto;

  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  ::-webkit-scrollbar {
    width: 0%;
    height: 0;
    background: transparent;

    &-thumb {
      background: transparent;
    }
  }
`;

const ListBlur = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 14.5rem;
  background: linear-gradient(
    180deg,
    ${getColor('adminBlack', 0)} 0%,
    ${getColor('adminBlack')} 73.31%
  );
  pointer-events: none;
`;

const PortalContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 19.5rem;
  display: flex;
  flex-direction: row-reverse;

  & > div:last-of-type {
    width: 100%;
    max-width: 49rem;
    margin-right: calc((100% - ${getVar('maxWidth')}) / 2);

    @media screen and (max-width: 1150px) {
      width: ${getVar('mobileContentWidth')};
      max-width: ${getVar('maxWidth')};
      margin: 15rem 0 0 calc((100% - ${getVar('maxWidth')}) / 2);
    }
  }

  @media screen and (max-width: 1150px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const PortalTextContainer = styled.div`
  width: 100%;
  max-width: 49rem;

  @media screen and (max-width: 1150px) {
    width: ${getVar('mobileContentWidth')};
    max-width: ${getVar('maxWidth')};
  }
`;

const LaptopImageReveal = styled(Reveal)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  //For responsive > FullHD
  height: 60.5rem;
  //For responsive > FullHD

  @media screen and (max-width: 1150px) {
    position: relative;
    left: unset;
    top: unset;
    transform: unset;
  }
  @media screen and (max-width: 700px) {
    height: unset;
    width: ${getVar('maxWidth')};
  }
`;

const LaptopImage = styled.img`
  //For responsive > FullHD
  height: 60.5rem;
  //For responsive > FullHD

  @media screen and (max-width: 700px) {
    height: unset;
    width: ${getVar('maxWidth')};
  }
`;

/* ******************************************************  Export  ****************************************************** */
export const HomeOperatorsStyles = {
  OperatorsContainer,
  OperatorsHeading,
  ApiContainer,
  ApiTextContainer,
  ApiListContainer,
  ApiListWrapper,
  ApiList,
  ListBlur,
  ButtonNoMargin,
  LogoImage,
  ButtonsWrapper,
  PortalContainer,
  PortalTextContainer,
  LaptopImageReveal,
  LaptopImage,
};
