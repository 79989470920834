export const environment = {
  services: {
    applicationService: process.env.REACT_APP_BASE_URL + '/application-api/v1',
  },
  xApiKey: process.env.REACT_APP_X_API_KEY,
  instrumentationKey: process.env.REACT_APP_APPINSIGHTS_KEY,
  matomoSiteId: parseInt(process.env.REACT_APP_MATOMO_SITE_ID as string, 10),
  matomoUrlBase: process.env.REACT_APP_MATOMO_URL_BASE,
} as {
  services: {
    applicationService: string;
  };
  xApiKey: string;
  instrumentationKey: string;
  matomoSiteId: number;
  matomoUrlBase: string;
};
