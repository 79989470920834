/* ******************************************************  Imports  ****************************************************** */
import translationEN from '../../../locales/en/translation.json';
import translationDE from '../../../locales/de/translation.json';
import translationSR from '../../../locales/sr/translation.json';
import translationHU from '../../../locales/hu/translation.json';
import translationES from '../../../locales/es/translation.json';
import translationFR from '../../../locales/fr/translation.json';
import translationPL from '../../../locales/pl/translation.json';

/* ******************************************************  Resources  ****************************************************** */
export const LANGUAGE_RESOURCES = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
  sr: {
    translation: translationSR,
  },
  hu: {
    translation: translationHU,
  },
  es: {
    translation: translationES,
  },
  fr: {
    translation: translationFR,
  },
  pl: {
    translation: translationPL,
  },
};

/* ******************************************************  Model  ****************************************************** */
export type LanguageModel = keyof typeof LANGUAGE_RESOURCES;

/* ******************************************************  Languages  ****************************************************** */
export const LANGUAGES = Object.keys(LANGUAGE_RESOURCES) as LanguageModel[];
