/* ******************************************************  Imports  ****************************************************** */
import { Link, NavLink } from 'react-router-dom';
import { useAppAnalytics } from 'analytics';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';

import { HomeApplicationsStyles } from './HomeApplications.styles';

import backPhone from '../../../../assets/images/home/home-mobile-splash.png';
import frontPhone from '../../../../assets/images/home/home-mobile-app.png';
import webImage from '../../../../assets/images/home/home-web-planner.png';
import externalLinkIcon from '../../../../assets/images/home/home-application-external.svg';
import { APP_LINKS } from '../../../shared/appLinks';
import { checkSafari } from '../../../shared/checkSafari';
import { APP_ROUTES } from '../../../shared/appRoutes';
import Reveal, { Fade } from 'react-awesome-reveal';
import { HomeApplicationsAnimations } from './home-applications.animations';
import { useResize } from '../../../shared/hooks/useResize';

/* ******************************************************  Component  ****************************************************** */
export const HomeApplications = () => {
  /* ******************************************************  State  ****************************************************** */
  const { t } = useTranslation();

  const windowSize = useResize();

  /* *************************************************** APP INSIGHTS **************************************************** */
  const { trackEvent } = useAppAnalytics();

  /* ******************************************************  Template  ****************************************************** */
  return (
    <HomeApplicationsStyles.ApplicationsContainer>
      {/* ---------------------  Mobile App  --------------------- */}
      <HomeApplicationsStyles.MobileAppContainer>
        <HomeApplicationsStyles.MobileAppImageWrapper>
          <Reveal
            keyframes={HomeApplicationsAnimations.slideInRight}
            duration={1500}
            triggerOnce={true}
            fraction={0.6}
          >
            <HomeApplicationsStyles.BackPhoneImage
              src={backPhone}
              alt="3D smartphone model with ElectricRoutes Application splash screen"
            />
          </Reveal>

          <Reveal
            keyframes={HomeApplicationsAnimations.slideInRightTransY}
            duration={1500}
            triggerOnce={true}
            fraction={0.4}
            className="frontPhone"
          >
            <HomeApplicationsStyles.FrontPhoneImage
              src={frontPhone}
              alt="3D smartphone model with ElectricRoutes Application route screen"
            />
          </Reveal>
        </HomeApplicationsStyles.MobileAppImageWrapper>

        <Fade direction="right" duration={1000} triggerOnce={true} fraction={0.7}>
          <HomeApplicationsStyles.MobileAppTextWrapper>
            <p className="topText">{t('home.applications.mobile.top')}</p>
            <h2 className="mainText">{t('home.applications.mobile.main')}</h2>
            <p className="subText">{t('home.applications.mobile.sub')}</p>

            <HomeApplicationsStyles.ButtonsWrapper>
              <Fade direction="down" delay={500} duration={1000} triggerOnce={true}>
                <Button
                  className="erButton"
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    trackEvent({
                      action: '[Home - Mobile App Section] Check Out In Stores Clicked',
                      category: 'Home',
                    })
                  }
                >
                  <a
                    href={checkSafari() ? APP_LINKS.apple : APP_LINKS.android}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('home.applications.mobile.button.primary')}
                  </a>
                </Button>
              </Fade>

              <Fade direction="down" delay={1000} duration={1000} triggerOnce={true}>
                <Button
                  className="erButton"
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    trackEvent({
                      action: '[Home - Mobile App Section] View Features Clicked',
                      category: 'Home',
                    })
                  }
                >
                  <NavLink to={APP_ROUTES.apps}>
                    {t('home.applications.mobile.button.secondary')}
                  </NavLink>
                </Button>
              </Fade>
            </HomeApplicationsStyles.ButtonsWrapper>
          </HomeApplicationsStyles.MobileAppTextWrapper>
        </Fade>
      </HomeApplicationsStyles.MobileAppContainer>

      {/* ---------------------  Web App  --------------------- */}
      <HomeApplicationsStyles.WebAppContainer>
        <Fade direction="left" duration={1000} triggerOnce={true} fraction={0.7}>
          <HomeApplicationsStyles.WebAppTextContainer>
            <p className="topText">{t('home.applications.web.top')}</p>
            <h2 className="mainText">{t('home.applications.web.main')}</h2>
            <p className="subText">{t('home.applications.web.sub')}</p>

            <HomeApplicationsStyles.ButtonsWrapper>
              <Fade direction="down" delay={500} duration={1000} triggerOnce={true}>
                <Button
                  className="erButton"
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    trackEvent({
                      action: '[Home - Web App Section] Open Web Planner Clicked',
                      category: 'Home',
                    })
                  }
                >
                  <a href={APP_LINKS.webPlanner} target="_blank" rel="noopener noreferrer">
                    {t('home.applications.web.button.primary')}
                    <img src={externalLinkIcon} alt="Open external link icon" />
                  </a>
                </Button>
              </Fade>

              <Fade direction="down" delay={1000} duration={1000} triggerOnce={true}>
                <Button
                  className="erButton"
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    trackEvent({
                      action: '[Home - Web App Section] Find Out More Clicked',
                      category: 'Home',
                    })
                  }
                >
                  <Link to={APP_ROUTES.apps + '#webPlanner'}>
                    {t('home.applications.web.button.secondary')}
                  </Link>
                </Button>
              </Fade>
            </HomeApplicationsStyles.ButtonsWrapper>
          </HomeApplicationsStyles.WebAppTextContainer>
        </Fade>

        <HomeApplicationsStyles.LaptopRevealContainer
          keyframes={
            windowSize.width > 1150
              ? HomeApplicationsAnimations.slideInLeft
              : HomeApplicationsAnimations.slideInLeftNoY
          }
          duration={1000}
          triggerOnce={true}
        >
          <HomeApplicationsStyles.WebAppLaptopImage
            src={webImage}
            alt="Laptop mockup with ElectricRoutes web app screen"
          />
        </HomeApplicationsStyles.LaptopRevealContainer>
      </HomeApplicationsStyles.WebAppContainer>
    </HomeApplicationsStyles.ApplicationsContainer>
  );
};
