import { IconButton } from '@material-ui/core';
/* ******************************************************  Imports  ****************************************************** */
import styled from '@emotion/styled';

import { NavLink } from 'react-router-dom';

import { getColor } from '../../../../theme/colors/colors';
import { getVar } from '../../../../theme/ui-variables/ui-variables';

/* ******************************************************  Styles  ****************************************************** */
const LanguageAbsoluteContainer = styled.div`
  margin-left: calc((100% - ${getVar('maxWidth')}) / 2);
  color: ${getColor('primaryContrast')};

  &.blue {
    color: ${getColor('primary')};
  }
`;

const MobileHeaderContainer = styled.div`
  width: 100%;
  height: ${getVar('headerHeight')};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1400;
  pointer-events: none;

  & a,
  & button {
    pointer-events: auto;
  }

  &.scrolled {
    position: fixed;
    background: ${getColor('primary')};
    top: -10rem;

    &.show {
      transition: 0.2s;
      top: 0;
    }

    &.white {
      background: ${getColor('background')};
    }

    &.black {
      background: ${getColor('adminBlack')};
    }

    height: 10rem;
  }
`;

const MobileHeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: ${getVar('maxWidth')};
  margin: 0 auto;
`;

const LogoImage = styled.img`
  //For responsive > FullHD
  height: 5.2rem;
  //For responsive > FullHD

  &.white {
    filter: brightness(0) invert(1);
  }

  @media screen and (max-width: 360px) {
    height: 4.5rem;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const MobileHeaderLink = styled(NavLink)`
  font-size: 1.6rem;
  font-weight: 700;
  color: ${getColor('primaryContrast')};
  padding: 3rem calc((100% - ${getVar('maxWidth')}) / 2);
  text-transform: uppercase;
  width: 100%;

  &.blueText {
    color: ${getColor('primary')};

    &:hover {
      background-color: ${getColor('primary', 0.1)};
    }

    &.active {
      box-shadow: inset 0.3rem 0 0 ${getColor('primary')};
      background-color: ${getColor('primary', 0.1)};
    }
  }

  &:hover {
    background-color: ${getColor('primaryContrast', 0.1)};
  }

  &.active {
    box-shadow: inset 0.3rem 0 0 ${getColor('primaryContrast')};
    background-color: ${getColor('primaryContrast', 0.1)};
  }
`;

const MobileHeaderLinkButton = styled.button`
  font-size: 1.6rem;
  font-weight: 700;
  color: ${getColor('primaryContrast')};
  box-shadow: inset 0 0 0 0.1rem ${getColor('primaryContrast', 0.3)} !important;
  border-radius: ${getVar('headerLinkSpacing')};
  padding: ${getVar('headerLinkSpacing')}
    calc(${getVar('headerLinkSpacing')} * 2);
  background-color: transparent !important;
  width: auto;
  margin: 3rem 0 10rem calc((100% - var(--maxWidth)) / 2);
  cursor: pointer;
  background: transparent;

  &:hover {
    box-shadow: inset 0 0 0 0.2rem ${getColor('primaryContrast', 1)} !important;
  }

  &.blueText {
    color: ${getColor('primary')};
    box-shadow: inset 0 0 0 0.1rem ${getColor('primary', 1)} !important;

    &:hover {
      box-shadow: inset 0 0 0 0.2rem ${getColor('primary', 1)} !important;
    }
  }

  @media screen and (max-height: 640px) {
    margin-bottom: 5rem;
  }
`;

const MobileHeaderLinkText = styled.span``;

const StyledToggleButton = styled(IconButton)`
  &.MuiIconButton-root {
    width: 6rem;
    height: 6rem;
    color: ${getColor('primaryContrast')};

    &.blueText {
      color: ${getColor('primary')};
    }

    svg {
      width: 4rem;
      height: 4rem;
    }
  }
`;

/* ******************************************************  Export  ****************************************************** */
export const MobileHeaderStyles = {
  LanguageAbsoluteContainer,
  MobileHeaderContainer,
  MobileHeaderContent,
  LogoImage,
  LinkContainer,
  MobileHeaderLink,
  MobileHeaderLinkButton,
  MobileHeaderLinkText,
  StyledToggleButton,
};
