/* ******************************************************  Imports  ****************************************************** */
import { DrawerClassKey, StyleRules } from '@material-ui/core';
import { getColor } from '../../colors/colors';
import { getVar } from '../../ui-variables/ui-variables';

/* ******************************************************  Override  ****************************************************** */
export const CustomDrawer: Partial<StyleRules<DrawerClassKey, {}>> | undefined =
  {
    root: {
      '&.headerDrawer': {
        '& .MuiDrawer-paper': {
          backgroundColor: getColor('primary'),
          padding: `${getVar('headerHeight')} 0 5rem 0`,
          // height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        },

        '&.scrolled .MuiDrawer-paper': {
          padding: `10rem 0 5rem 0`,
        },

        '&.white .MuiDrawer-paper': {
          backgroundColor: getColor('background'),
        },

        '&.black .MuiDrawer-paper': {
          backgroundColor: getColor('adminBlack'),
        },
      },
    },
  };
