/* ******************************************************  Imports  ****************************************************** */
import { HomeApplications } from './applications/HomeApplications';
import { HomeLanding } from './landing/HomeLanding';
import { HomeOperators } from './operators/HomeOperators';

/* ******************************************************  Component  ****************************************************** */
export const HomePage = () => {
  /* ******************************************************  Template  ****************************************************** */
  return (
    <>
      <HomeLanding />
      <HomeApplications />
      <HomeOperators />
    </>
  );
};
