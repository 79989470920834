/* ******************************************************  Imports  ****************************************************** */
import ImageChargePlanner from '../../../../assets/images/b2b/api-icons/charge-planner-api.svg';
import ImageDirections from '../../../../assets/images/b2b/api-icons/directions-api.svg';
import ImageElevation from '../../../../assets/images/b2b/api-icons/elevation-api.svg';
import ImageChargingStation from '../../../../assets/images/b2b/api-icons/charging-station-api.svg';
import ImageDistanceMatrix from '../../../../assets/images/b2b/api-icons/distance-matrix-api.svg';
import ImageGeoCoding from '../../../../assets/images/b2b/api-icons/geo-coding-api.svg';
// import ImageRouteAlong from '../../../../assets/images/b2b/api-icons/route-along-api.svg';
import ImageTileService from '../../../../assets/images/b2b/api-icons/tile-service-api.svg';

/* ******************************************************  Export  ****************************************************** */
export const B2B_APIS_DATA: {
  icon: string;
  title: string;
  text: string;
}[] = [
  {
    icon: ImageChargePlanner,
    title: 'b2b.apis.chargePlanner.title',
    text: 'b2b.apis.chargePlanner.text',
  },
  {
    icon: ImageDirections,
    title: 'b2b.apis.directions.title',
    text: 'b2b.apis.directions.text',
  },
  {
    icon: ImageElevation,
    title: 'b2b.apis.elevation.title',
    text: 'b2b.apis.elevation.text',
  },
  {
    icon: ImageChargingStation,
    title: 'b2b.apis.chargingStation.title',
    text: 'b2b.apis.chargingStation.text',
  },
  {
    icon: ImageDistanceMatrix,
    title: 'b2b.apis.distanceMatrix.title',
    text: 'b2b.apis.distanceMatrix.text',
  },
  {
    icon: ImageGeoCoding,
    title: 'b2b.apis.geoCoding.title',
    text: 'b2b.apis.geoCoding.text',
  },
  // {
  //   icon: ImageRouteAlong,
  //   title: 'b2b.apis.routeAlong.title',
  //   text: 'b2b.apis.routeAlong.text',
  // },
  {
    icon: ImageTileService,
    title: 'b2b.apis.tileService.title',
    text: 'b2b.apis.tileService.text',
  },
];
