/* ******************************************************  Imports  ****************************************************** */
import { B2BLanding } from './landing/B2BLanding';
import { B2BApis } from './apis/B2BApis';
import { B2BOperator } from './operator/B2BOperator';
import { B2BWhiteLabel } from './white-label/B2BWhiteLabel';

/* ******************************************************  Component  ****************************************************** */
export const B2BPage = () => {
  /* ******************************************************  Template  ****************************************************** */
  return (
    <>
      <B2BLanding />
      <B2BApis />
      <B2BOperator />
      <B2BWhiteLabel />
    </>
  );
};
