import appleButtonIcon from 'assets/images/apple-store.svg';
import { APP_LINKS } from 'core/shared/appLinks';

export const AppleButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <a href={APP_LINKS.apple} target="_blank" rel="noopener noreferrer" onClick={onClick}>
      <img src={appleButtonIcon} alt="Apple store download button link" />
    </a>
  );
};
