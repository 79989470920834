/* ******************************************************  Imports  ****************************************************** */
import { NotFoundStyles } from './NotFound.styles';

import world from '../../../assets/images/404-world.svg';
import { Button } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { APP_ROUTES } from '../../shared/appRoutes';
import { useTranslation } from 'react-i18next';
import { APP_LINKS } from '../../shared/appLinks';

/* ******************************************************  Component  ****************************************************** */
export const NotFoundPage = () => {
  const { t } = useTranslation();

  /* ******************************************************  Template  ****************************************************** */
  return (
    <NotFoundStyles.NotFoundContainer>
      <NotFoundStyles.NotFoundContent>
        <img src={world} alt="" />
        <p>{t('404.top')}</p>
        <h2 className="mainText thin">{t('404.main')}</h2>

        <NotFoundStyles.ButtonsWrapper>
          <Button variant="outlined" className="erButton">
            <NavLink to={APP_ROUTES.home}>{t('404.button.secondary')}</NavLink>
          </Button>
          <Button variant="contained" className="erButton">
            <a
              href={APP_LINKS.webPlanner}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('404.button.primary')}
            </a>
          </Button>
        </NotFoundStyles.ButtonsWrapper>
      </NotFoundStyles.NotFoundContent>
    </NotFoundStyles.NotFoundContainer>
  );
};
