/* ******************************************************  Imports  ****************************************************** */
import styled from '@emotion/styled';

import { getColor } from '../../../theme/colors/colors';
import { getVar } from '../../../theme/ui-variables/ui-variables';

/* ******************************************************  Styles  ****************************************************** */
const NavigationContainer = styled.div`
  background-color: ${getColor('background')};
  padding: 19rem 0 25rem 0;
`;

const NavigationContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: ${getVar('maxWidth')};
  margin: 0 auto;

  @media screen and (max-width: 1150px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TextWrapper = styled.div`
  width: 100%;
  max-width: 45rem;

  @media screen and (max-width: 1150px) {
    width: ${getVar('mobileContentWidth')};
    max-width: ${getVar('maxWidth')};
  }
`;

const PhoneImageContainer = styled.div`
  position: relative;
  width: 63.5rem;

  @media screen and (max-width: 1150px) {
    margin-bottom: 5rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

const PhoneImage = styled.img`
  margin-left: -10rem;
  //For responsive > FullHD
  height: 72rem;
  //For responsive > FullHD

  @media screen and (max-width: 1150px) {
    margin-left: unset;
  }

  @media screen and (max-width: 800px) {
    height: 67rem;
  }

  @media screen and (max-width: 700px) {
    height: unset;
    width: 100%;
  }
`;

/* ******************************************************  Export  ****************************************************** */
export const AppsNavigationStyles = {
  NavigationContainer,
  NavigationContent,
  TextWrapper,
  PhoneImageContainer,
  PhoneImage,
};
