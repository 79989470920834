import { createContext, Dispatch, SetStateAction } from 'react';
import { LanguageModel } from './shared/components/language-switcher/language.data';

export interface LanguageContextModel {
  languageContextValue: LanguageModel;
  setLanguageContextValue: Dispatch<SetStateAction<LanguageModel>> | any;
}

export const LanguageContext = createContext<LanguageContextModel>({
  languageContextValue: 'en',
  setLanguageContextValue: null,
});
