/* ******************************************************  Imports  ****************************************************** */
import { useContext } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Menu } from '@material-ui/core';

import { APP_ROUTES } from '../../appRoutes';

import { LANGUAGES, LanguageModel } from './language.data';
import { LanguageContext } from '../../../language-context';
import { LanguageSwitcherStyles } from './LanguageSwitcher.styles';

/* ******************************************************  Component  ****************************************************** */
export const LanguageSwitcher = (props: any) => {
  /* ******************************************************  State  ****************************************************** */
  const { i18n } = useTranslation();

  const { languageContextValue, setLanguageContextValue } =
    useContext(LanguageContext);

  const [lang, setLang] = useState<LanguageModel>(languageContextValue);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { t } = useTranslation();

  /* ******************************************************  useEffect()  ****************************************************** */
  useEffect(() => {
    setLang(languageContextValue);
  }, [languageContextValue]);

  /* ******************************************************  useHistory()  ****************************************************** */
  const history = useHistory();

  const navigateToLegalPages = (languageString: LanguageModel) => {
    const path = history.location.pathname;

    if (
      path.indexOf(APP_ROUTES.imprint) !== -1 ||
      path.indexOf(APP_ROUTES.privacyPolicy) !== -1
    ) {
      history.push(
        path.split('-')[0] + '-' + (languageString === 'de' ? 'de' : 'en')
      );
    }
  };

  /* ******************************************************  Logic  ****************************************************** */
  const switchLanguage = (value: LanguageModel) => {
    setLang(value);
    i18n.changeLanguage(value);
    setLanguageContextValue(value);
    navigateToLegalPages(value);

    setAnchorEl(null);

    props.languageChanged(value);
  };

  /* ******************************************************  Template  ****************************************************** */
  return (
    <LanguageSwitcherStyles.SwitcherContainer>
      <LanguageSwitcherStyles.StyledButton
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        size="medium"
      >
        {t(`language.${lang}`)}
      </LanguageSwitcherStyles.StyledButton>

      <Menu
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {(LANGUAGES as LanguageModel[]).sort().map((lng) => (
          <LanguageSwitcherStyles.StyledMenuItem
            key={lng}
            onClick={() => switchLanguage(lng)}
            className={lang === lng ? 'active' : ''}
          >
            {t(`language.${lng}`)}
          </LanguageSwitcherStyles.StyledMenuItem>
        ))}
      </Menu>
    </LanguageSwitcherStyles.SwitcherContainer>
  );
};
