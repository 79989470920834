/* ******************************************************  Imports  ****************************************************** */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';

import { APP_ROUTES } from '../../../appRoutes';
import { ContactFormModel, ContactFormTopicModel } from './contact-form.model';
import { ContactFormStyles } from './ContactForm.styles';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { contactSupport } from '../../../../services/http/contact-support.service';
import { errorService } from '../../../../services/error.service';
import { contactFormTopicService } from '../../../../services/contact-form-topic.service';
import { useRef } from 'react';

/* ******************************************************  Component  ****************************************************** */
export const ContactForm = () => {
  /* ******************************************************  State  ****************************************************** */
  const [formState, setFormState] = useState<ContactFormModel>({
    fullName: '',
    emailAddress: '',
    emailText: '',
    topic: 'OTHER',
  });

  const [formErrors, setFormErrors] = useState({
    fullName: false,
    emailAddress: false,
    emailText: false,
    topic: false,
  });

  const [formFieldDirty, setFormFieldDirty] = useState({
    fullName: false,
    emailAddress: false,
    emailText: false,
  });

  const [buttonState, setButtonState] = useState({
    sent: false,
    loading: false,
  });

  const nameFieldRef = useRef<any>(null);

  const { t } = useTranslation();

  /* ******************************************************  useLocation()  ****************************************************** */
  const location = useLocation();

  useEffect(() => {
    resetForm(location.hash === '#contactUs');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /* ******************************************************  useEffect()  ****************************************************** */
  useEffect(() => {
    const sub = contactFormTopicService.topic.subscribe(
      (topicValue: ContactFormTopicModel) => {
        setFormState({ ...formState, topic: topicValue });
        nameFieldRef.current.focus();
      }
    );
    return () => {
      sub.unsubscribe();
    };
  }, [formState]);

  /* ******************************************************  Change handlers  ****************************************************** */
  // ------------------- Full Name
  const handleNameChange = (value: string) => {
    checkForFullnameErrors(false, value.trim());

    setFormState({ ...formState, fullName: value });
  };

  // ------------------- Email Address
  const handleEmailChange = (value: string) => {
    const trimmedValue = value.trim();

    checkForEmailAddressErrors(false, trimmedValue);

    setFormState({ ...formState, emailAddress: trimmedValue });
  };

  // ------------------- Topic
  const handleTopicChange = (value: typeof formState.topic) => {
    setFormState({ ...formState, topic: value });
  };

  // ------------------- Email Text
  const handleTextChange = (value: string) => {
    checkForEmailTextErrors(false, value.trim());

    setFormState({ ...formState, emailText: value });
  };

  // Reset Form
  const resetForm = (didGetNewDropdownValueBeforeReroute: boolean = false) => {
    setFormState({
      fullName: '',
      emailAddress: '',
      emailText: '',
      topic: didGetNewDropdownValueBeforeReroute ? formState.topic : 'OTHER',
    });

    setFormErrors({
      fullName: false,
      emailAddress: false,
      emailText: false,
      topic: false,
    });

    setFormFieldDirty({
      fullName: false,
      emailAddress: false,
      emailText: false,
    });
  };

  /* ******************************************************  Validation handlers  ****************************************************** */
  // ------------------- Full Name
  const checkForFullnameErrors = (isBlur: boolean, value?: string) => {
    const fieldValue = value || formState.fullName;

    // Check if form field is 'dirty'
    if (!formFieldDirty.fullName && isBlur) {
      setFormFieldDirty({ ...formFieldDirty, fullName: true });

      setFormErrors({
        ...formErrors,
        fullName: !fieldValue.trim(),
      });

      return;
    }

    // Error validation
    setFormErrors({
      ...formErrors,
      fullName: !fieldValue.trim() && formFieldDirty.fullName,
    });
    // Set Form State
    setFormState({ ...formState, fullName: fieldValue });
  };

  // ------------------- Email Address
  const checkForEmailAddressErrors = (isBlur: boolean, value?: string) => {
    const fieldValue = value || formState.emailAddress;

    const emailRegex = /\S+@\S+\.\S+/;

    // Check if form field is 'dirty'
    if (!formFieldDirty.emailAddress && isBlur) {
      setFormFieldDirty({ ...formFieldDirty, emailAddress: true });

      setFormErrors({
        ...formErrors,
        emailAddress: !emailRegex.test(fieldValue),
      });

      return;
    }

    // Error validation
    setFormErrors({
      ...formErrors,
      emailAddress: !emailRegex.test(fieldValue) && formFieldDirty.emailAddress,
    });
    // Set Form State
    setFormState({ ...formState, emailAddress: fieldValue });
  };

  // ------------------- Email Text
  const checkForEmailTextErrors = (isBlur: boolean, value?: string) => {
    const fieldValue = value || formState.emailText;

    // Check if form field is 'dirty'
    if (!formFieldDirty.emailText && isBlur) {
      setFormFieldDirty({ ...formFieldDirty, emailText: true });
      // Error validation
      setFormErrors({
        ...formErrors,
        emailText: !fieldValue.trim(),
      });

      return;
    }

    // Error validation
    setFormErrors({
      ...formErrors,
      emailText: !fieldValue.trim() && formFieldDirty.emailText,
    });
    // Set Form State
    setFormState({ ...formState, emailText: fieldValue });
  };

  // Checks if any value inside of an object is false, null, ...
  // Needed for 'Submit' button
  const anyFalse = (object: any) => {
    for (var property in object) {
      if (!object[property]) {
        return true;
      }
    }

    return false;
  };

  const anyTrue = (object: any) => {
    for (var property in object) {
      if (object[property]) {
        return true;
      }
    }

    return false;
  };

  /* ******************************************************  Style logic  ****************************************************** */
  const getClasses = (starterClasses = '') => {
    const classes = [starterClasses];

    switch (location.pathname) {
      case APP_ROUTES.apps:
        classes.push('black');
        break;
      case APP_ROUTES.b2b:
        classes.push('white');
        break;
    }

    return classes.join(' ');
  };

  const getCustomInputClasses = (
    fieldName: 'fullName' | 'emailAddress' | 'emailText' | 'topic',
    starterClasses = ''
  ) => {
    const classes = [starterClasses];

    if (location.pathname === APP_ROUTES.apps) {
      classes.push('blackText');
    }

    if (formErrors[fieldName]) {
      classes.push('error');
    }

    return classes.join(' ');
  };

  const renderButtonContent = () => {
    if (buttonState.loading) {
      return <CircularProgress size={30} />;
    }

    if (buttonState.sent) {
      return (
        <ContactFormStyles.SentMessage>
          {t('contactForm.sent')} <CheckRoundedIcon />
        </ContactFormStyles.SentMessage>
      );
    }

    return t('contactForm.submit');
  };

  /* ******************************************************  API Call  ****************************************************** */
  const sendContactForm = async () => {
    setButtonState({ ...buttonState, loading: true });

    try {
      await contactSupport(formState);

      // Close loader and show 'Message sent'
      setButtonState({ sent: true, loading: false });

      // Reset form
      resetForm();

      // Remove 'Message sent'
      setTimeout(() => {
        setButtonState({ sent: false, loading: false });
      }, 4000);
    } catch (error) {
      setButtonState({ sent: false, loading: false });

      errorService.showError('contactForm.error.backend');

      // setButtonState({ sent: true, loading: false });
      // setTimeout(() => {
      //   setButtonState({ sent: false, loading: false });
      // }, 40000);
      // resetForm();
    }
  };

  /* ******************************************************  Template  ****************************************************** */
  return (
    <form>
      {/* ---------------------  NAME  --------------------- */}
      <ContactFormStyles.CustomInput
        className={getCustomInputClasses('fullName')}
      >
        <TextField
          inputRef={nameFieldRef}
          value={formState.fullName}
          fullWidth
          placeholder={'John Doe'}
          label={t('contactForm.name')}
          className="customInput"
          onChange={(event: any) => handleNameChange(event.target.value)}
          onBlur={() => checkForFullnameErrors(true)}
        />

        {formErrors.fullName ? (
          <ContactFormStyles.ErrorMessage className="errorMessage">
            {t('contactForm.error.name')}
          </ContactFormStyles.ErrorMessage>
        ) : null}
      </ContactFormStyles.CustomInput>

      {/* ---------------------  E-MAIL  --------------------- */}
      <ContactFormStyles.CustomInput
        className={getCustomInputClasses('emailAddress')}
      >
        <TextField
          value={formState.emailAddress}
          fullWidth
          placeholder={'johndoe@electricroutes.com'}
          label={t('contactForm.email')}
          className="customInput"
          onChange={(event: any) => handleEmailChange(event.target.value)}
          onBlur={() => checkForEmailAddressErrors(true)}
        />

        {formErrors.emailAddress ? (
          <ContactFormStyles.ErrorMessage className="errorMessage">
            {formState.emailAddress.trim()
              ? t('contactForm.error.mail.format')
              : t('contactForm.error.mail.required')}
          </ContactFormStyles.ErrorMessage>
        ) : null}
      </ContactFormStyles.CustomInput>

      {/* ---------------------  TOPIC  --------------------- */}
      <ContactFormStyles.CustomSelectInput
        className={getCustomInputClasses('topic')}
      >
        <FormControl fullWidth className="customInput customSelect">
          <InputLabel>{t('contactForm.topic')}</InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            value={formState.topic}
            onChange={(event: any) => handleTopicChange(event.target.value)}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
              className: getClasses('customSelectMenu'),
            }}
          >
            <MenuItem value={'ROUTING_APIS'}>{t('ROUTING_APIS')}</MenuItem>
            <MenuItem value={'OPERATOR_PORTAL'}>
              {t('OPERATOR_PORTAL')}
            </MenuItem>
            <MenuItem value={'WHITE_LABEL'}>{t('WHITE_LABEL')}</MenuItem>
            {/*
              //TODO: Unfinished Prod
            */}
            {/* <MenuItem value={'SDK'}>{t('SDK')}</MenuItem> */}
            <MenuItem value={'OTHER'}>{t('OTHER')}</MenuItem>
          </Select>
        </FormControl>
      </ContactFormStyles.CustomSelectInput>

      {/* ---------------------  TEXT  --------------------- */}
      <ContactFormStyles.CustomInput
        className={getCustomInputClasses('emailText', 'textarea')}
      >
        <TextField
          value={formState.emailText}
          fullWidth
          label={t('contactForm.text')}
          className="customInput"
          onChange={(event: any) => handleTextChange(event.target.value)}
          onBlur={() => checkForEmailTextErrors(true)}
          multiline
        />

        {formErrors.emailText ? (
          <ContactFormStyles.ErrorMessage className="errorMessage">
            {t('contactForm.error.text')}
          </ContactFormStyles.ErrorMessage>
        ) : null}
      </ContactFormStyles.CustomInput>

      {/* ---------------------  SUBMIT  --------------------- */}
      <ContactFormStyles.ButtonContainer>
        <ContactFormStyles.SendButton
          style={
            buttonState.sent ? { pointerEvents: 'none', opacity: '1' } : {}
          }
          variant="contained"
          className={getClasses('erButton noLink')}
          disabled={anyTrue(formErrors) || anyFalse(formState)}
          onClick={() => sendContactForm()}
        >
          {renderButtonContent()}
        </ContactFormStyles.SendButton>
      </ContactFormStyles.ButtonContainer>
    </form>
  );
};
