/* ******************************************************  Imports  ****************************************************** */
import styled from '@emotion/styled';
import { Reveal } from 'react-awesome-reveal';

import { getColor } from '../../../theme/colors/colors';
import { getVar } from '../../../theme/ui-variables/ui-variables';

/* ******************************************************  Styles  ****************************************************** */
const ApplicationsContainer = styled.div`
  background-color: ${getColor('whiteContrast')};
  padding: 25rem 0 36rem 0;

  @media screen and (max-width: 1150px) {
    /* margin-top: -15rem; */
    padding: 15rem 0 24rem 0; // was 0 top
  }
`;

const MobileAppContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: ${getVar('maxWidth')};
  margin: 0 auto;

  @media screen and (max-width: 1150px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const MobileAppImageWrapper = styled.div`
  position: relative;
  width: 51.5rem;

  & > div.frontPhone {
    animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    animation-fill-mode: both;
    position: absolute;
    top: calc(50% + 4.5rem);
    right: 0;
    transform: translateY(-50%);
    z-index: 2;

    //For responsive > FullHD
    height: 80.4rem;
    //For responsive > FullHD

    @media screen and (max-width: 1150px) {
      right: 7rem;
      height: 73.4rem;
    }
    @media screen and (max-width: 700px) {
      right: 12rem;
      height: 63.4rem;
    }
    @media screen and (max-width: 499px) {
      right: 0;
      height: 56.4rem;
    }
    @media screen and (max-width: 399px) {
      right: 5rem;
      height: 46.4rem;
    }

    & > img {
      transform: scale(1, 1) translateZ(0);
    }
  }

  @media screen and (max-width: 1150px) {
    margin: 0 auto 5rem auto;
  }

  @media screen and (max-width: 499px) {
    width: 272px; // 85vw of 320px screen (iPhone 5/SE)
    max-width: 272px;
  }
`;

const FrontPhoneImage = styled.img`
  //For responsive > FullHD
  height: 80.4rem;
  //For responsive > FullHD

  @media screen and (max-width: 1150px) {
    height: 73.4rem;
  }
  @media screen and (max-width: 700px) {
    height: 63.4rem;
  }
  @media screen and (max-width: 499px) {
    height: 56.4rem;
  }
  @media screen and (max-width: 399px) {
    height: 46.4rem;
  }
`;

const BackPhoneImage = styled.img`
  //For responsive > FullHD
  height: 97.6rem;
  //For responsive > FullHD

  @media screen and (max-width: 1150px) {
    height: 90.6rem;
  }
  @media screen and (max-width: 700px) {
    height: 80.6rem;
  }
  @media screen and (max-width: 499px) {
    height: 70.6rem;
  }
  @media screen and (max-width: 399px) {
    height: 60.6rem;
  }
`;

const MobileAppTextWrapper = styled.div`
  width: 100%;
  max-width: 45rem;

  @media screen and (max-width: 1150px) {
    width: ${getVar('mobileContentWidth')};
    max-width: ${getVar('maxWidth')};
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  img {
    margin-top: -0.5rem;
    //For responsive > FullHD
    height: 2.4rem;
    //For responsive > FullHD
  }
`;

const WebAppContainer = styled.div`
  width: 100%;
  position: relative;
  margin-top: 32.5rem;

  & > div:first-of-type {
    width: 100%;
    max-width: 46rem;
    margin-left: calc((100% - ${getVar('maxWidth')}) / 2);

    @media screen and (max-width: 1150px) {
      width: ${getVar('mobileContentWidth')};
      max-width: ${getVar('maxWidth')};
    }
  }

  @media screen and (max-width: 1150px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-top: 5rem;
  }
`;

const WebAppTextContainer = styled.div`
  width: 100%;
  max-width: 46rem;

  @media screen and (max-width: 1150px) {
    width: ${getVar('mobileContentWidth')};
    max-width: ${getVar('maxWidth')};
  }
`;

const LaptopRevealContainer = styled(Reveal)`
  position: absolute;
  right: 0;
  top: 50%;

  //For responsive > FullHD
  height: 70.8rem;
  //For responsive > FullHD

  @media screen and (max-width: 1150px) {
    position: relative;
    right: unset;
    top: unset;
    transform: unset;
    height: 63.8rem;
    margin-left: auto;
    margin-bottom: 5rem;
  }
  @media screen and (max-width: 700px) {
    height: unset;
    width: ${getVar('maxWidth')};
  }
`;

const WebAppLaptopImage = styled.img`
  //For responsive > FullHD
  height: 70.8rem;
  //For responsive > FullHD

  @media screen and (max-width: 1150px) {
    height: 63.8rem;
  }
  @media screen and (max-width: 700px) {
    height: unset;
    width: ${getVar('maxWidth')};
  }
`;

/* ******************************************************  Export  ****************************************************** */
export const HomeApplicationsStyles = {
  ApplicationsContainer,
  MobileAppContainer,
  MobileAppImageWrapper,
  FrontPhoneImage,
  BackPhoneImage,
  MobileAppTextWrapper,
  ButtonsWrapper,
  WebAppContainer,
  WebAppTextContainer,
  LaptopRevealContainer,
  WebAppLaptopImage,
};
