/* ******************************************************  Imports  ****************************************************** */
import { keyframes } from '@emotion/react';

/* ******************************************************  Animations  ****************************************************** */
const slideInLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-50%) translateX(25rem)
  }

  100% {
    opacity: 1;
    transform: translateY(-50%) translateX(0)
  }
`;

const slideInLeftNoY = keyframes`
  0% {
    opacity: 0;
    transform: translateX(25rem)
  }

  100% {
    opacity: 1;
    transform: translateX(0)
  }
`;

/* ******************************************************  Export  ****************************************************** */
export const AppsWebPlannerAnimations = {
  slideInLeft,
  slideInLeftNoY,
};
