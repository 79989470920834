/* ******************************************************  Imports  ****************************************************** */
import { useTranslation } from 'react-i18next';

import { StoreButtons } from '../store-buttons/StoreButtons';

import { AppsRouteDetailsStyles } from './AppsRouteDetails.styles';
import phoneImage from '../../../../assets/images/apps/apps-1.png';
import { GetCustomTranslation } from '../../../shared/components/GetCustomTranslation';
import { useAppAnalytics } from 'analytics';
import { Fade } from 'react-awesome-reveal';

/* ******************************************************  Component  ****************************************************** */
export const AppsRouteDetails = () => {
  /* ******************************************************  Component  ****************************************************** */
  const { t } = useTranslation();

  /* *************************************************** APP INSIGHTS **************************************************** */
  const { trackEvent } = useAppAnalytics();

  const handleButtonClicked = (button: string) => {
    if (button === 'apple') {
      trackEvent({
        action: '[Mobile & Web App - Step By Step Section] App Store Button Clicked',
        category: 'Mobile & Web App',
      });
    } else {
      trackEvent({
        action: '[Mobile & Web App - Step By Step Section] Google Play Button Clicked',
        category: 'Mobile & Web App',
      });
    }
  };

  /* ******************************************************  Template  ****************************************************** */
  return (
    <AppsRouteDetailsStyles.RouteDetailsContainer>
      <AppsRouteDetailsStyles.RouteDetailsContent>
        <Fade direction="left" duration={1500} fraction={0.79} triggerOnce={true}>
          <AppsRouteDetailsStyles.TextWrapper>
            <p className="miniTopText">{t('apps.routeDetails.top')}</p>
            <h2
              className="mainText black"
              dangerouslySetInnerHTML={GetCustomTranslation('apps.routeDetails.main')}
            ></h2>
            <p
              className="subText"
              dangerouslySetInnerHTML={GetCustomTranslation('apps.routeDetails.sub')}
            ></p>

            <StoreButtons buttonClicked={handleButtonClicked} />
          </AppsRouteDetailsStyles.TextWrapper>
        </Fade>

        <Fade direction="right" duration={1500} fraction={0.79} triggerOnce={true}>
          <AppsRouteDetailsStyles.PhoneImageContainer>
            <AppsRouteDetailsStyles.PhoneImage
              src={phoneImage}
              alt="3D smartphone model with ElectricRoutes application screen"
            />
          </AppsRouteDetailsStyles.PhoneImageContainer>
        </Fade>
      </AppsRouteDetailsStyles.RouteDetailsContent>
    </AppsRouteDetailsStyles.RouteDetailsContainer>
  );
};
