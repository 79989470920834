/* ******************************************************  Imports  ****************************************************** */
import styled from '@emotion/styled';
import { getColor } from '../../theme/colors/colors';
import { getVar } from '../../theme/ui-variables/ui-variables';

/* ******************************************************  Styles  ****************************************************** */
const NotFoundContainer = styled.div`
  background-color: ${getColor('primary')};
  padding: calc(${getVar('headerHeight')} + 12rem) 0;
  min-height: 100%;
`;

const NotFoundContent = styled.div`
  position: relative;
  width: 100%;
  max-width: ${getVar('maxWidth')};
  margin: 0 auto;
  color: ${getColor('primaryContrast')};
  text-align: center;

  p {
    font-weight: 700;
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  img {
    position: absolute;
    top: -12rem;
    left: 0;
    width: 100%;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 5rem;

  button {
    margin-right: 1.5rem;
    margin-bottom: 3rem;

    &:last-of-type {
      margin-right: 0;
      text-transform: none !important;
    }
  }
`;

/* ******************************************************  Export  ****************************************************** */
export const NotFoundStyles = {
  NotFoundContainer,
  NotFoundContent,
  ButtonsWrapper,
};
