/* ******************************************************  Imports  ****************************************************** */
import styled from '@emotion/styled';
import { Zoom } from 'react-awesome-reveal';

import SliderHandle from '../../../../assets/images/b2b/slider-handle.svg';

import { getColor } from '../../../theme/colors/colors';
import { getVar } from '../../../theme/ui-variables/ui-variables';

/* ******************************************************  Styles  ****************************************************** */
const WhiteLabelContainer = styled.div`
  width: 100%;
  min-height: 100%;
  padding: 11rem 0 23rem 0;
  background-color: ${getColor('adminBlack')};
  color: ${getColor('primaryContrast')};
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: ${getVar('maxWidth')};
  height: 100%;
  margin-inline: auto;
`;

const LogoImage = styled.img`
  display: block;
  margin: 0 auto;
  width: 30.5rem;
  height: 2rem;
  margin-bottom: 11rem;
`;

const TextContainer = styled.div`
  margin: 12.5rem auto;
  width: 100%;
  max-width: 65rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h2,
  p {
    max-width: 73.5rem;
  }

  p {
    color: ${getColor('textGrey')};
    font-size: 1.8rem;
    margin-top: 1.5rem;
  }
`;

const SliderContainer = styled(Zoom)`
  width: 100%;
  height: 68.5rem;
  box-shadow: 0 2.3rem 7rem ${getColor('primaryContrast', 0.15)};
  border-radius: 1.8rem;
  overflow: hidden;

  div {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  @media screen and (max-width: 1150px) {
    height: 60rem;
  }

  @media screen and (max-width: 1000px) {
    height: 50vw;
  }

  @media screen and (max-width: 700px) {
    height: 53vw;
  }
`;

const CustomSlider = styled.div`
  position: relative;
  width: 0.6rem !important;
  background-color: ${getColor('primaryContrast')};
  filter: drop-shadow(0 0 1rem ${getColor('text', 0.5)});
  cursor: grab;
  z-index: 2;

  &:active {
    cursor: grabbing;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    width: 6.4rem;
    height: 6.4rem;
    transform: translate(-50%, -50%);
    background-image: url(${SliderHandle});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 6.4rem 6.4rem;
  }
`;

const ButtonsContainer = styled.div`
  width: 100%;
  margin-top: 11rem;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin-right: 0;
    margin-bottom: 0;
  }
`;

const SliderSwitch = styled.div`
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: currentColor;

  &.middle {
    margin: 0 2rem;
  }

  &.active {
    box-shadow: 0 0 2rem 0;
    border: 2px solid ${getColor('primaryContrast')};
    pointer-events: none;
  }

  &:hover {
    box-shadow: 0 0 2rem 0;
  }
`;

/* ******************************************************  Export  ****************************************************** */
export const B2BWhiteLabelStyles = {
  WhiteLabelContainer,
  ContentContainer,
  LogoImage,
  TextContainer,
  SliderContainer,
  CustomSlider,
  ButtonsContainer,
  SliderSwitch,
};
