/* ******************************************************  Imports  ****************************************************** */
import { useTranslation } from 'react-i18next';

import { Button } from '@material-ui/core';
import { APP_LINKS } from '../../../shared/appLinks';

import { AppsWebPlannerStyles } from './AppsWebPlanner.styles';
import laptopImage from '../../../../assets/images/apps/apps-6.png';
import { GetCustomTranslation } from '../../../shared/components/GetCustomTranslation';
import { useAppAnalytics } from 'analytics';
import { useResize } from '../../../shared/hooks/useResize';
import { AppsWebPlannerAnimations } from './apps-web-planner.animations';
import { Zoom } from 'react-awesome-reveal';

/* ******************************************************  Component  ****************************************************** */
export const AppsWebPlanner = () => {
  /* ******************************************************  Component  ****************************************************** */
  const { t } = useTranslation();

  const windowSize = useResize();

  /* *************************************************** APP INSIGHTS **************************************************** */
  const { trackEvent } = useAppAnalytics();

  /* ******************************************************  Template  ****************************************************** */
  return (
    <AppsWebPlannerStyles.WebPlannerContainer id="webPlanner">
      <AppsWebPlannerStyles.TextWrapperFade direction="left" duration={1500} triggerOnce>
        <AppsWebPlannerStyles.TextWrapper>
          <h2
            className="mainText black"
            dangerouslySetInnerHTML={GetCustomTranslation('apps.webPlanner.main')}
          ></h2>
          <p className="subText">{t('apps.webPlanner.sub')}</p>

          <Zoom duration={500} delay={1000} fraction={0.79} triggerOnce={true}>
            <Button
              variant="contained"
              color="primary"
              className="erButton"
              onClick={() =>
                trackEvent({
                  action: '[Mobile & Web App - Web Planner Section] Open In Web Clicked',
                  category: 'Mobile & Web App',
                })
              }
            >
              <a href={APP_LINKS.webPlanner} target="_blank" rel="noopener noreferrer">
                {t('apps.webPlanner.button')}
              </a>
            </Button>
          </Zoom>
        </AppsWebPlannerStyles.TextWrapper>
      </AppsWebPlannerStyles.TextWrapperFade>

      <AppsWebPlannerStyles.ImageReveal
        keyframes={
          windowSize.width > 1150
            ? AppsWebPlannerAnimations.slideInLeft
            : AppsWebPlannerAnimations.slideInLeftNoY
        }
        duration={1500}
        triggerOnce
      >
        <AppsWebPlannerStyles.Image
          src={laptopImage}
          alt="ElectricRoutes application screen on laptop mockup"
        />
      </AppsWebPlannerStyles.ImageReveal>
    </AppsWebPlannerStyles.WebPlannerContainer>
  );
};
