/* ******************************************************  Imports  ****************************************************** */
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { useScroll } from '../../../hooks/useScroll';

import logoBlue from '../../../../../assets/images/logos/full-logo-blue.svg';

import { HeaderLinksData } from '../header-links.data';
import { DesktopHeaderStyles } from './DesktopHeader.styles';
import { APP_ROUTES } from '../../../appRoutes';
import { LanguageSwitcher } from '../../language-switcher/LanguageSwitcher';
import { contactFormTopicService } from '../../../../services/contact-form-topic.service';
import { useAppAnalytics } from 'analytics';
import { LanguageModel } from '../../language-switcher/language.data';

/* ******************************************************  Component  ****************************************************** */
export const DesktopHeader = ({ contactUsClicked, linkClicked }: any) => {
  /* ******************************************************  State  ****************************************************** */
  const [scrollActive, setScrollActive] = useState(false);

  const { t } = useTranslation();

  /* ******************************************************  useScroll()  ****************************************************** */
  const scroll = useScroll();

  useEffect(() => {
    setScrollActive(scroll.scrollY > 100);
  }, [scroll]);

  /* ******************************************************  useLocation()  ****************************************************** */
  const location = useLocation();

  useEffect(() => {}, [location]);

  /* ******************************************************  Logic  ****************************************************** */
  const getHeaderClasses = () => {
    const classes = [];

    switch (location.pathname) {
      case APP_ROUTES.apps:
        classes.push('white');
        break;
      case APP_ROUTES.b2b:
        classes.push('black');
        break;
    }

    if (scrollActive) {
      if (scroll.scrollDirection === 'down') {
        classes.push('show');
      }
      classes.push('scrolled');
    }

    return classes.join(' ');
  };

  /* *************************************************** APP INSIGHTS **************************************************** */
  const { trackEvent } = useAppAnalytics();

  /* ******************************************************  Template  ****************************************************** */
  return (
    <>
      <DesktopHeaderStyles.LanguageAbsoluteContainer
        className={location.pathname === APP_ROUTES.apps ? 'blue' : ''}
      >
        <LanguageSwitcher
          languageChanged={(lang: LanguageModel) =>
            trackEvent({
              action: 'Language changed',
              name: JSON.stringify({ lang }),
              category: 'General',
            })
          }
        />
      </DesktopHeaderStyles.LanguageAbsoluteContainer>

      <DesktopHeaderStyles.HeaderContainer className={getHeaderClasses()}>
        <DesktopHeaderStyles.HeaderContent>
          <NavLink to="/">
            <DesktopHeaderStyles.LogoImage
              src={logoBlue}
              alt="ElectricRoutes logo"
              className={location.pathname !== APP_ROUTES.apps ? 'white' : ''}
            />
          </NavLink>

          <DesktopHeaderStyles.LinkContainer>
            {HeaderLinksData.map((link) => (
              <DesktopHeaderStyles.HeaderLink
                onClick={() => linkClicked(link.text)}
                key={link.route}
                to={link.route}
                activeClassName="active"
                exact={link.exact}
                className={location.pathname === APP_ROUTES.apps ? 'blueText' : ''}
              >
                <DesktopHeaderStyles.HeaderLinkText>
                  {t(link.text)}
                </DesktopHeaderStyles.HeaderLinkText>
              </DesktopHeaderStyles.HeaderLink>
            ))}

            <DesktopHeaderStyles.HeaderLinkButton
              className={location.pathname === APP_ROUTES.apps ? 'blueText' : ''}
              onClick={() => {
                contactFormTopicService.changeTopic('OTHER');
                window.scrollTo(0, document.body.scrollHeight);
                contactUsClicked();
              }}
            >
              {t('header.contact')}
            </DesktopHeaderStyles.HeaderLinkButton>
          </DesktopHeaderStyles.LinkContainer>
        </DesktopHeaderStyles.HeaderContent>
      </DesktopHeaderStyles.HeaderContainer>
    </>
  );
};
