/* ******************************************************  Imports  ****************************************************** */
import ImageReward from '../../../../assets/images/b2b/operator-icons/operator-reward.svg';
import ImageFeedback from '../../../../assets/images/b2b/operator-icons/operator-feedback.svg';
import ImageQuestion from '../../../../assets/images/b2b/operator-icons/operator-question.svg';
import ImageImages from '../../../../assets/images/b2b/operator-icons/operator-image.svg';

/* ******************************************************  Export  ****************************************************** */
export const B2B_OPERATOR_DATA: {
  icon: string;
  title: string;
  text: string;
}[] = [
  {
    icon: ImageReward,
    title: 'b2b.operator.reward.title',
    text: 'b2b.operator.reward.text',
  },
  {
    icon: ImageFeedback,
    title: 'b2b.operator.feedback.title',
    text: 'b2b.operator.feedback.text',
  },
  {
    icon: ImageQuestion,
    title: 'b2b.operator.question.title',
    text: 'b2b.operator.question.text',
  },
  {
    icon: ImageImages,
    title: 'b2b.operator.images.title',
    text: 'b2b.operator.images.text',
  },
];
