/* ******************************************************  Imports  ****************************************************** */
import { PopoverClassKey, StyleRules } from '@material-ui/core';
import { getColor } from '../../colors/colors';

/* ******************************************************  Override  ****************************************************** */
export const CustomPopover:
  | Partial<StyleRules<PopoverClassKey, {}>>
  | undefined = {
  root: {
    '&.customSelectMenu': {
      '& .MuiPaper-root': {
        padding: '1.7rem 0',
        backgroundColor: getColor('primary'),
        color: getColor('primaryContrast'),
        borderRadius: '1.8rem',

        '& .MuiMenu-list': {
          padding: 0,

          '& .MuiMenuItem-dense': {
            padding: '1.5rem 2.4rem',
            fontSize: '1.8rem',
          },
        },
      },

      '&.black': {
        '& .MuiPaper-root': {
          backgroundColor: getColor('primaryContrast'),
          color: getColor('adminBlack'),
        },
      },

      '&.white': {
        '& .MuiPaper-root': {
          backgroundColor: getColor('adminBlack'),
          color: getColor('primaryContrast'),
        },
      },
    },
  },
};
