import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash && pathname) {
      // window.scrollTo(0, 0);

      setTimeout(() => {
        document.querySelector(hash)?.scrollIntoView(true);
      }, 100);
      // 1000

      return;
    }

    window.scrollTo(0, 0);
  }, [pathname, hash]);

  return null;
};
