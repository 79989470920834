/* ******************************************************  Imports  ****************************************************** */
import { useTranslation } from 'react-i18next';
import { ApiListItemModel } from './api-list-item.model';
import { ApiListItemStyles } from './ApiListItem.styles';

//TODO: Unfinished Prod
// import arrowIcon from '../../../../../assets/images/home/api-icons/api-list-arrow.svg';

/* ******************************************************  Component  ****************************************************** */
export const ApiListItem = (item: ApiListItemModel) => {
  /* ******************************************************  State  ****************************************************** */
  const { t } = useTranslation();

  /* ******************************************************  Template  ****************************************************** */
  return (
    <ApiListItemStyles.ItemContainer href={item.link}>
      <ApiListItemStyles.ApiIcon src={item.icon} alt="api icon" />

      <ApiListItemStyles.Text>
        {t(item.translationString)}
      </ApiListItemStyles.Text>

      {/*
        //TODO: Unfinished Prod
      */}
      {/* <ApiListItemStyles.ArrowIcon
        src={arrowIcon}
        alt='arrow icon'
        className='arrow'
      /> */}
    </ApiListItemStyles.ItemContainer>
  );
};
