/* ******************************************************  Imports  ****************************************************** */
import { AppsLanding } from './landing/AppsLanding';
import { AppsRouteDetails } from './route-details/AppsRouteDetails';
import { AppsNavigation } from './navigation/AppsNavigation';
import { AppsCharging } from './charging/AppsCharging';
//TODO: Unfinished Prod
// import { AppsCarData } from './car-data/AppsCarData';
import { AppsInCar } from './in-car/AppsInCar';
import { AppsWebPlanner } from './web-planner/AppsWebPlanner';

/* ******************************************************  Component  ****************************************************** */
export const AppsPage = () => {
  /* ******************************************************  Template  ****************************************************** */
  return (
    <>
      <AppsLanding />
      <AppsRouteDetails />
      <AppsNavigation />
      <AppsCharging />
      {/*
      //TODO: Unfinished Prod
      */}
      {/* <AppsCarData /> */}
      <AppsInCar />
      <AppsWebPlanner />
    </>
  );
};
