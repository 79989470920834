/* ******************************************************  Imports  ****************************************************** */
import { useState, useEffect } from 'react';

/* ******************************************************  Hook  ****************************************************** */
export const useResize = () => {
  /* ******************************************************  State  ****************************************************** */
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  /* ******************************************************  Logic  ****************************************************** */
  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  /* ******************************************************  useEffect  ****************************************************** */
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  /* ******************************************************  Return  ****************************************************** */
  return windowSize;
};
