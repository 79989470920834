/* ******************************************************  Imports  ****************************************************** */
import { ImprintStyles } from './Imprint.styles';

import PhoneIcon from '../../../../assets/images/legal/phone.svg';
import EmailIcon from '../../../../assets/images/legal/mail.svg';
import ExternalLinkIcon from '../../../../assets/images/legal/external-link.svg';

/* ******************************************************  Component  ****************************************************** */
export const ImprintDE = () => {
  /* ******************************************************  Template  ****************************************************** */
  return (
    <>
      <ImprintStyles.LandingContainer>
        <ImprintStyles.Heading className="mainText">
          Impressum
        </ImprintStyles.Heading>
      </ImprintStyles.LandingContainer>

      <ImprintStyles.ImprintContainer>
        <ImprintStyles.SubtitleContainer>
          <ImprintStyles.StyledH4>
            Angaben gemäß § 5 Abs. 1 des Telemediengesetzes:
          </ImprintStyles.StyledH4>
        </ImprintStyles.SubtitleContainer>
        <ImprintStyles.Divider />
        <ImprintStyles.Row>
          <ImprintStyles.StyledH5>Name des Anbieters:</ImprintStyles.StyledH5>
          <ImprintStyles.StyledText>
            ElectricRoutes GmbH
          </ImprintStyles.StyledText>
        </ImprintStyles.Row>
        <ImprintStyles.Row>
          <ImprintStyles.StyledH5>Anschrift:</ImprintStyles.StyledH5>
          <ImprintStyles.StyledText>
            <div>Heilbronner Str. 86</div>
            <div>70191 Stuttgart</div>
            <div>Deutschland</div>
          </ImprintStyles.StyledText>
        </ImprintStyles.Row>
        <ImprintStyles.Row>
          <ImprintStyles.StyledH5>
            Vertretungsberechtigte Geschäftsführer:
          </ImprintStyles.StyledH5>
          <ImprintStyles.StyledText>Andreas Bisenius</ImprintStyles.StyledText>
        </ImprintStyles.Row>
        <ImprintStyles.Row>
          <ImprintStyles.StyledH5>Registergericht:</ImprintStyles.StyledH5>
          <ImprintStyles.StyledText>
            Amtsgericht Stuttgart
          </ImprintStyles.StyledText>
        </ImprintStyles.Row>
        <ImprintStyles.Row>
          <ImprintStyles.StyledH5>Registernummer:</ImprintStyles.StyledH5>
          <ImprintStyles.StyledText>HRB 780686</ImprintStyles.StyledText>
        </ImprintStyles.Row>
        <ImprintStyles.Row>
          <ImprintStyles.StyledH5>
            Umsatzsteuer-Id.-Nr. (§ 27a UStG).:
          </ImprintStyles.StyledH5>
          <ImprintStyles.StyledText>DE264948656</ImprintStyles.StyledText>
        </ImprintStyles.Row>
        <ImprintStyles.Row>
          <ImprintStyles.StyledH5>Kontaktdaten:</ImprintStyles.StyledH5>
          <ImprintStyles.StyledText>
            <ImprintStyles.StyledMiniRow>
              <ImprintStyles.StyledIcon src={EmailIcon} />
              <ImprintStyles.StyledLink
                href={'mailto:contact@electricroutes.com'}
              >
                contact@electricroutes.com
              </ImprintStyles.StyledLink>
            </ImprintStyles.StyledMiniRow>
            <ImprintStyles.StyledMiniRow>
              <ImprintStyles.StyledIcon src={PhoneIcon} />
              <ImprintStyles.StyledLink href={'tel:+49 711 252 749-0'}>
                +49 711 252 749-0
              </ImprintStyles.StyledLink>
            </ImprintStyles.StyledMiniRow>
            <ImprintStyles.StyledMiniRow>
              <ImprintStyles.StyledIcon src={ExternalLinkIcon} />
              <ImprintStyles.StyledLink
                href={'https://www.electricroutes.com'}
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.electricroutes.com
              </ImprintStyles.StyledLink>
            </ImprintStyles.StyledMiniRow>
          </ImprintStyles.StyledText>
        </ImprintStyles.Row>

        <ImprintStyles.SubtitleContainer>
          <ImprintStyles.StyledH4>
            Verbraucherstreitbeilegung
          </ImprintStyles.StyledH4>
        </ImprintStyles.SubtitleContainer>
        <ImprintStyles.Divider />
        <ImprintStyles.StyledText2>
          <p>
            Die Europäische Kommission stellt eine Online-Plattform zur
            Online-Streitbeilegung zur Verfügung, welche unter&nbsp;
            <ImprintStyles.StyledLink
              href={'http://ec.europa.eu/consumers/odr/'}
              target="_blank"
              rel="noopener noreferrer"
            >
              http://ec.europa.eu/consumers/odr/
            </ImprintStyles.StyledLink>{' '}
            zu finden ist.
          </p>
          <br />
          <p>
            Die ElectricRoutes GmbH ist weder bereit noch verpflichtet, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
          </p>
          <br />
          <p>
            Zusätzliche Informationen zur Berufshaftpflichtversicherung gemäß §
            2 Abs. 1 Nr. 11 der Verordnung über Informationspflichten für
            Dienstleistungserbringer (DL-InfoV):
          </p>
        </ImprintStyles.StyledText2>
        <ImprintStyles.Row>
          <ImprintStyles.StyledH5>
            Name und Anschrift des Versicherers:
          </ImprintStyles.StyledH5>
          <ImprintStyles.StyledText>
            <div>AIG Europe S.A.</div>
            <div>Direktion für Deutschland</div>
            <div>Neue Mainzer Straße 46 – 50</div>
            <div>60311 Frankfurt</div>
          </ImprintStyles.StyledText>
        </ImprintStyles.Row>
        <ImprintStyles.Row>
          <ImprintStyles.StyledH5>
            Räumlicher Geltungsbereich:
          </ImprintStyles.StyledH5>
          <ImprintStyles.StyledText>
            Weltweiter Versicherungsschutz
          </ImprintStyles.StyledText>
        </ImprintStyles.Row>

        <ImprintStyles.SubtitleContainer>
          <ImprintStyles.StyledH4>Urheberrechtshinweis</ImprintStyles.StyledH4>
        </ImprintStyles.SubtitleContainer>
        <ImprintStyles.Divider />
        <ImprintStyles.StyledText2>
          <p>
            Die auf unserer Internetseite vorhandenen Inhalte unterliegen in der
            Regel dem Schutz des Urheberrechts. Jede unberechtigte Verwendung
            (insbesondere die Vervielfältigung, Bearbeitung oder Verbreitung)
            dieser urheberrechtsgeschützten Inhalte ist daher untersagt.
          </p>
          <br />
          <p>
            Die Charger-Daten für das Routing werden in Kooperation mit
            CIRRANTiC GmbH (
            <ImprintStyles.StyledLink
              href={'https://cirrantic.com/'}
              target="_blank"
              rel="noopener noreferrer"
            >
              https://cirrantic.com
            </ImprintStyles.StyledLink>
            ) bereitgestellt. Eine Verwendung von Funktionen und Details der
            Charger-Daten ist nur für die Routenplanung mit ElectricRoutes
            Webapp und App für Endnutzer zulässig. Automatisierte Abfragen der
            Charger-Daten (Bots/Scraping/Crawling) sowie alle kommerzielle
            Verwendungen der Funktionen und Charger-Daten sind nicht gestattet.
            Mehr Informationen zu den Charger-Daten sind unter MOOVILITY Webapp
            (
            <ImprintStyles.StyledLink
              href={'https://moovility.com'}
              target="_blank"
              rel="noopener noreferrer"
            >
              https://moovility.com
            </ImprintStyles.StyledLink>
            ) und App (
            <ImprintStyles.StyledLink
              href={'https://moovility.com/app'}
              target="_blank"
              rel="noopener noreferrer"
            >
              https://moovility.com/app
            </ImprintStyles.StyledLink>
            ) verfügbar.
          </p>
        </ImprintStyles.StyledText2>
      </ImprintStyles.ImprintContainer>
    </>
  );
};
