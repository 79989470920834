/* ******************************************************  Imports  ****************************************************** */
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { useScroll } from '../../../hooks/useScroll';

import logoBlue from '../../../../../assets/images/logos/full-logo-blue.svg';

import { HeaderLinksData } from '../header-links.data';
import { MobileHeaderStyles } from './MobileHeader.styles';
import { APP_ROUTES } from '../../../appRoutes';
import { LanguageSwitcher } from '../../language-switcher/LanguageSwitcher';

import { Drawer } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { contactFormTopicService } from '../../../../services/contact-form-topic.service';
import { useAppAnalytics } from 'analytics';
import { LanguageModel } from '../../language-switcher/language.data';

/* ******************************************************  Component  ****************************************************** */
export const MobileHeader = ({ contactUsClicked, linkClicked }: any) => {
  /* ******************************************************  State  ****************************************************** */
  const [isOpen, setIsOpen] = useState(false);
  const [scrollActive, setScrollActive] = useState(false);

  const { t } = useTranslation();

  /* ******************************************************  useScroll()  ****************************************************** */
  const scroll = useScroll();

  useEffect(() => {
    setScrollActive(scroll.scrollY > 100);
  }, [scroll]);

  /* ******************************************************  useLocation()  ****************************************************** */
  const location = useLocation();

  useEffect(() => {}, [location]);

  /* ******************************************************  Logic  ****************************************************** */
  const getHeaderClasses = () => {
    const classes = [];

    switch (location.pathname) {
      case APP_ROUTES.apps:
        classes.push('white');
        break;
      case APP_ROUTES.b2b:
        classes.push('black');
        break;
    }

    if (scrollActive) {
      if (scroll.scrollDirection === 'down') {
        classes.push('show');
      }
      classes.push('scrolled');
    }

    return classes.join(' ');
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const toggleHeader = () => {
    setIsOpen(!isOpen);
  };

  /* *************************************************** APP INSIGHTS **************************************************** */
  const { trackEvent } = useAppAnalytics();

  /* ******************************************************  Template  ****************************************************** */
  return (
    <>
      <Drawer
        anchor={'top'}
        open={isOpen}
        onClose={handleClose}
        className={getHeaderClasses() + ' headerDrawer'}
      >
        <MobileHeaderStyles.LinkContainer>
          {HeaderLinksData.map((link) => (
            <MobileHeaderStyles.MobileHeaderLink
              key={link.route}
              to={link.route}
              activeClassName="active"
              exact={link.exact}
              className={location.pathname === APP_ROUTES.apps ? 'blueText' : ''}
              onClick={() => {
                linkClicked(link.text);
                handleClose();
              }}
            >
              <MobileHeaderStyles.MobileHeaderLinkText>
                {t(link.text)}
              </MobileHeaderStyles.MobileHeaderLinkText>
            </MobileHeaderStyles.MobileHeaderLink>
          ))}

          <MobileHeaderStyles.MobileHeaderLinkButton
            className={location.pathname === APP_ROUTES.apps ? 'blueText' : ''}
            onClick={() => {
              contactFormTopicService.changeTopic('OTHER');
              window.scrollTo(0, document.body.scrollHeight);
              contactUsClicked();
              handleClose();
            }}
          >
            {t('header.contact')}
          </MobileHeaderStyles.MobileHeaderLinkButton>
        </MobileHeaderStyles.LinkContainer>

        <MobileHeaderStyles.LanguageAbsoluteContainer
          className={location.pathname === APP_ROUTES.apps ? 'blue' : ''}
        >
          <LanguageSwitcher
            languageChanged={(lang: LanguageModel) =>
              trackEvent({
                action: 'Language changed',
                name: JSON.stringify({ lang }),
                category: 'General',
              })
            }
          />
        </MobileHeaderStyles.LanguageAbsoluteContainer>
      </Drawer>

      <MobileHeaderStyles.MobileHeaderContainer className={getHeaderClasses()}>
        <MobileHeaderStyles.MobileHeaderContent>
          <NavLink to="/">
            <MobileHeaderStyles.LogoImage
              src={logoBlue}
              alt="ElectricRoutes logo"
              className={location.pathname !== APP_ROUTES.apps ? 'white' : ''}
            />
          </NavLink>

          <MobileHeaderStyles.StyledToggleButton
            className={location.pathname === APP_ROUTES.apps ? 'blueText' : ''}
            onClick={toggleHeader}
            size="medium"
          >
            {isOpen ? <CloseIcon /> : <MenuIcon />}
          </MobileHeaderStyles.StyledToggleButton>
        </MobileHeaderStyles.MobileHeaderContent>
      </MobileHeaderStyles.MobileHeaderContainer>
    </>
  );
};
