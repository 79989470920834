/* ******************************************************  Imports  ****************************************************** */
import styled from '@emotion/styled';
import { Button, MenuItem } from '@material-ui/core';
import { getColor } from '../../../theme/colors/colors';
import { getVar } from '../../../theme/ui-variables/ui-variables';

/* ******************************************************  Styles  ****************************************************** */
const SwitcherContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  width: 100%;
  max-width: ${getVar('maxWidth')};
`;

const StyledButton = styled(Button)`
  &.MuiButton-root {
    font-size: 1.4rem;
    font-weight: 800;
    color: currentColor;
    text-transform: none;
    border-radius: 1rem;
    padding: 0.8rem 1.2rem;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &.active {
    color: ${getColor('primary')};
    font-weight: bold;
    pointer-events: none;
  }
`;

/* ******************************************************  Export  ****************************************************** */
export const LanguageSwitcherStyles = {
  SwitcherContainer,
  StyledButton,
  StyledMenuItem,
};
