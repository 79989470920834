/* ******************************************************  Imports  ****************************************************** */
import styled from '@emotion/styled';

import { getColor } from '../../../theme/colors/colors';

import operatorHeroImage from '../../../../assets/images/b2b/b2b-operator-hero.png';
import { getVar } from '../../../theme/ui-variables/ui-variables';
import { Fade } from 'react-awesome-reveal';

/* ******************************************************  Styles  ****************************************************** */
const OperatorContainer = styled.div`
  width: 100%;
  min-height: 100%;
  padding: 0 0 22rem 0;
  background-color: ${getColor('primaryContrast')};
  color: ${getColor('text')};
`;

const LandingContainer = styled.div`
  width: 100%;
  min-height: 936px;
  background-image: url(${operatorHeroImage});
  background-position: top right;
  background-repeat: no-repeat;
  background-size: auto 936px;
  padding: 11.5rem calc((100% - ${getVar('maxWidth')}) / 2) 17.5rem
    calc((100% - ${getVar('maxWidth')}) / 2);
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${getColor('primaryContrast')};
    top: 0;
    left: 0;
    opacity: 0;
  }

  @media screen and (max-width: 1350px) {
    &::before {
      opacity: 0.8;
    }
  }
`;

const LandingTextContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 47.5rem;

  img {
    width: 32.2rem;
    height: 4.6rem;
    margin-bottom: 12.5rem;
  }

  button {
    margin: 0;
    margin-top: 5.6rem;
  }

  @media screen and (max-width: 1350px) {
    max-width: 70rem;
  }

  @media screen and (max-width: 1050px) {
    max-width: ${getVar('maxWidth')};
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: ${getVar('maxWidth')};
  margin: 12.5rem auto 0 auto;
`;

const ContentLogo = styled.img`
  display: block;
  margin: 0 auto 12.5rem auto;
  width: 32.2rem;
  height: 4.6rem;
`;

const ContentTextContainer = styled.div`
  width: 100%;
  max-width: 55rem;
  text-align: center;
  margin: 0 auto 11rem auto;
`;

const TilesContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-inline: auto;
  max-width: ${getVar('maxWidth')};
`;

const Tile = styled(Fade)`
  width: ${getVar('b2bTileWidth')};
  min-height: 35rem;
  //TODO: Unfinished Prod
  /* min-height: ${getVar('b2bTileHeight')}; */
  margin-bottom: calc(${getVar('maxWidth')} - (2 * ${getVar('b2bTileWidth')}));
  border-radius: 2.4rem;
  padding: 5.5rem 4.5rem;
  background-color: ${getColor('whiteContrast')};

  &:nth-of-type(even) {
    margin-left: calc(${getVar('maxWidth')} - (2 * ${getVar('b2bTileWidth')}));
  }

  &.hide {
    display: none;
  }

  img {
    width: 12rem;
    height: 12rem;
    display: block;
    margin: 0 auto;
  }

  h4 {
    text-align: center;
    margin-top: 6rem;
    margin-bottom: 3rem;
    //TODO
    max-width: 28rem;
    margin-inline: auto;
  }

  p {
    font-size: 1.8rem;
    text-align: center;
    font-weight: 300;
  }

  @media screen and (max-width: 1200px) {
    max-width: calc(50% - 2.5rem);
    margin-bottom: 5rem;

    &:nth-of-type(even) {
      margin-left: 5rem;
    }
  }

  @media screen and (max-width: 1023px) {
    max-width: 100%;

    &:nth-of-type(even) {
      margin-left: 0;
    }
  }
`;

const ButtonsContainer = styled.div`
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  & > button.MuiButton-root.erButton {
    min-width: 42rem;
    margin-right: 0;

    &.noLink {
      margin-bottom: 3.5rem;
    }
  }
`;

/* ******************************************************  Export  ****************************************************** */
export const B2BOperatorStyles = {
  OperatorContainer,
  LandingContainer,
  LandingTextContainer,
  ContentContainer,
  ContentLogo,
  ContentTextContainer,
  TilesContainer,
  Tile,
  ButtonsContainer,
};
