/* ******************************************************  Imports  ****************************************************** */
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { axiosData } from '../../../api/axios-data';
import { applicationService } from '../../../api/api';

import { ContactFormModel } from './../../shared/components/footer/contact-form/contact-form.model';

/* ******************************************************  Service  ****************************************************** */
export const contactSupport = (body: ContactFormModel): Promise<any> => {
  return axiosData<any>((): AxiosObservable<any> => {
    return applicationService.post('/support', body);
  })().toPromise();
};
