import { useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { erLocalStore } from '../../services/local-storage/local-store-namespace';

const DATA_TRACKING_ENABLED = 'data_tracking_enabled';

const dataTrackingEnabledSubject = new Subject<boolean>();

/**
 * This hook will expose the boolean value of the stored "data tracking"
 * flag for the toggle in Privacy Policy page.
 *
 * Additionally, it exposes a setter function to set the new value. By using
 * subjects/event-emitting, all hook instances will share the same value and
 * same boolean value and inform all other hook instances of changes to the
 * toggle.
 *
 * DISCUSS: Another implementation could be redux + redux-persist for more
 * "react-looking" code.
 */
export const useDataTrackingEnabled: () => [boolean, (val: boolean) => void] = () => {
  const [enabled, setEnabled] = useState<boolean>(() => {
    // set initial value
    if (erLocalStore.has(DATA_TRACKING_ENABLED)) {
      // if user decision already exists in local storage, use that value
      return erLocalStore.get(DATA_TRACKING_ENABLED);
    } else {
      // otherwise, it's the initial usage of the app and the default
      // value should be set to true
      erLocalStore.set(DATA_TRACKING_ENABLED, true);
      return true;
    }
  });

  useEffect(() => {
    // we listen to toggle switch change from ANY component to keep them in sync
    const sub = dataTrackingEnabledSubject.subscribe((value) => {
      setEnabled(value);
    });

    return () => {
      sub.unsubscribe();
    };
  }, []);

  const setDataTrackingEnabled = (enabled: boolean) => {
    // when setting new toggle switch value, we both set the local state AND value in local storage
    erLocalStore.set(DATA_TRACKING_ENABLED, enabled);
    dataTrackingEnabledSubject.next(enabled);
  };

  return [enabled, setDataTrackingEnabled];
};
