import { StyleRules, SwitchClassKey } from '@material-ui/core';
import transitions from '@material-ui/core/styles/transitions';
import { getColor } from 'core/theme/colors/colors';

// Configure switch/thumb size
const width = 52;
const height = 32;
const borderWidth = 2;
const thumbSize = height - 2 * borderWidth;

export const CustomSwitch: Partial<StyleRules<SwitchClassKey, {}>> | undefined = {
  root: {
    width,
    height,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: borderWidth,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: `translateX(${width - height}px)`,
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: getColor('primary'),
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: getColor('primary'),
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {},
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: thumbSize,
      height: thumbSize,
      backgroundColor: '#fff',
    },
    '& .MuiSwitch-track': {
      borderRadius: height / 2,
      backgroundColor: getColor('textGrey'), // TODO: Add default grey variables to theme -> getColor('grey')
      opacity: 1,
      transition: transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  },
};
