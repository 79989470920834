import { Reveal, Fade } from 'react-awesome-reveal';
/* ******************************************************  Imports  ****************************************************** */
import styled from '@emotion/styled';

import { getColor } from '../../../theme/colors/colors';
import { getVar } from '../../../theme/ui-variables/ui-variables';

/* ******************************************************  Styles  ****************************************************** */
const WebPlannerContainer = styled.div`
  position: relative;
  background-color: ${getColor('primaryContrast')};
  padding: 19rem 0 25rem 0;

  @media screen and (max-width: 1150px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 15rem 0;
  }
`;

const TextWrapperFade = styled(Fade)`
  width: 100%;
  max-width: 45rem;
  margin-left: calc((100% - ${getVar('maxWidth')}) / 2);

  @media screen and (max-width: 1150px) {
    width: ${getVar('mobileContentWidth')};
    max-width: ${getVar('maxWidth')};
  }
`;

const TextWrapper = styled.div`
  width: 100%;
  max-width: 45rem;

  & > div {
    transform-origin: center;
    display: inline-block;
  }

  @media screen and (max-width: 1150px) {
    width: ${getVar('mobileContentWidth')};
    max-width: ${getVar('maxWidth')};
  }
`;

const ImageReveal = styled(Reveal)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;

  //For responsive > FullHD
  height: 70.8rem;
  //For responsive > FullHD

  @media screen and (max-width: 1150px) {
    position: relative;
    top: unset;
    right: unset;
    transform: unset;
    margin: 0 0 10rem auto;
    height: 60.8rem;
  }

  @media screen and (max-width: 800px) {
    height: 55.8rem;
  }

  @media screen and (max-width: 700px) {
    height: unset;
    width: ${getVar('maxWidth')};
  }
`;

const Image = styled.img`
  //For responsive > FullHD
  height: 70.8rem;
  //For responsive > FullHD

  @media screen and (max-width: 1150px) {
    height: 60.8rem;
  }

  @media screen and (max-width: 800px) {
    height: 55.8rem;
  }

  @media screen and (max-width: 700px) {
    height: unset;
    width: ${getVar('maxWidth')};
  }
`;

/* ******************************************************  Export  ****************************************************** */
export const AppsWebPlannerStyles = {
  WebPlannerContainer,
  TextWrapperFade,
  TextWrapper,
  ImageReveal,
  Image,
};
