/* ******************************************************  Imports  ****************************************************** */
import { ImprintStyles } from './Imprint.styles';

import PhoneIcon from '../../../../assets/images/legal/phone.svg';
import EmailIcon from '../../../../assets/images/legal/mail.svg';
import ExternalLinkIcon from '../../../../assets/images/legal/external-link.svg';

/* ******************************************************  Component  ****************************************************** */
export const ImprintEN = () => {
  /* ******************************************************  Template  ****************************************************** */
  return (
    <>
      <ImprintStyles.LandingContainer>
        <ImprintStyles.Heading className="mainText">
          Imprint
        </ImprintStyles.Heading>
      </ImprintStyles.LandingContainer>

      <ImprintStyles.ImprintContainer>
        <ImprintStyles.SubtitleContainer>
          <ImprintStyles.StyledH4>
            Information according to § 5 para. 1 of the Telemedia Act:
          </ImprintStyles.StyledH4>
        </ImprintStyles.SubtitleContainer>
        <ImprintStyles.Divider />
        <ImprintStyles.Row>
          <ImprintStyles.StyledH5>Provider name:</ImprintStyles.StyledH5>
          <ImprintStyles.StyledText>
            ElectricRoutes GmbH
          </ImprintStyles.StyledText>
        </ImprintStyles.Row>
        <ImprintStyles.Row>
          <ImprintStyles.StyledH5>Address:</ImprintStyles.StyledH5>
          <ImprintStyles.StyledText>
            <div>Heilbronner Str. 86</div>
            <div>70191 Stuttgart</div>
            <div>Deutschland</div>
          </ImprintStyles.StyledText>
        </ImprintStyles.Row>
        <ImprintStyles.Row>
          <ImprintStyles.StyledH5>
            Authorized representative managing director:
          </ImprintStyles.StyledH5>
          <ImprintStyles.StyledText>Andreas Bisenius</ImprintStyles.StyledText>
        </ImprintStyles.Row>
        <ImprintStyles.Row>
          <ImprintStyles.StyledH5>Registry Court:</ImprintStyles.StyledH5>
          <ImprintStyles.StyledText>
            Amtsgericht Stuttgart
          </ImprintStyles.StyledText>
        </ImprintStyles.Row>
        <ImprintStyles.Row>
          <ImprintStyles.StyledH5>Register number:</ImprintStyles.StyledH5>
          <ImprintStyles.StyledText>HRB 780686</ImprintStyles.StyledText>
        </ImprintStyles.Row>
        <ImprintStyles.Row>
          <ImprintStyles.StyledH5>Contact details:</ImprintStyles.StyledH5>
          <ImprintStyles.StyledText>
            <ImprintStyles.StyledMiniRow>
              <ImprintStyles.StyledIcon src={EmailIcon} />
              <ImprintStyles.StyledLink
                href={'mailto:contact@electricroutes.com'}
              >
                contact@electricroutes.com
              </ImprintStyles.StyledLink>
            </ImprintStyles.StyledMiniRow>
            <ImprintStyles.StyledMiniRow>
              <ImprintStyles.StyledIcon src={PhoneIcon} />
              <ImprintStyles.StyledLink href={'tel:+49 711 252 749-0'}>
                +49 711 252 749-0
              </ImprintStyles.StyledLink>
            </ImprintStyles.StyledMiniRow>
            <ImprintStyles.StyledMiniRow>
              <ImprintStyles.StyledIcon src={ExternalLinkIcon} />
              <ImprintStyles.StyledLink
                href={'https://www.electricroutes.com'}
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.electricroutes.com
              </ImprintStyles.StyledLink>
            </ImprintStyles.StyledMiniRow>
          </ImprintStyles.StyledText>
        </ImprintStyles.Row>

        <ImprintStyles.SubtitleContainer>
          <ImprintStyles.StyledH4>
            Consumer Dispute Resolution
          </ImprintStyles.StyledH4>
        </ImprintStyles.SubtitleContainer>
        <ImprintStyles.Divider />
        <ImprintStyles.StyledText2>
          <p>
            The European Commission provides an online platform for online
            dispute resolution, which can be found at&nbsp;
            <ImprintStyles.StyledLink
              href={'http://ec.europa.eu/consumers/odr/'}
              target="_blank"
              rel="noopener noreferrer"
            >
              http://ec.europa.eu/consumers/odr/.
            </ImprintStyles.StyledLink>
          </p>
          <br />
          <p>
            ElectricRoutes GmbH is neither willing nor obliged to participate in
            dispute resolution proceedings before a consumer arbitration board.
          </p>
          <br />
          <p>
            Additional information on professional liability insurance pursuant
            to Section 2 (1) No. 11 of the Ordinance on Information Duties for
            Service Providers (DL-InfoV):
          </p>
        </ImprintStyles.StyledText2>
        <ImprintStyles.Row>
          <ImprintStyles.StyledH5>
            Name and address of the insurer:
          </ImprintStyles.StyledH5>
          <ImprintStyles.StyledText>
            <div>AIG Europe S.A.</div>
            <div>Direktion für Deutschland</div>
            <div>Neue Mainzer Straße 46 – 50</div>
            <div>60311 Frankfurt</div>
          </ImprintStyles.StyledText>
        </ImprintStyles.Row>
        <ImprintStyles.Row>
          <ImprintStyles.StyledH5>Spatial scope:</ImprintStyles.StyledH5>
          <ImprintStyles.StyledText>
            Worldwide insurance coverage
          </ImprintStyles.StyledText>
        </ImprintStyles.Row>

        <ImprintStyles.SubtitleContainer>
          <ImprintStyles.StyledH4>Copyright notice</ImprintStyles.StyledH4>
        </ImprintStyles.SubtitleContainer>
        <ImprintStyles.Divider />
        <ImprintStyles.StyledText2>
          <p>
            The content available on our website is generally subject to
            copyright protection. Any unauthorized use (in particular copying,
            editing or distribution) of this copyright-protected content is
            therefore prohibited.
          </p>
          <br />
          <p>
            The Charger data for routing is provided in cooperation with
            CIRRANTiC GmbH (
            <ImprintStyles.StyledLink
              href={'https://cirrantic.com/'}
              target="_blank"
              rel="noopener noreferrer"
            >
              https://cirrantic.com
            </ImprintStyles.StyledLink>
            ). Use of functions and details of the Charger data is only
            permitted for route planning with ElectricRoutes webapp and app for
            end users. Automated queries of the Charger data
            (bots/scraping/crawling) and all commercial uses of the functions
            and Charger data are not permitted. More information on Charger data
            is available at MOOVILITY Webapp (
            <ImprintStyles.StyledLink
              href={'https://moovility.com'}
              target="_blank"
              rel="noopener noreferrer"
            >
              https://moovility.com
            </ImprintStyles.StyledLink>
            ) and App (
            <ImprintStyles.StyledLink
              href={'https://moovility.com/app'}
              target="_blank"
              rel="noopener noreferrer"
            >
              https://moovility.com/app
            </ImprintStyles.StyledLink>
            ).
          </p>
        </ImprintStyles.StyledText2>
      </ImprintStyles.ImprintContainer>
    </>
  );
};
