/* ******************************************************  Imports  ****************************************************** */
import { useTranslation } from 'react-i18next';
import { PersonModel } from './person.model';
import { PersonStyles } from './Person.styles';

/* ******************************************************  Component  ****************************************************** */
export const Person = (person: PersonModel) => {
  /* ******************************************************  State  ****************************************************** */
  const { t } = useTranslation();

  /* ******************************************************  Template  ****************************************************** */
  return (
    <PersonStyles.PersonContainer>
      <PersonStyles.PersonImageContainer>
        {person.image ? (
          <PersonStyles.PersonImage src={person.image} alt={person.name} />
        ) : null}
      </PersonStyles.PersonImageContainer>
      <PersonStyles.PersonName>{person.name}</PersonStyles.PersonName>
      <PersonStyles.PersonJob>
        {t('about.people.' + person.job)}
      </PersonStyles.PersonJob>
    </PersonStyles.PersonContainer>
  );
};
