import { ContactFormTopicModel } from '../shared/components/footer/contact-form/contact-form.model';
import { Subject, Observable } from 'rxjs';

const alert = new Subject();

const changeTopic = (newTopicValue: ContactFormTopicModel): void => {
  alert.next(newTopicValue);
};

export const contactFormTopicService: {
  topic: Observable<ContactFormTopicModel>;
  changeTopic(newTopicValue: ContactFormTopicModel): void;
} = {
  topic: alert.asObservable() as Observable<ContactFormTopicModel>,
  changeTopic,
};
