/* ******************************************************  Imports  ****************************************************** */
import { Fade } from 'react-awesome-reveal';
import { useTranslation } from 'react-i18next';
import { PEOPLE } from '../people/people.data';
import { AboutLandingStyles } from './AboutLanding.styles';

/* ******************************************************  Component  ****************************************************** */
export const AboutLanding = () => {
  /* ******************************************************  State  ****************************************************** */
  const { t } = useTranslation();

  const countPeople = () => {
    return (
      PEOPLE.management.length +
      PEOPLE.engineers.length +
      PEOPLE.creative.length +
      PEOPLE.support.length +
      ' '
    );
  };

  /* ******************************************************  Template  ****************************************************** */
  return (
    <AboutLandingStyles.LandingContainer>
      <Fade direction="down" duration={1000} triggerOnce={true} delay={500}>
        <AboutLandingStyles.Text className="mainText">
          {t('about.landing', { numberOfPeople: countPeople() })}
        </AboutLandingStyles.Text>
      </Fade>
    </AboutLandingStyles.LandingContainer>
  );
};
