/* ******************************************************  Imports  ****************************************************** */
import styled from '@emotion/styled';

import { getColor } from '../../../theme/colors/colors';
import { getVar } from '../../../theme/ui-variables/ui-variables';

/* ******************************************************  Styles  ****************************************************** */
const InCarContainer = styled.div`
  position: relative;
  background-color: ${getColor('background')};
  padding: 25rem 0;

  @media screen and (max-width: 1150px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 20rem 0;
  }
`;

const TextWrapper = styled.div`
  width: 100%;
  max-width: 45rem;
  margin-left: calc((100% - ${getVar('maxWidth')}) / 2);

  @media screen and (max-width: 1150px) {
    width: ${getVar('mobileContentWidth')};
    max-width: ${getVar('maxWidth')};
  }
`;

const Image = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  //For responsive > FullHD
  height: 49.2rem;
  //For responsive > FullHD

  @media screen and (max-width: 1150px) {
    position: relative;
    top: unset;
    right: unset;
    transform: unset;
    margin: 0 0 10rem auto;
  }

  @media screen and (max-width: 800px) {
    height: 44.2rem;
  }

  @media screen and (max-width: 700px) {
    height: unset;
    width: ${getVar('maxWidth')};
  }
`;

/* ******************************************************  Export  ****************************************************** */
export const AppsInCarStyles = {
  InCarContainer,
  TextWrapper,
  Image,
};
