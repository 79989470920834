import { useCallback } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useDataTrackingEnabled } from 'core/shared/hooks/useDataTrackingEnabled';

type AnalyticsEventCategory = 'Mobile & Web App' | 'B2B' | 'Home' | 'General';

interface AnalyticsEventParams {
  name?: string;
  category: AnalyticsEventCategory;
  action: string;
}

export const useAppAnalytics = () => {
  const { trackEvent: externalLibraryTrackEvent } = useMatomo();
  const [trackingEnabled] = useDataTrackingEnabled();

  const trackEvent = useCallback(
    ({ name, category, action }: AnalyticsEventParams) => {
      if (!trackingEnabled) {
        return;
      }

      externalLibraryTrackEvent({
        name,
        category,
        action,
      });
    },
    [externalLibraryTrackEvent, trackingEnabled]
  );

  return {
    trackEvent,
  };
};
