/* ******************************************************  Imports  ****************************************************** */
import styled from '@emotion/styled';
import { getColor } from '../../../../theme/colors/colors';

/* ******************************************************  Styles  ****************************************************** */
const PersonContainer = styled.div`
  margin-bottom: 4rem;

  &:not(:last-of-type) {
    margin-right: 3.3rem;

    @media screen and (max-width: 516px) {
      margin-right: 1.5rem;
    }
  }
`;

const PersonImageContainer = styled.div`
  width: 15rem;
  height: 19.4rem;
  margin-bottom: 1rem;
  background-color: ${getColor('textGrey', 0.2)};
  border-radius: 1.4rem;
  box-shadow: 0 0.4rem 1.2rem ${getColor('adminBlack', 0.12)};
`;

const PersonImage = styled.img`
  width: 15rem;
  height: 19.4rem;
`;

const PersonName = styled.p`
  font-size: 1.8rem;
  font-weight: 700;
`;

const PersonJob = styled.p`
  font-size: 1.2rem;
  font-weight: 400;
`;

/* ******************************************************  Export  ****************************************************** */
export const PersonStyles = {
  PersonContainer,
  PersonImageContainer,
  PersonImage,
  PersonName,
  PersonJob,
};
