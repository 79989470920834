/* ******************************************************  Imports  ****************************************************** */
import styled from '@emotion/styled';
import { getColor } from '../../../theme/colors/colors';
import { getVar } from '../../../theme/ui-variables/ui-variables';

/* ******************************************************  Styles  ****************************************************** */
const FooterContainer = styled.div`
  position: relative;
  width: 100%;
  padding: ${getVar('footerVerticalSpacing')} 0;
  background-color: ${getColor('primary')};
  color: ${getColor('primaryContrast')};
  overflow: hidden;

  & *:not(.erButton) {
    color: ${getColor('primaryContrast')};
  }

  & .erButton * {
    color: inherit !important;
  }

  &.white {
    background-color: ${getColor('primaryContrast')};
    color: ${getColor('adminBlack')};

    & *:not(.erButton) {
      color: ${getColor('adminBlack')};
    }

    & .erButton > span {
      color: inherit;
    }
  }

  &.black {
    background-color: ${getColor('adminBlack')};
  }

  @media screen and (max-width: 1150px) {
    padding: ${getVar('footerVerticalSpacing')} 0 20rem 0;
  }

  @media screen and (max-width: 499px) {
    padding-bottom: 25rem;
  }
`;

const BackgroundLogoImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.1;
  //For responsive > FullHD
  height: 106rem;
  //For responsive > FullHD

  &.no-opacity {
    opacity: 1;
  }
`;

const Heading = styled.p`
  margin-left: calc((100% - ${getVar('maxWidth')}) / 2);
  margin-bottom: 6rem;
  position: relative;
  width: 100%;
  max-width: ${getVar('maxWidth')};
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: ${getVar('maxWidth')};
  margin: 0 auto;
  display: flex;
  align-items: normal;
  justify-content: space-between;
  position: relative;

  @media screen and (max-width: 1150px) {
    flex-direction: column-reverse;
  }
`;

const FormContainer = styled.div`
  width: ${getVar('contactFormWidth')};

  @media screen and (max-width: 1150px) {
    width: 100%;
    max-width: 70rem;
    margin-top: 10rem;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  width: calc(100% - ${getVar('contactFormWidth')} - 12.5rem);

  @media screen and (max-width: 1150px) {
    width: 100%;
  }
`;

/* ******************************************************  Export  ****************************************************** */
export const FooterStyles = {
  FooterContainer,
  BackgroundLogoImage,
  Heading,
  ContentContainer,
  FormContainer,
  LinksContainer,
};
