import androidButtonIcon from 'assets/images/android-store.svg';
import { APP_LINKS } from 'core/shared/appLinks';

export const GoogleButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <a href={APP_LINKS.android} target="_blank" rel="noopener noreferrer" onClick={onClick}>
      <img src={androidButtonIcon} alt="Android play store download button link" />
    </a>
  );
};
