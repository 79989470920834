/* ******************************************************  Imports  ****************************************************** */
import { Button } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import { HomeOperatorsStyles } from './HomeOperators.styles';

import apiLogo from '../../../../assets/images/logos/full-logo-api-white.svg';
import challengesLogo from '../../../../assets/images/logos/full-logo-challenge-white.svg';
import laptopImage from '../../../../assets/images/home/home-laptop-challenge.png';
import { API_LIST_ITEMS } from './api-list-item/api-list-items';
import { ApiListItem } from './api-list-item/ApiListItem';

import { NavLink } from 'react-router-dom';
import { APP_ROUTES } from '../../../shared/appRoutes';
import { contactFormTopicService } from '../../../services/contact-form-topic.service';
import { GetCustomTranslation } from '../../../shared/components/GetCustomTranslation';
import { useAppAnalytics } from 'analytics';
import { Fade } from 'react-awesome-reveal';
import { HomeOperatorsAnimations } from './home-operators.animations';
import { useResize } from '../../../shared/hooks/useResize';

/* ******************************************************  Component  ****************************************************** */
export const HomeOperators = () => {
  /* ******************************************************  State  ****************************************************** */
  const { t } = useTranslation();

  const windowSize = useResize();

  /* *************************************************** APP INSIGHTS **************************************************** */
  const { trackEvent } = useAppAnalytics();

  /* ******************************************************  Template  ****************************************************** */
  return (
    <HomeOperatorsStyles.OperatorsContainer>
      <Fade direction="down" duration={1000} triggerOnce>
        <HomeOperatorsStyles.OperatorsHeading className="mainText thin">
          {t('home.operators.heading')}
        </HomeOperatorsStyles.OperatorsHeading>
      </Fade>

      {/* ---------------------  API  --------------------- */}
      <HomeOperatorsStyles.ApiContainer>
        <Fade direction="left" duration={1000} triggerOnce={true}>
          <HomeOperatorsStyles.ApiTextContainer>
            <div>
              <HomeOperatorsStyles.LogoImage src={apiLogo} alt="ElectricRoutes API Logo" />

              <h2
                className="mainText"
                dangerouslySetInnerHTML={GetCustomTranslation('home.operators.api.main')}
              ></h2>
              <p
                className="subText"
                dangerouslySetInnerHTML={GetCustomTranslation('home.operators.api.sub')}
              ></p>
            </div>

            <HomeOperatorsStyles.ButtonsWrapper>
              <Fade direction="down" delay={1000} duration={1000} triggerOnce={true}>
                <Button
                  className="erButton"
                  variant="outlined"
                  onClick={() =>
                    trackEvent({
                      action: '[Home - API Section] Contact Our Experts Clicked',
                      category: 'Home',
                    })
                  }
                >
                  <NavLink
                    to={APP_ROUTES.b2b + '#contactUs'}
                    onClick={() => {
                      contactFormTopicService.changeTopic('ROUTING_APIS');
                    }}
                  >
                    {t('home.operators.api.button.secondary')}
                  </NavLink>
                </Button>
              </Fade>
            </HomeOperatorsStyles.ButtonsWrapper>
          </HomeOperatorsStyles.ApiTextContainer>
        </Fade>

        <Fade direction="right" duration={1000} triggerOnce={true}>
          <HomeOperatorsStyles.ApiListContainer>
            <HomeOperatorsStyles.ApiListWrapper>
              <HomeOperatorsStyles.ApiList>
                {API_LIST_ITEMS.map((item) => (
                  <ApiListItem key={item.translationString} {...item} />
                ))}
              </HomeOperatorsStyles.ApiList>

              <HomeOperatorsStyles.ListBlur></HomeOperatorsStyles.ListBlur>
            </HomeOperatorsStyles.ApiListWrapper>

            <HomeOperatorsStyles.ButtonsWrapper>
              <Fade direction="down" delay={1000} duration={1000} triggerOnce={true}>
                <HomeOperatorsStyles.ButtonNoMargin
                  className="erButton orange"
                  variant="contained"
                  onClick={() =>
                    trackEvent({
                      action: '[Home - API Section] View All API Clicked',
                      category: 'Home',
                    })
                  }
                >
                  <NavLink to={APP_ROUTES.b2b}>{t('home.operators.api.button.primary')}</NavLink>
                </HomeOperatorsStyles.ButtonNoMargin>
              </Fade>
            </HomeOperatorsStyles.ButtonsWrapper>
          </HomeOperatorsStyles.ApiListContainer>
        </Fade>
      </HomeOperatorsStyles.ApiContainer>

      {/* ---------------------  OPERATOR PORTAL  --------------------- */}
      <HomeOperatorsStyles.PortalContainer>
        <HomeOperatorsStyles.LaptopImageReveal
          keyframes={
            windowSize.width > 1150
              ? HomeOperatorsAnimations.slideInRight
              : HomeOperatorsAnimations.slideInRightNoY
          }
          duration={1000}
          triggerOnce
        >
          <HomeOperatorsStyles.LaptopImage
            src={laptopImage}
            alt="Laptop mock with Operator Portal Application screen"
          />
        </HomeOperatorsStyles.LaptopImageReveal>

        <Fade direction="right" duration={1000} triggerOnce={true}>
          <HomeOperatorsStyles.PortalTextContainer>
            <HomeOperatorsStyles.LogoImage
              src={challengesLogo}
              alt="ElectricRoutes Operator Portal Logo"
            />

            <h2
              className="mainText"
              dangerouslySetInnerHTML={GetCustomTranslation('home.operators.operator.main')}
            ></h2>
            <p
              className="subText"
              dangerouslySetInnerHTML={GetCustomTranslation('home.operators.operator.sub')}
            ></p>

            <HomeOperatorsStyles.ButtonsWrapper>
              <Fade direction="down" delay={1000} duration={1000} triggerOnce={true}>
                <Button
                  className="erButton green"
                  variant="contained"
                  onClick={() =>
                    trackEvent({
                      action: '[Home - Operator Portal Section] Request Access Clicked',
                      category: 'Home',
                    })
                  }
                >
                  <NavLink
                    to={APP_ROUTES.b2b + '#contactUs'}
                    onClick={() => {
                      contactFormTopicService.changeTopic('OPERATOR_PORTAL');
                    }}
                  >
                    {t('home.operators.operator.button.primary')}
                  </NavLink>
                </Button>
              </Fade>

              <Fade direction="down" delay={1500} duration={1000} triggerOnce={true}>
                <Button
                  className="erButton"
                  variant="outlined"
                  onClick={() =>
                    trackEvent({
                      action: '[Home - Operator Portal Section] Find Out More Clicked',
                      category: 'Home',
                    })
                  }
                >
                  <NavLink to={APP_ROUTES.b2b + '#operatorPortal'}>
                    {t('home.operators.operator.button.secondary')}
                  </NavLink>
                </Button>
              </Fade>
            </HomeOperatorsStyles.ButtonsWrapper>
          </HomeOperatorsStyles.PortalTextContainer>
        </Fade>
      </HomeOperatorsStyles.PortalContainer>
    </HomeOperatorsStyles.OperatorsContainer>
  );
};
