/* ******************************************************  Imports  ****************************************************** */
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Button } from '@material-ui/core';

import { GetCustomTranslation } from '../../../shared/components/GetCustomTranslation';
import { contactFormTopicService } from '../../../services/contact-form-topic.service';
import { B2B_APIS_DATA } from './apis.data';

import { B2BApisStyles } from './B2BApis.styles';
import Logo from '../../../../assets/images/logos/full-logo-api-white.svg';
import { useAppAnalytics } from 'analytics';
import { Fade } from 'react-awesome-reveal';

/* ******************************************************  Component  ****************************************************** */
export const B2BApis = () => {
  /* ******************************************************  State  ****************************************************** */
  const [isAllLoaded, setIsAllLoaded] = useState<boolean>(false);

  const { t } = useTranslation();

  /* *************************************************** APP INSIGHTS **************************************************** */
  const { trackEvent } = useAppAnalytics();

  /* ******************************************************  Template  ****************************************************** */
  return (
    <B2BApisStyles.ApisContainer id="apiPortal">
      <B2BApisStyles.ContentContainer>
        <B2BApisStyles.LogoImage src={Logo} alt="ElectricRoutes APIs logo" />

        <Fade direction="down" duration={1000} triggerOnce={true}>
          <B2BApisStyles.TextContainer>
            <h2
              className="mainText"
              dangerouslySetInnerHTML={GetCustomTranslation('b2b.apis.main')}
            ></h2>
            <p className="subText">{t('b2b.apis.sub')}</p>
          </B2BApisStyles.TextContainer>
        </Fade>

        {/*
          I decided to go with 'display: none' rather than not rendering at all
          Because this way the pictures/icons will be loaded and ready to go before user clicks 'Load more'
        */}
        <B2BApisStyles.TilesContainer>
          {B2B_APIS_DATA.map((item, index) => (
            <B2BApisStyles.Tile
              key={item.title}
              className={index > 3 && !isAllLoaded ? 'hide' : ''}
              direction={index % 2 === 0 ? 'left' : 'right'}
              duration={1000}
              triggerOnce={true}
            >
              <div>
                <img src={item.icon} alt="" />

                <h4 className="topText">{t(item.title)}</h4>

                <p>{t(item.text)}</p>
              </div>
            </B2BApisStyles.Tile>
          ))}
        </B2BApisStyles.TilesContainer>

        <B2BApisStyles.ButtonsContainer>
          {!isAllLoaded ? (
            <Button
              className="erButton noLink"
              variant="outlined"
              onClick={() => {
                setIsAllLoaded(true);
                trackEvent({
                  action: '[B2B Offer - ER APIs Section] Load More Clicked',
                  category: 'B2B',
                });
              }}
            >
              {t('b2b.apis.loadMore')}
            </Button>
          ) : null}

          <Button
            className="erButton orange noLink"
            variant="contained"
            onClick={() => {
              window.scrollTo(0, document.body.scrollHeight);
              contactFormTopicService.changeTopic('ROUTING_APIS');
              trackEvent({
                action: '[B2B Offer - ER APIs Section] Contact Our Experts Clicked',
                category: 'B2B',
              });
            }}
          >
            {t('b2b.apis.contactOurExperts')}
          </Button>
        </B2BApisStyles.ButtonsContainer>
      </B2BApisStyles.ContentContainer>
    </B2BApisStyles.ApisContainer>
  );
};
