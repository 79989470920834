/* ******************************************************  Imports  ****************************************************** */
import { FormControlClassKey, StyleRules } from '@material-ui/core';

/* ******************************************************  Override  ****************************************************** */
export const CustomFormControl:
  | Partial<StyleRules<FormControlClassKey, {}>>
  | undefined = {
  marginDense: {
    '&.customInput': {
      marginTop: '0',
      marginBottom: '0',

      '& .MuiInput-formControl': {
        height: '2.5rem',
        marginTop: '1rem',
      },
      '& .MuiInput-underline': {
        '&:before': {
          border: 'none !important',
        },
        '&:after': {
          border: 'none !important',
        },
      },
      '& .MuiInputBase-inputMarginDense': {
        padding: '1rem 0 0 0',
        fontSize: '1.8rem',
      },
      '& .MuiInputLabel-marginDense': {
        transform: 'translate(0, 1.3rem) scale(1)',
        fontSize: '1.8rem',
      },
      '& .MuiInputLabel-shrink': {
        transform: 'translate(0, -0.35rem) scale(0.75)',
      },

      '&.customSelect': {
        '& .MuiInputLabel-shrink': {
          padding: '0 2.95rem',
        },
        '& .MuiInputBase-inputMarginDense': {
          padding: '1.5rem 2.5rem 0 2.5rem',
        },
        '& .MuiSelect-icon': {
          top: 'calc(50% - 1.6rem)',
          marginRight: '2.5rem',
        },
        '& .MuiSelect-select:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
};
