/* ******************************************************  Imports  ****************************************************** */
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';

/* ******************************************************  Component  ****************************************************** */
export const GetCustomTranslation = (translationKey: string) => {
  /* ******************************************************  Component  ****************************************************** */
  const { t } = useTranslation();

  /* ******************************************************  Template  ****************************************************** */
  return { __html: DOMPurify.sanitize(t(translationKey)) };
};
