/* ******************************************************  Imports  ****************************************************** */
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { DataTrackingPolicyRow } from './DataTrackingPolicyRow';
import { PrivacyPolicyStyles } from './PrivacyPolicy.styles';

/* ******************************************************  Component  ****************************************************** */
export const PrivacyPolicyEN = () => {
  /* ******************************************************  Template  ****************************************************** */
  return (
    <>
      <PrivacyPolicyStyles.LandingContainer>
        <PrivacyPolicyStyles.Heading className="mainText">
          Data Privacy Policy
        </PrivacyPolicyStyles.Heading>
        <PrivacyPolicyStyles.Updated>Updated 09/29/2021</PrivacyPolicyStyles.Updated>
      </PrivacyPolicyStyles.LandingContainer>

      <PrivacyPolicyStyles.PrivacyPolicyContainer>
        <div className="noAccordion">
          <p className="privacyParagraph">
            ElectricRoutes GmbH ("ElectricRoutes" or "we") makes "ElectricRoutes" available to you
            on the website{' '}
            <PrivacyPolicyStyles.StyledLink
              href={'https://www.electricroutes.com'}
              rel="noopener noreferrer"
              target="_blank"
            >
              https://www.electricroutes.com
            </PrivacyPolicyStyles.StyledLink>{' '}
            („Website“).
          </p>
          <p className="privacyParagraph">
            With the following privacy policy for the Website, we explain to you what personal data
            we collect, for what purposes we process it and what rights you have when you visit the
            Website and use the "ElectricRoutes" service via the Website. The protection of your
            personal data is important to us.
          </p>
          <p className="privacyParagraph">
            According to the EU General Data Protection Regulation ("GDPR"), personal data is any
            information relating to an identified or identifiable natural person ("data subject").
            An identifiable natural person is one who can be identified, directly or indirectly, in
            particular by reference to an identifier such as a name, an identification number,
            location data, an online identifier or one or more particular personal characteristics.
            This means that both your personal data, such as your name, your postal address or your
            e-mail address, and the technical data created or processed when you use the website,
            which can be assigned to you, such as the IP address, the operating system, the browser
            of your terminal device or your user behavior, are personal data.
          </p>
        </div>

        <PrivacyPolicyStyles.StyledDiv>
          {/* ------------------- HEADING 1 ------------------- */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              data-cy="privacy-accordion-1"
            >
              {'1. Responsible for Data Protection'}
            </AccordionSummary>
            <AccordionDetails>
              <p className="privacyParagraph">
                Responsible under data protection law for the collection and processing of your
                personal data when visiting and using the website is
              </p>
              <p className="privacyParagraph">
                <span>ElectricRoutes GmbH</span>
                <span>Heilbronner Straße 86</span>
                <span>70191 Stuttgart</span>
              </p>
              <p className="privacyParagraph">
                <span>
                  E-Mail:&nbsp;
                  <PrivacyPolicyStyles.StyledLink href={'mailto:contact@electricroutes.com'}>
                    contact@electricroutes.com
                  </PrivacyPolicyStyles.StyledLink>
                </span>
                <span>
                  Phone:&nbsp;
                  <PrivacyPolicyStyles.StyledLink href={'tel:+49 711 252 749-0'}>
                    +49 711 252 749-0
                  </PrivacyPolicyStyles.StyledLink>
                </span>
              </p>
            </AccordionDetails>
          </Accordion>

          {/* ------------------- HEADING 2 ------------------- */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              data-cy="privacy-accordion-2"
            >
              {'2. Data Protection Officer'}
            </AccordionSummary>
            <AccordionDetails>
              <p className="privacyParagraph">
                If you have any questions, concerns or suggestions regarding data protection on our
                website, you can contact our data protection officer at any time:
              </p>

              <p className="privacyParagraph">
                <span>SICODA GmbH</span>
                <span>Oliver Gönner c/o DSB – P3 group GmbH</span>
              </p>

              <p className="privacyParagraph">
                <span>Rochusstraße 198</span>
                <span>53123 Bonn</span>
              </p>

              <p className="privacyParagraph">
                <span>
                  E-Mail:&nbsp;
                  <PrivacyPolicyStyles.StyledLink href={'mailto:datenschutz.global@p3-group.com'}>
                    datenschutz.global@p3-group.com
                  </PrivacyPolicyStyles.StyledLink>
                </span>
                <span>
                  Phone:&nbsp;
                  <PrivacyPolicyStyles.StyledLink href={'tel:+49 228 286 140 60'}>
                    +49 228 286 140 60
                  </PrivacyPolicyStyles.StyledLink>
                </span>
              </p>

              <p className="privacyParagraph">
                You can also assert your data subject rights with our data protection officer. You
                can find more information on this in section 8.
              </p>
            </AccordionDetails>
          </Accordion>

          {/* ------------------- HEADING 3 ------------------- */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              {'3. Purposes and Legal Bases of Data Processing - Automatically Collected Data'}
            </AccordionSummary>
            <AccordionDetails>
              <p className="privacyParagraph">
                The visit and purely informational use of our website is possible without you
                yourself (for example, by registering or submitting in online forms) personal data,
                such as your name, postal address or e-mail address, or we collect such personal
                data.
              </p>
              <p className="privacyParagraph">
                However, in order for you to view our website and use it in a user-friendly,
                effective, stable and secure manner, we automatically collect and process the
                necessary (pseudonymous) technical data in interaction with your terminal device and
                your browser.
              </p>

              <h5>3.1 Automatic Data Collection and Processing by Web Servers</h5>

              <p className="privacyParagraph">
                In order to be able to show you the individual web pages belonging to our website,
                our web servers automatically collect and process the following personal data
                transmitted by your browser:
              </p>

              <ul>
                <li>IP address of your accessing terminal device,</li>
                <li>Name and URL of the file accessed,</li>
                <li>date and time of access,</li>
                <li>mount of data transferred,</li>
                <li>message as to whether the file was retrieved successfully,</li>
                <li>
                  Recognition data (type, version and language) of the browser and operating system
                  used by your accessing end device,
                </li>
                <li>
                  URL of the referring website, if accessed via link, and additionally search term,
                  if accessed via search engine, and
                </li>
                <li>name of your Internet access provider</li>
              </ul>

              <p className="privacyParagraph">
                The legal basis for this data processing is Art. 6 para. 1 sentence 1 lit. b) GDPR.
                The data processing is necessary for the implementation of pre-contractual measures
                that take place upon your request, as such pre-contractual measures also include
                website visits.
              </p>

              <p className="privacyParagraph">
                The (pseudonymous) technical communication data collected and processed to enable
                the use of the website (connection establishment) is temporarily stored by our web
                servers in log files, so-called server log files. We use the server log files for
                internal system-related purposes, in particular for technical administration and to
                ensure the stability and security of our web servers and website, e.g. to identify
                and track unauthorized or abusive access attempts to our web servers via the stored
                IP address. We also use the server log files for statistical purposes in order to
                record and evaluate access to our website and its content in a pseudonymous manner
                and, on this basis, to be able to design and optimize our website in a more
                needs-oriented, user-friendly and effective manner. The legal basis for this data
                processing of the server log files is Article 6 (1) sentence 1 lit. f) GDPR, which
                permits the processing of personal data in the context of our "legitimate
                interests", unless your fundamental rights, freedoms or interests prevail. Our
                legitimate interests are the easy and secure administration of our website or the
                measurement, analysis and improvement of our website.
              </p>

              <h5>3.2 Map display by "Google Maps“</h5>

              <p className="privacyParagraph">
                With your consent, your personal route will be displayed when using "ElectricRoutes"
                within the map service "Google Maps". "Google Maps" is a service of Google Ireland
                Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Ireland ("Google").
              </p>
              <p className="privacyParagraph">
                The legal basis for the use of "Google Maps" on our website is Article 6 (1)
                sentence 1 lit. a{')'} GDPR, which permits the processing of personal data with the
                consent of the data subject.
              </p>
              <p className="privacyParagraph">
                Within ElectricRoutes, you have the option to activate "Google Maps" by providing
                the following declaration of consent:
              </p>

              <p className="privacyParagraph quote">Activation of the Google Maps service</p>
              <p className="privacyParagraph quote">
                In order to display your route in Google Maps, your consent is required. By clicking
                the "Agree" button, you consent to Google Maps being activated and to data about
                your use of "ElectricRoutes" being transferred to Google. Please note that the use
                of Google Maps may also result in data transfers to Google servers in the USA and
                thus to a country outside the European Union without a comparable level of data
                protection.
              </p>
              <p className="privacyParagraph quote">
                For more information about the nature and purposes of data processing within the
                framework of Google Maps and about your rights, please refer to the privacy policy (
                <PrivacyPolicyStyles.StyledLink
                  href={'https://policies.google.com/privacy'}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  https://policies.google.com/privacy
                </PrivacyPolicyStyles.StyledLink>
                ).
              </p>

              <p className="privacyParagraph">
                If you give this consent, Google will receive the information that you have accessed
                the website concerned. Furthermore, Google receives access to the (pseudonymous)
                technical communication data mentioned in section 3.1 and possibly further personal
                data such as your location data or data about the use of other pages of this
                website. The data transfer to Google takes place regardless of whether you have a
                user account with Google via which you are logged in or not.
              </p>
              <p className="privacyParagraph">
                If you are logged in to Google, your data will be directly assigned to your account.
                If you do not want the assignment to your profile at Google, you must log out before
                calling up pages with Google Maps. Google stores your data as usage profiles and
                uses them for the purpose of tailoring its offers, advertising and/or market
                research. Such an evaluation of your data, in particular for the provision of
                needs-based advertising, takes place on a pseudonymous basis even if you are not
                logged in to Google or do not have a Google user account. You have the right to
                object to the creation of these (pseudonymous) user profiles, whereby you must
                contact Google to exercise this right.
              </p>
              <p className="privacyParagraph">
                We have no influence on the data collection and processing carried out by Google and
                are not responsible for it. We are neither aware of this data processing nor of all
                its purposes and storage periods. Please note that when using "Google Maps", data
                may also be transferred to servers of Google LLC in the USA, a third country outside
                the European Union and the European Economic Area. If you do not wish data to be
                transferred to Google, you should not consent to the use of "Google Maps". The
                display of your personal route by "ElectricRoutes" is not possible in this case.
              </p>
              <p className="privacyParagraph">
                Google's terms of use can be found at&nbsp;
                <PrivacyPolicyStyles.StyledLink
                  href={'https://www.google.de/intl/de/policies/terms/regional.html'}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  https://www.google.de/intl/de/policies/terms/regional.html
                </PrivacyPolicyStyles.StyledLink>
                , and the additional terms of use for Google Maps can be found at&nbsp;
                <PrivacyPolicyStyles.StyledLink
                  href={'https://www.google.com/intl/de_US/help/terms_maps.html'}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  https://www.google.com/intl/de_US/help/terms_maps.html
                </PrivacyPolicyStyles.StyledLink>
                . Detailed information on data protection in connection with the use of Google Maps
                can be found at&nbsp;
                <PrivacyPolicyStyles.StyledLink
                  href={'https://www.google.de/intl/de/policies/privacy/'}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  https://www.google.de/intl/de/policies/privacy/
                </PrivacyPolicyStyles.StyledLink>
                . If you wish to object to data collection and processing by Google, you can do so
                at&nbsp;
                <PrivacyPolicyStyles.StyledLink
                  href={'https://adssettings.google.com/authenticated'}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  https://adssettings.google.com/authenticated
                </PrivacyPolicyStyles.StyledLink>
                .
              </p>

              <h5>3.3 Location Query by Browser</h5>

              <p className="privacyParagraph">
                Via the corresponding dialog box in your browser, you have the option of enabling
                the use of your location by "ElectricRoutes". In this case, your browser
                automatically sends us information about your approximate location upon request. We
                process your location data exclusively for navigation and location display as part
                of "ElectricRoutes".
              </p>
              <p className="privacyParagraph">
                You can revoke your consent at any time with future effect by deactivating the
                release of your location for the website in your browser settings.
              </p>
              <p className="privacyParagraph">
                The legal basis for the automatic collection and use of your location data on our
                website is Art. 6 (1) sentence 1 lit. a{')'}
                GDPR, which permits the processing of personal data with the consent of the data
                subject.
              </p>
            </AccordionDetails>
          </Accordion>

          {/* ------------------- HEADING 4 ------------------- */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4a-content"
              id="panel4a-header"
            >
              {'4. Purposes and Legal Bases of Data Processing - Data Provided by You'}
            </AccordionSummary>
            <AccordionDetails>
              <p className="privacyParagraph">
                The use of certain functions and offers on our website, in particular route planning
                with "ElectricRoutes", is only possible on the basis of personal data. We only
                process such data if you provide it to us yourself or expressly consent to the
                collection of this data.
              </p>

              <h5>4.1 Data processing within the scope of "ElectricRoutes“</h5>
              <p className="privacyParagraph">
                For route planning with "ElectricRoutes", we process the data you provide us with in
                the corresponding input fields, namely
              </p>

              <ul>
                <li>Start and destination locations,</li>
                <li>vehicle type (make and model),</li>
                <li>preferred battery charging criteria,</li>
                <li>Battery charge (State of Charge, SoC),</li>
                <li>Charging station selection criteria.</li>
              </ul>

              <p className="privacyParagraph">
                The data you enter and - if you have consented via your browser - the automatically
                collected location data are processed by us to calculate a route and offer you
                individualized navigation. In doing so, our service calculates an optimized route
                based on your information, including charging stops that are matched to the charging
                curve of your vehicle. We have no way of assigning this data to you personally.
              </p>
              <p className="privacyParagraph">
                The legal basis for the data processing for route calculation and navigation within
                the scope of "ElectricRoutes" is Art. 6 (1) sentence 1 lit. b{')'} GDPR. The
                processing of your personal data is necessary to provide you with the
                "ElectricRoutes" service. Insofar as you have given your consent to the location
                query through your browser, we process your location data on the basis of Art. 6 (1)
                sentence 1 lit. a{')'} GDPR, which permits the processing of personal data with the
                consent of the data subject.
              </p>
              <p className="privacyParagraph">
                We store the data generated when using "ElectricRoutes" in such an anonymized form
                that it is no longer possible to establish a personal reference. We perform
                statistical analyses of this anonymized data in order to learn more about the use of
                E-Mobility and to be able to improve our service. The anonymization of the data is
                based on our legitimate interest according to Art. 6 (1) sentence 1 lit. f{')'}
                GDPR to be able to statistically evaluate the data in anonymized form.
              </p>
              <p className="privacyParagraph">
                As part of the "ElectricRoutes Community", you also have the option of rating
                charging stations according to predefined criteria. We store your rating and make it
                available to other ElectricRoutes users in the status information for the charging
                station in question. Your rating remains anonymous - other users will not see your
                name or other identifying characteristics. We reserve the right to ensure that only
                one rating per user can be stored by processing (pseudonymous) characteristics such
                as the device ID. Data processing in connection with ratings is carried out on the
                basis of Art. 6 (1) sentence 1 lit. f{')'}
                GDPR, which permits the processing of personal data in the context of our
                "legitimate interests", unless your fundamental rights, freedoms or interests
                prevail. Our legitimate interests are the provision of an objective rating system
                for charging stations within the framework of our service "ElectricRoutes“.
              </p>

              <h5>4.2 Communication via E-Mail</h5>

              <p className="privacyParagraph">
                If you contact us by e-mail, we will process your request and your e-mail address
                exclusively for processing your request and possible queries.
              </p>
              <p className="privacyParagraph">
                The legal basis for the processing of your messages and your email address is Art. 6
                para. 1 sentence 1 letter b{')'} GDPR.
              </p>
            </AccordionDetails>
          </Accordion>

          {/* ------------------- HEADING 5 ------------------- */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5a-content"
              id="panel5a-header"
            >
              {'5. Recipients of Personal Data'}
            </AccordionSummary>
            <AccordionDetails>
              <p className="privacyParagraph">
                The personal data collected by us in the course of your visit to our website will
                generally not be passed on to other recipients or transmitted in any other way,
                unless this is expressly stated in the data protection declaration. Exceptions to
                this are mandatory transfers of personal data to government institutions and
                authorities and private rights holders on the basis of statutory provisions or
                judicial or official decisions, as well as the necessary disclosure to government
                institutions and authorities in the event of attacks on our legal assets for the
                purposes of legal or criminal prosecution.
              </p>
              <p className="privacyParagraph">
                However, in the operation of our website and the provision and processing of
                individual website functions and offers, we sometimes use external technical and
                other service providers who support us in the provision of our services and have
                been carefully selected by us. To the extent necessary, these service providers
                engaged by us process your personal data within the scope of their respective
                assignment in accordance with our instructions for the purposes stated in this data
                protection notice. They are contractually obligated to strictly comply with this
                privacy notice, the applicable statutory data protection provisions and our
                instructions (so-called order processors pursuant to Art. 28 GDPR). The same applies
                to any subcontractors, insofar as our processors use such with our prior consent.
              </p>
              <p className="privacyParagraph">
                We use order processors in particular for hosting the website and our route
                navigation database.
              </p>
            </AccordionDetails>
          </Accordion>

          {/* ------------------- HEADING 6 ------------------- */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel6a-content"
              id="panel6a-header"
            >
              {'6. Processing of Personal Data in "Third Countries“'}
            </AccordionSummary>
            <AccordionDetails>
              <p className="privacyParagraph">
                We process the personal data collected on the website within the member states of
                the European Union. A transfer of personal data to other "third countries", i.e.
                countries outside the European Union and the European Economic Area, only takes
                place if we explicitly inform you of this in this privacy policy.
              </p>
              <p className="privacyParagraph">
                The use of "Google Maps" described in section 3.2. may result in data transfers to
                the USA. The collection and processing of data by "Google Maps" is carried out
                exclusively with your consent in accordance with Art. 6 para. 1 sentence 1 lit. a
                {')'} GDPR in conjunction with Art. 49 para.1 lit. a{')'} GDPR.
              </p>
            </AccordionDetails>
          </Accordion>

          {/* ------------------- HEADING 7 ------------------- */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel7a-content"
              id="panel7a-header"
            >
              {'7. Duration of the Storage'}
            </AccordionSummary>
            <AccordionDetails>
              <p className="privacyParagraph">
                In general, we only store personal data for as long as is necessary to fulfill the
                purpose of the respective data processing or until you request us to delete the
                personal data for other legitimate reasons (see Art. 17 (1) GDPR). After expiration
                of these storage periods or upon your legitimate request for deletion, we check in
                each case whether certain personal data are still needed for (other) legitimate
                purposes and, if not, whether a deletion of the personal data is precluded by
                contractual or statutory retention periods. In these cases, the personal data
                affected by this will continue to be stored for as long as necessary to fulfill this
                (other) legitimate purpose, or for the duration of the respective contractual or
                legal retention period for these purposes. For other purposes, however, they are
                blocked and then finally deleted after the expiry of the respective last contractual
                or legal retention period.
              </p>
              <p className="privacyParagraph">
                The technical communication data contained in the server log files (see section 3.1)
                are deleted after 90 days at the latest.
              </p>
              <p className="privacyParagraph">
                The storage period of the data transferred to Google can be found in Google's
                privacy policy (see section 3.2).
              </p>
              <p className="privacyParagraph">
                We store and process the data generated when using "ElectricRoutes" without
                reference to individuals (see section 4.1).
              </p>
              <p className="privacyParagraph">
                If you contact us by e-mail, we will only store the message you send and your e-mail
                address for as long as is necessary to process your request and possible queries
                (see section 4.2). Your data will be deleted no later than six months after you last
                contacted us, unless we are entitled or obliged to continue storing your message
                and/or your e-mail address.
              </p>
            </AccordionDetails>
          </Accordion>

          {/* ------------------- HEADING 8 ------------------- */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel8a-content"
              id="panel8a-header"
            >
              {'8. Your Rights (Data Subject Rights)'}
            </AccordionSummary>
            <AccordionDetails>
              <p className="privacyParagraph">
                Insofar as the legal requirements according to Art. 15 et seq. GDPR, you have the
                following rights with regard to your personal data stored by us (so-called data
                subject rights):
              </p>

              <ul>
                <li>
                  You can request information from us at any time as to whether personal data and
                  which categories of personal data about you are stored by us, for which purposes
                  these are processed and which recipients or categories of recipients may receive
                  them. Furthermore, you can request the other information listed in Art. 15 GDPR
                  about your personal data from us (right to information).
                </li>
                <li>
                  In accordance with the legal requirements, you also have a right to rectification
                  (Art. 16 GDPR), a right to erasure (Art. 17 GDPR) and a right to restriction of
                  processing (blocking) (Art. 18 GDPR) of your personal data.
                </li>
                <li>
                  You further have the right, pursuant to Art. 20 GDPR, to receive from us the
                  personal data concerning you that you have provided to us in a structured,
                  commonly used and machine-readable format. You may also transfer this personal
                  data or have it transferred to other responsible parties (right to data
                  portability).
                </li>
                <li>You also have the right to revoke your granted consent at any time.</li>
                <li>
                  <b>
                    In addition, you have the right to object to data processing based on the
                    legitimate interests of the controller or a third party pursuant to Art. 6 (1)
                    sentence 1 lit. f{')'} GDPR, if the legal requirements pursuant to Art. 21 GDPR
                    are met.
                  </b>
                </li>
              </ul>

              <p className="privacyParagraph">
                To assert your data subject rights, you can contact us at any time at one of the
                addresses given in sections 1. and 2.
              </p>
              <p className="privacyParagraph">
                Furthermore, pursuant to Art. 77 (1) GDPR, you have the right to lodge a complaint
                with a supervisory authority, in particular in the member state of your residence,
                workplace or the place of the alleged infringement, if you are of the opinion that
                the processing of personal data concerning you violates the GDPR.
              </p>
            </AccordionDetails>
          </Accordion>

          {/* ------------------- HEADING 9 ------------------- */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel9a-content"
              id="panel9a-header"
              data-cy="privacy-accordion-9"
            >
              {'9. Data Security'}
            </AccordionSummary>
            <AccordionDetails>
              <p className="privacyParagraph">
                We use extensive technical and organizational precautions to protect your personal
                data stored and processed by us against misuse, accidental or intentional
                manipulation, loss, access by unauthorized persons and other data security risks.
                Our data security precautions are continuously improved in line with technological
                developments. The transmission of personal data is encrypted in accordance with the
                current state of the art.
              </p>
              <p className="privacyParagraph">
                Our employees are obligated by us to maintain confidentiality and to comply with the
                relevant data protection regulations.
              </p>
            </AccordionDetails>
          </Accordion>

          {/* ------------------- HEADING 10 ------------------- */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel10a-content"
              id="panel10a-header"
            >
              {'10. Modification of the Privacy Policy'}
            </AccordionSummary>
            <AccordionDetails>
              <p className="privacyParagraph">
                We can adapt this data protection notice to changed factual or legal circumstances
                at any time and will always publish the latest version of this data protection
                notice here. You can tell when this privacy notice was last changed by the date
                ("Last updated") at the beginning of this privacy notice.
              </p>
            </AccordionDetails>
          </Accordion>

          {/* ------------------- HEADING 11 ------------------- */}
          <Accordion>
            <AccordionSummary
              expandIcon={null}
              aria-controls="panel11a-content"
              id="panel11a-header"
              style={{ paddingRight: 0 }} // TODO: Extract style
            >
              <DataTrackingPolicyRow heading={'11. Data Tracking'} />
            </AccordionSummary>
          </Accordion>
        </PrivacyPolicyStyles.StyledDiv>
      </PrivacyPolicyStyles.PrivacyPolicyContainer>
    </>
  );
};
