/* ******************************************************  Imports  ****************************************************** */
import styled from '@emotion/styled';
import { getColor } from '../../../theme/colors/colors';
import { getVar } from '../../../theme/ui-variables/ui-variables';

/* ******************************************************  Styles  ****************************************************** */
const PeopleContainer = styled.div`
  width: 100%;
  padding: 13rem 0;
  background-color: ${getColor('background')};
`;

const Content = styled.div`
  width: 100%;
  max-width: ${getVar('maxWidth')};
  margin: 0 auto;
  color: ${getColor('adminBlack')};

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const PeopleGroupContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  &:not(:last-of-type) {
    margin-bottom: 9.5rem;
  }
`;

const DescriptionText = styled.p`
  font-size: 1.8rem;
  font-weight: 400;
  margin-bottom: 5rem;
  margin-top: 1rem;
  width: 100%;
`;

/* ******************************************************  Export  ****************************************************** */
export const AboutPeopleStyles = {
  PeopleContainer,
  Content,
  PeopleGroupContainer,
  DescriptionText,
};
