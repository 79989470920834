/* ******************************************************  Imports  ****************************************************** */
import { useTranslation } from 'react-i18next';
import { FooterNavigationStyles } from './FooterNavigation.styles';

import blueLogo from '../../../../../assets/images/logos/full-logo-blue.svg';
import { APP_ROUTES } from '../../../appRoutes';
import { useLocation } from 'react-router';
import { useEffect } from 'react';
import { LanguageSwitcher } from '../../language-switcher/LanguageSwitcher';
import { useAppAnalytics } from 'analytics';
import { LanguageModel } from '../../language-switcher/language.data';

/* ******************************************************  Component  ****************************************************** */
export const FooterNavigation = () => {
  /* ******************************************************  State  ****************************************************** */
  const { t, i18n } = useTranslation();

  const location = useLocation();

  /* ******************************************************  useEffect()  ****************************************************** */
  useEffect(() => {}, [location]);

  /* *************************************************** APP INSIGHTS **************************************************** */
  const { trackEvent } = useAppAnalytics();

  /* ******************************************************  Template  ****************************************************** */
  return (
    <FooterNavigationStyles.NavigationContainer>
      <FooterNavigationStyles.LinksContainer>
        {/* ---------------------  Mobile & Web App  --------------------- */}
        <FooterNavigationStyles.MenuContainer>
          <FooterNavigationStyles.MenuHeading>
            {t('footer.menu.mobileAndWeb')}
          </FooterNavigationStyles.MenuHeading>

          <FooterNavigationStyles.MenuLink
            to={APP_ROUTES.apps}
            onClick={() =>
              trackEvent({
                action: '[Footer] Mobile App Clicked',
                category: 'General',
              })
            }
          >
            {t('footer.menu.mobileApp')}
          </FooterNavigationStyles.MenuLink>

          <FooterNavigationStyles.MenuLink
            to={APP_ROUTES.apps + '#webPlanner'}
            onClick={() =>
              trackEvent({
                action: '[Footer] Web App Clicked',
                category: 'General',
              })
            }
          >
            {t('footer.menu.webApp')}
          </FooterNavigationStyles.MenuLink>
        </FooterNavigationStyles.MenuContainer>

        {/* ---------------------  Operator Portal  --------------------- */}
        <FooterNavigationStyles.MenuContainer>
          <FooterNavigationStyles.MenuHeading>
            {t('footer.menu.operatorPortal')}
          </FooterNavigationStyles.MenuHeading>

          <FooterNavigationStyles.MenuLink
            to={APP_ROUTES.b2b + '#operatorPortal'}
            onClick={() =>
              trackEvent({
                action: '[Footer] Operator Portal Clicked',
                category: 'General',
              })
            }
          >
            {t('footer.menu.challenge')}
          </FooterNavigationStyles.MenuLink>

          <FooterNavigationStyles.MenuLink
            to={APP_ROUTES.b2b + '#apiPortal'}
            onClick={() =>
              trackEvent({
                action: '[Footer] API Portal Clicked',
                category: 'General',
              })
            }
          >
            {t('footer.menu.api')}
          </FooterNavigationStyles.MenuLink>
        </FooterNavigationStyles.MenuContainer>

        {/* ---------------------  Legal  --------------------- */}
        <FooterNavigationStyles.MenuContainer className="legal">
          <FooterNavigationStyles.MenuLink
            to={`${APP_ROUTES.privacyPolicy}-${i18n.language === 'de' ? 'de' : 'en'}`}
            onClick={() =>
              trackEvent({
                action: '[Footer] Data Policy Clicked',
                category: 'General',
              })
            }
          >
            {t('footer.menu.dataPolicy')}
          </FooterNavigationStyles.MenuLink>

          <FooterNavigationStyles.MenuLink
            to={`${APP_ROUTES.imprint}-${i18n.language === 'de' ? 'de' : 'en'}`}
            onClick={() =>
              trackEvent({
                action: '[Footer] Imprint Clicked',
                category: 'General',
              })
            }
          >
            {t('footer.menu.imprint')}
          </FooterNavigationStyles.MenuLink>

          <FooterNavigationStyles.StyledLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.electricroutes.com/press/PressKit.zip"
          >
            {t('footer.menu.pressKit')}
          </FooterNavigationStyles.StyledLink>
        </FooterNavigationStyles.MenuContainer>

        {/* ---------------------  Language  --------------------- */}
        <FooterNavigationStyles.MenuContainer className="legal">
          <FooterNavigationStyles.LanguageContainer>
            <LanguageSwitcher
              languageChanged={(lang: LanguageModel) =>
                trackEvent({
                  action: 'Language changed',
                  name: JSON.stringify({ lang }),
                  category: 'General',
                })
              }
            />
          </FooterNavigationStyles.LanguageContainer>
        </FooterNavigationStyles.MenuContainer>
      </FooterNavigationStyles.LinksContainer>

      {/* ---------------------  Copyright  --------------------- */}
      <FooterNavigationStyles.CopyrightContainer>
        <FooterNavigationStyles.CopyrightText>
          <span>{t('footer.menu.copyright')}</span>
        </FooterNavigationStyles.CopyrightText>

        <FooterNavigationStyles.LogoImage
          src={blueLogo}
          alt="ElectricRoutes Logo"
          className={location.pathname === APP_ROUTES.apps ? '' : 'white'}
        />
      </FooterNavigationStyles.CopyrightContainer>
    </FooterNavigationStyles.NavigationContainer>
  );
};
