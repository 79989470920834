// ******************************************************  TYPE  ****************************************************** //
export type UIVar = keyof typeof uiVariables;

// ******************************************************  FUNCTIONS  ****************************************************** //
export function getVar(v: UIVar) {
  return `var(${uiVariables[v]})`;
}

// ******************************************************  VARIABLES  ****************************************************** //
const uiVariables = {
  headerHeight: '--headerHeight',
  headerLinkSpacing: '--headerLinkSpacing',
  maxWidth: '--maxWidth',
  mobileContentWidth: '--mobileContentWidth',
  footerHeight: '--footerHeight',
  footerVerticalSpacing: '--footerVerticalSpacing',
  contactFormWidth: '--contactFormWidth',
  b2bTileWidth: '--b2bTileWidth',
  b2bTileHeight: '--b2bTileHeight',
};
