/* ******************************************************  Imports  ****************************************************** */
import styled from '@emotion/styled';
import { Fade } from 'react-awesome-reveal';
import { getColor } from '../../../theme/colors/colors';
import { getVar } from '../../../theme/ui-variables/ui-variables';

/* ******************************************************  Styles  ****************************************************** */
const LandingContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 100%;
  padding: calc(${getVar('headerHeight')} + 11rem) 0 13rem 0;
  background-color: ${getColor('primary')};
  color: ${getColor('primaryContrast')};

  @media screen and (max-width: 1200px) {
    padding: calc(${getVar('headerHeight')} + 8rem) 0 13rem 0;
  }

  @media screen and (max-height: 799px) {
    min-height: 930px;
    padding-top: calc(var(--headerHeight) + 3rem);
  }
`;

const TextContainer = styled(Fade)`
  width: 100%;
  max-width: 44.5rem;
  margin-left: calc((100% - ${getVar('maxWidth')}) / 2);

  img {
    //For responsive > FullHD
    height: 3.5rem;
    //For responsive > FullHD
  }

  @media screen and (max-width: 1200px) {
    max-width: 60rem;
    position: relative;
    z-index: 2;
  }

  @media screen and (max-width: 1150px) {
    width: ${getVar('mobileContentWidth')};
    max-width: ${getVar('maxWidth')};
  }
`;

const HeroImageContainer = styled(Fade)`
  position: absolute;
  top: 24rem;
  right: 0;
  overflow: hidden;
  border-radius: 4rem 0 0 16rem;
  width: 70rem;
  height: 94.5rem;
  box-shadow: 0 0.4rem 24.4rem -1rem ${getColor('adminBlack', 0.23)};
  z-index: 998;

  @media screen and (max-width: 1200px) {
    top: 37rem;
    width: 55rem;
    height: 79.5rem;
    z-index: unset;
  }

  @media screen and (max-width: 1150px) {
    width: 100%;
    border-radius: 0;
    height: 100%;
    top: 0;
    mix-blend-mode: overlay;
  }
`;

const HeroImage = styled.img`
  overflow: hidden;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

/* ******************************************************  Export  ****************************************************** */
export const HomeLandingStyles = {
  LandingContainer,
  TextContainer,
  HeroImageContainer,
  HeroImage,
};
