/* ******************************************************  Imports  ****************************************************** */
import { PersonModel } from './person/person.model';

/* ******************************************************  Images  ****************************************************** */
// ---------- Management
// Project Manager
// import rafael from '../../../../assets/images/people/rafael.png';
// import martin from '../../../../assets/images/people/martin.png';
// import jeanMarc from '../../../../assets/images/people/jeanmarc.png';

// Consultant
// import sebastian from '../../../../assets/images/people/sebastian.png';
// import arne from '../../../../assets/images/people/arne.png';
// import johanna from '../../../../assets/images/people/johanna.png';

// ---------- Engineers
// Backend Developer
import aleksandarBE from '../../../../assets/images/people/aleksandar-be.png';
import dusan from '../../../../assets/images/people/dusan.png';
import kristina from '../../../../assets/images/people/kristina.png';
import miljan from '../../../../assets/images/people/miljan.png';
import petar from '../../../../assets/images/people/petar.png';
import stefan from '../../../../assets/images/people/stefan.png';

// Frontend Developer
import jovanaFE from '../../../../assets/images/people/jovana-fe.png';
import joakim from '../../../../assets/images/people/joakim.png';
import nenad from '../../../../assets/images/people/nenad.png';
import milijan from '../../../../assets/images/people/milijan.png';

// Mobile Developer
import alexandru from '../../../../assets/images/people/alexandru.png';
import bogdan from '../../../../assets/images/people/bogdan.png';
import cristina from '../../../../assets/images/people/cristina.png';
import norbert from '../../../../assets/images/people/norbert.png';
import roxana from '../../../../assets/images/people/roxana.png';
import vasile from '../../../../assets/images/people/vasile.png';
import veronica from '../../../../assets/images/people/veronica.png';

// DevOps
// import djordje from '../../../../assets/images/people/djordje.png';
import gojko from '../../../../assets/images/people/gojko.png';
import milos from '../../../../assets/images/people/milos.png';

// IT Ops
import ivanIT from '../../../../assets/images/people/ivan-it.png';
import jovan from '../../../../assets/images/people/jovan.png';
import milan from '../../../../assets/images/people/milan.png';
import sofija from '../../../../assets/images/people/sofija.png';

// Quality Assurance
import aleksandarQA from '../../../../assets/images/people/aleksandar-qa.png';
import jagoda from '../../../../assets/images/people/jagoda.png';
import nikola from '../../../../assets/images/people/nikola.png';
import tijanaQA from '../../../../assets/images/people/tijana-qa.png';

// SCRUM Master
import milena from '../../../../assets/images/people/milena.png';

// ---------- Creative
import tijanaUX from '../../../../assets/images/people/tijana-ux.png';
import jovanaUX from '../../../../assets/images/people/jovana-ux.png';
import ivanUX from '../../../../assets/images/people/ivan-ux.png';

// ----------Support
import marina from '../../../../assets/images/people/marina.png';
import ivona from '../../../../assets/images/people/ivona.png';
import elena from '../../../../assets/images/people/elena.png';

/* ******************************************************  Model  ****************************************************** */
interface PeopleModel {
  management: PersonModel[];
  engineers: PersonModel[];
  creative: PersonModel[];
  support: PersonModel[];
}

/* ******************************************************  Data  ****************************************************** */
export const PEOPLE: PeopleModel = {
  management: [
    // Project Manager
    // {
    //   name: 'Rafael',
    //   job: 'PM',
    //   image: rafael,
    // },
    // {
    //   name: 'Martin',
    //   job: 'PM',
    //   image: martin,
    // },
    // {
    //   name: 'Jean Marc',
    //   job: 'PM',
    //   image: jeanMarc,
    // },
    // Consultant
    // {
    //   name: 'Sebastian',
    //   job: 'CONSULTANT',
    //   image: sebastian,
    // },
    // {
    //   name: 'Arne',
    //   job: 'CONSULTANT',
    //   image: arne,
    // },
    // {
    //   name: 'Johanna',
    //   job: 'CONSULTANT',
    //   image: false,
    // },
  ],
  engineers: [
    // Backend Developer
    {
      name: 'Aleksandar',
      job: 'BE',
      image: aleksandarBE,
    },
    {
      name: 'Dušan',
      job: 'BE',
      image: dusan,
    },
    {
      name: 'Kristina',
      job: 'BE',
      image: kristina,
    },
    {
      name: 'Miljan',
      job: 'BE',
      image: miljan,
    },
    {
      name: 'Petar',
      job: 'BE',
      image: petar,
    },
    {
      name: 'Stefan',
      job: 'BE',
      image: stefan,
    },
    // Frontend Developer
    {
      name: 'Jovana',
      job: 'FE',
      image: jovanaFE,
    },
    {
      name: 'Joakim',
      job: 'FE',
      image: joakim,
    },
    {
      name: 'Nenad',
      job: 'FE',
      image: nenad,
    },
    {
      name: 'Milijan',
      job: 'FE',
      image: milijan,
    },
    // Mobile Developer
    {
      name: 'Alexandru',
      job: 'MOBILE',
      image: alexandru,
    },
    {
      name: 'Bogdan',
      job: 'MOBILE',
      image: bogdan,
    },
    {
      name: 'Cristina',
      job: 'MOBILE',
      image: cristina,
    },
    {
      name: 'Norbert',
      job: 'MOBILE',
      image: norbert,
    },
    {
      name: 'Roxana',
      job: 'MOBILE',
      image: roxana,
    },
    {
      name: 'Vasile',
      job: 'MOBILE',
      image: vasile,
    },
    {
      name: 'Veronica',
      job: 'MOBILE',
      image: veronica,
    },
    // DevOps
    {
      name: 'Đorđe',
      job: 'DEVOPS',
      image: false,
    },
    {
      name: 'Gojko',
      job: 'DEVOPS',
      image: gojko,
    },
    {
      name: 'Miloš',
      job: 'DEVOPS',
      image: milos,
    },
    // IT Ops
    {
      name: 'Ivan',
      job: 'ITOPS',
      image: ivanIT,
    },
    {
      name: 'Jovan',
      job: 'ITOPS',
      image: jovan,
    },
    {
      name: 'Milan',
      job: 'ITOPS',
      image: milan,
    },
    {
      name: 'Sofija',
      job: 'ITOPS',
      image: sofija,
    },
    // Quality Assurance
    {
      name: 'Aleksandar',
      job: 'QA',
      image: aleksandarQA,
    },
    {
      name: 'Jagoda',
      job: 'QA',
      image: jagoda,
    },
    {
      name: 'Nikola',
      job: 'QA',
      image: nikola,
    },
    {
      name: 'Tijana',
      job: 'QA',
      image: tijanaQA,
    },
    //SCRUM Master
    {
      name: 'Milena',
      job: 'SCRUM',
      image: milena,
    },
  ],
  creative: [
    {
      name: 'Tijana',
      job: 'UX',
      image: tijanaUX,
    },
    {
      name: 'Jovana',
      job: 'UX',
      image: jovanaUX,
    },
    {
      name: 'Ivan',
      job: 'UX',
      image: ivanUX,
    },
  ],
  support: [
    {
      name: 'Marina',
      job: 'SUPPORT',
      image: marina,
    },
    {
      name: 'Ivona',
      job: 'SUPPORT',
      image: ivona,
    },
    {
      name: 'Elena',
      job: 'SUPPORT',
      image: elena,
    },
  ],
};
