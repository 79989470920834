/* ******************************************************  Imports  ****************************************************** */
import styled from '@emotion/styled';

/* ******************************************************  Styles  ****************************************************** */
const ButtonsContainer = styled.div`
  margin-top: 4rem;
  display: flex;

  div {
    transform-origin: center;

    &:not(:last-of-type) {
      margin-right: 1.5rem;
    }
  }

  a,
  img {
    display: inline-block;
    //For responsive > FullHD
    height: 4rem;
    //For responsive > FullHD
  }
`;

/* ******************************************************  Export  ****************************************************** */
export const StoreButtonsStyles = {
  ButtonsContainer,
};
