import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LANGUAGE_RESOURCES } from './core/shared/components/language-switcher/language.data';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: LANGUAGE_RESOURCES,
    fallbackLng: 'en', // use en if detected lng is not available
    keySeparator: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
