/* ******************************************************  Imports  ****************************************************** */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { FooterStyles } from './Footer.styles';

import backgroundLogo from '../../../../assets/images/logos/footer-logo-background.svg';
import { ContactForm } from './contact-form/ContactForm';
import { APP_ROUTES } from '../../appRoutes';
import { FooterNavigation } from './footer-navigation/FooterNavigation';
import { Fade } from 'react-awesome-reveal';

/* ******************************************************  Component  ****************************************************** */
export const Footer = () => {
  /* ******************************************************  State  ****************************************************** */
  const [footerColorClass, setFooterColorClass] = useState('');

  const { t } = useTranslation();

  const location = useLocation();

  /* ******************************************************  useEffect()  ****************************************************** */
  useEffect(() => {
    switch (location.pathname) {
      case APP_ROUTES.apps:
        setFooterColorClass('white');
        break;
      case APP_ROUTES.b2b:
        setFooterColorClass('black');
        break;
      default:
        setFooterColorClass('');
    }
  }, [location]);

  /* ******************************************************  Template  ****************************************************** */
  return (
    <FooterStyles.FooterContainer className={footerColorClass} id="contactUs">
      <FooterStyles.BackgroundLogoImage
        src={backgroundLogo}
        alt="ElectricRoutes logo background"
        className={footerColorClass === 'white' ? 'no-opacity' : ''}
      />

      <Fade direction="down" duration={1000} triggerOnce fraction={0.7}>
        <FooterStyles.Heading className="mainText">
          {t('footer.heading')}
        </FooterStyles.Heading>
      </Fade>

      <FooterStyles.ContentContainer>
        <FooterStyles.FormContainer>
          <ContactForm />
        </FooterStyles.FormContainer>
        <FooterStyles.LinksContainer>
          <FooterNavigation />
        </FooterStyles.LinksContainer>
      </FooterStyles.ContentContainer>
    </FooterStyles.FooterContainer>
  );
};
