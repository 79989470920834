/* ******************************************************  Imports  ****************************************************** */
import { createTheme } from '@material-ui/core/styles';
import createPalette from '@material-ui/core/styles/createPalette';

import { getColor, getDarkerColor, getLighterColor } from '../colors/colors';
import { CustomFormControl } from './overrides/mui-form-control';
import { CustomButton } from './overrides/mui-button';
import { CustomPopover } from './overrides/mui-popover';
import { CustomDrawer } from './overrides/mui-drawer';
import {
  CustomAccordion,
  CustomAccordionDetails,
  CustomAccordionSummary,
} from './overrides/mui-accordion';
import { CustomSwitch } from './overrides/mui-switch';

/* ******************************************************  Theme  ****************************************************** */
export const muiTheme = createTheme({
  palette: createPalette({
    type: 'light',
    primary: {
      light: getLighterColor('primary', 20),
      main: getColor('primary'),
      dark: getDarkerColor('primary', 5),
      contrastText: getColor('primaryContrast'),
    },

    secondary: {
      light: getLighterColor('accent', 10),
      main: getColor('accent'),
      dark: getDarkerColor('accent', 5),
      contrastText: getColor('accentContrast'),
    },

    error: {
      light: getLighterColor('error', 20),
      main: getColor('error'),
      dark: getDarkerColor('error', 5),
      contrastText: getColor('errorContrast'),
    },
  }),
  typography: {
    fontFamily: ['OverPass', 'Roboto', 'sans-serif'].join(','),
    htmlFontSize: 10,
  },
  spacing: 5,
  props: {
    MuiButton: {
      size: 'small',
    },
    MuiFilledInput: {
      margin: 'dense',
    },
    MuiFormControl: {
      margin: 'dense',
    },
    MuiFormHelperText: {
      margin: 'dense',
    },
    MuiIconButton: {
      size: 'small',
    },
    MuiInputBase: {
      margin: 'dense',
    },
    MuiInputLabel: {
      margin: 'dense',
    },
    MuiListItem: {
      dense: true,
    },
    MuiOutlinedInput: {
      margin: 'dense',
    },
    MuiFab: {
      size: 'small',
    },
    MuiTable: {
      size: 'small',
    },
    MuiTextField: {
      margin: 'dense',
    },
    MuiToolbar: {
      variant: 'dense',
    },
    MuiTypography: {
      variant: 'body1',
    },
    MuiSelect: {
      variant: 'standard',
    },
    MuiMenuItem: {
      dense: true,
    },
    MuiMenu: {
      variant: 'menu',
    },
  },
  overrides: {
    MuiFormControl: CustomFormControl,
    MuiButton: CustomButton,
    MuiPopover: CustomPopover,
    MuiDrawer: CustomDrawer,
    MuiAccordion: CustomAccordion,
    MuiAccordionDetails: CustomAccordionDetails,
    MuiAccordionSummary: CustomAccordionSummary,
    MuiSwitch: CustomSwitch,
  },
});
