/* ******************************************************  Imports  ****************************************************** */
import { useTranslation } from 'react-i18next';
import { useAppAnalytics } from 'analytics';
import { AppsInCarStyles } from './AppsInCar.styles';
import carImage from '../../../../assets/images/apps/apps-5.png';
import { GetCustomTranslation } from '../../../shared/components/GetCustomTranslation';
import { AppleStoreButtonSection } from '../store-buttons/AppleStoreButtonSection';

/* ******************************************************  Component  ****************************************************** */
export const AppsInCar = () => {
  /* ******************************************************  Component  ****************************************************** */
  const { t } = useTranslation();

  const { trackEvent } = useAppAnalytics();

  const handleButtonClicked = (button: string) => {
    if (button === 'apple') {
      trackEvent({
        action: '[Mobile & Web App - Apps In Car Section] App Store Button Clicked',
        category: 'Mobile & Web App',
      });
    } else {
      trackEvent({
        action: '[Mobile & Web App - Apps In Car Section] Google Play Button Clicked',
        category: 'Mobile & Web App',
      });
    }
  };

  /* ******************************************************  Template  ****************************************************** */
  return (
    <AppsInCarStyles.InCarContainer>
      <AppsInCarStyles.TextWrapper>
        <h2
          className="mainText black"
          dangerouslySetInnerHTML={GetCustomTranslation('apps.inCar.main')}
        ></h2>
        <p className="subText">{t('apps.inCar.sub')}</p>

        {/* Once Android Auto is supported, replace AppleStoreButtonSection with StoreButtons used in the rest of the sections */}
        <AppleStoreButtonSection buttonClicked={handleButtonClicked} />
      </AppsInCarStyles.TextWrapper>

      <AppsInCarStyles.Image
        src={carImage}
        alt="ElectricRoutes application screen in car display"
      />
    </AppsInCarStyles.InCarContainer>
  );
};
