/* ******************************************************  Imports  ****************************************************** */
import styled from '@emotion/styled';

import { NavLink } from 'react-router-dom';

import { getColor } from '../../../../theme/colors/colors';
import { getVar } from '../../../../theme/ui-variables/ui-variables';

/* ******************************************************  Styles  ****************************************************** */
const LanguageAbsoluteContainer = styled.div`
  position: absolute;
  top: 3rem;
  right: calc((100% - ${getVar('maxWidth')}) / 2);
  z-index: 1000;
  color: ${getColor('primaryContrast')};

  &.blue {
    color: ${getColor('primary')};
  }

  @media (min-width: 1251px) and (max-height: 640px) {
    top: 0.5rem;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  height: ${getVar('headerHeight')};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  pointer-events: none;

  & a,
  & button {
    pointer-events: auto;
  }

  &.scrolled {
    position: fixed;
    background: ${getColor('primary')};
    top: -10rem;

    &.show {
      transition: 0.2s;
      top: 0;
    }

    &.white {
      background: ${getColor('background')};
    }

    &.black {
      background: ${getColor('adminBlack')};
    }

    height: 10rem;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: ${getVar('maxWidth')};
  margin: 0 auto;
`;

const LogoImage = styled.img`
  //For responsive > FullHD
  height: 5.2rem;
  //For responsive > FullHD

  &.white {
    filter: brightness(0) invert(1);
  }
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderLink = styled(NavLink)`
  font-size: 1.6rem;
  font-weight: 700;
  margin-right: calc(${getVar('headerLinkSpacing')} * 1.25);
  color: ${getColor('primaryContrast')};
  padding: 0 ${getVar('headerLinkSpacing')};

  &.blueText {
    color: ${getColor('primary')};

    & > span::after {
      background-color: ${getColor('primary')};
    }
  }

  &:hover > span::after,
  &.active > span::after {
    opacity: 1;
  }
`;

const HeaderLinkButton = styled.button`
  font-size: 1.6rem;
  font-weight: 700;
  color: ${getColor('primaryContrast')};
  margin-right: 0;
  box-shadow: inset 0 0 0 0.1rem ${getColor('primaryContrast', 0.3)};
  border-radius: ${getVar('headerLinkSpacing')};
  padding: ${getVar('headerLinkSpacing')}
    calc(${getVar('headerLinkSpacing')} * 2);
  cursor: pointer;
  background: transparent;

  &:hover {
    box-shadow: inset 0 0 0 0.2rem ${getColor('primaryContrast', 1)};
  }

  &.blueText {
    color: ${getColor('primary')};
    box-shadow: inset 0 0 0 0.1rem ${getColor('primary', 1)};

    &:hover {
      box-shadow: inset 0 0 0 0.2rem ${getColor('primary', 1)};
    }
  }
`;

const HeaderLinkText = styled.span`
  position: relative;
  padding: ${getVar('headerLinkSpacing')} 0;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 4.2rem;
    height: 0.2rem;
    background-color: ${getColor('primaryContrast')};
    border-radius: 0 0 0.4rem 0.4rem;
    opacity: 0;
  }
`;

/* ******************************************************  Export  ****************************************************** */
export const DesktopHeaderStyles = {
  LanguageAbsoluteContainer,
  HeaderContainer,
  HeaderContent,
  LogoImage,
  LinkContainer,
  HeaderLink,
  HeaderLinkButton,
  HeaderLinkText,
};
