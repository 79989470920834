import styled from '@emotion/styled';
import Snackbar from '@material-ui/core/Snackbar';
import { getColor } from '../../../theme/colors/colors';
import { Button } from '@material-ui/core';

const ErrorSnackbar = styled(Snackbar)`
  background: ${getColor('error')};
  border-radius: 0.8rem;
  max-width: 50rem;
`;

const StyledErrorSnackbarContent = styled.div`
  display: flex;
  align-items: center;
`;

const StyledMessage = styled.div`
  color: ${getColor('errorContrast')};
  padding: 1.5rem 3rem;
  font-weight: 400;
  font-size: 1.4rem;
`;

const StyledButton = styled(Button)`
  height: 6.4rem;
  color: ${getColor('errorContrast')} !important;
`;

export const StyledErrorSnackbar = {
  ErrorSnackbar,
  StyledErrorSnackbarContent,
  StyledMessage,
  StyledButton,
};
