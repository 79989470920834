/* ******************************************************  Imports  ****************************************************** */
import { AboutLanding } from './landing/AboutLanding';
import { AboutPeople } from './people/AboutPeople';

/* ******************************************************  Component  ****************************************************** */
export const AboutPage = () => {
  /* ******************************************************  Template  ****************************************************** */
  return (
    <>
      <AboutLanding />
      <AboutPeople />
    </>
  );
};
