/* ******************************************************  Imports  ****************************************************** */
import styled from '@emotion/styled';
import { Fade } from 'react-awesome-reveal';
import { getColor } from '../../../theme/colors/colors';
import { getVar } from '../../../theme/ui-variables/ui-variables';

/* ******************************************************  Styles  ****************************************************** */
const LandingContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 100%;
  padding: calc(${getVar('headerHeight')} + 8rem) 0 19rem 0;
  background-color: ${getColor('background')};
  color: ${getColor('textApps')};
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 1150px) {
    justify-content: flex-start;
  }
`;

const TextContainerFade = styled(Fade)`
  width: 100%;
  max-width: 49rem;
  margin-right: calc((100% - ${getVar('maxWidth')}) / 2);

  @media screen and (max-width: 1150px) {
    width: ${getVar('mobileContentWidth')};
    max-width: ${getVar('maxWidth')};
    margin-left: calc((100% - ${getVar('maxWidth')}) / 2);
    margin-right: unset;
    position: relative;
    z-index: 2;
  }
`;

const TextContainer = styled.div`
  width: 100%;
  max-width: 49rem;

  @media screen and (max-width: 1150px) {
    width: ${getVar('mobileContentWidth')};
    max-width: ${getVar('maxWidth')};
  }
`;

const PhoneImage = styled.img`
  position: absolute;
  left: calc((100% - ${getVar('maxWidth')}) / 2);
  top: calc(${getVar('headerHeight')} - 8rem);
  z-index: 998;

  //For responsive > FullHD
  height: 98.3rem;
  //For responsive > FullHD

  @media screen and (max-width: 1150px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    opacity: 0.3;
    height: 100%;
  }

  @media screen and (max-width: 700px) {
    height: unset;
    width: 100%;
    bottom: 0;
    top: unset;
    transform: translateX(-50%);
  }
`;

/* ******************************************************  Export  ****************************************************** */
export const AppsLandingStyles = {
  LandingContainer,
  TextContainerFade,
  TextContainer,
  PhoneImage,
};
