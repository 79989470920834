import { DesktopHeader } from './desktop-header/DesktopHeader';
import { MobileHeader } from './mobile-header/MobileHeader';
import { useAppAnalytics } from 'analytics';
import { useEffect } from 'react';
import { useResize } from '../../hooks/useResize';

export const Header = () => {
  /* *************************************************** APP INSIGHTS **************************************************** */
  const { trackEvent } = useAppAnalytics();

  const handleContactUsClick = () => {
    trackEvent({ action: '[Header] Contact Us Clicked', category: 'General' });
  };

  const handleLinkClick = (linkText: string) => {
    switch (linkText) {
      case 'header.home':
        trackEvent({ action: '[Header] Home Clicked', category: 'General' });
        break;
      case 'header.apps':
        trackEvent({ action: '[Header] Mobile & Web App Clicked', category: 'General' });
        break;
      case 'header.b2b':
        trackEvent({ action: '[Header] B2B Offer Clicked', category: 'General' });
        break;
      case 'header.about':
        trackEvent({ action: '[Header] About Us Clicked', category: 'General' });
    }
  };

  /* ******************************************************  useResize()  ****************************************************** */
  const windowSize = useResize();

  useEffect(() => {}, [windowSize]);

  return (
    <>
      {windowSize.width > 1250 ? (
        <DesktopHeader contactUsClicked={handleContactUsClick} linkClicked={handleLinkClick} />
      ) : (
        <MobileHeader contactUsClicked={handleContactUsClick} linkClicked={handleLinkClick} />
      )}
    </>
  );
};
