/* ******************************************************  Imports  ****************************************************** */
import styled from '@emotion/styled';
import { Fade } from 'react-awesome-reveal';
import { getColor } from '../../../theme/colors/colors';
import { getVar } from '../../../theme/ui-variables/ui-variables';

/* ******************************************************  Styles  ****************************************************** */
const ApisContainer = styled.div`
  width: 100%;
  min-height: 100%;
  padding: 40rem 0 23rem 0;
  background-color: ${getColor('adminBlack')};
  color: ${getColor('primaryContrast')};

  @media screen and (max-width: 1150px) {
    padding: 20rem 0;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: ${getVar('maxWidth')};
  height: 100%;
  margin-inline: auto;
`;

const LogoImage = styled.img`
  display: block;
  margin: 0 auto;
  width: 35.1rem;
  height: 5.3rem;
`;

const TextContainer = styled.div`
  margin: 12.5rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h2,
  p {
    max-width: 58rem;
  }

  p {
    color: ${getColor('textGrey')};
    margin-top: 1.5rem;
    margin-bottom: 0;
  }
`;

const TilesContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-inline: auto;
  max-width: ${getVar('maxWidth')};
`;

const Tile = styled(Fade)`
  width: ${getVar('b2bTileWidth')};
  min-height: ${getVar('b2bTileHeight')};
  margin-bottom: calc(${getVar('maxWidth')} - (2 * ${getVar('b2bTileWidth')}));
  border-radius: 2.4rem;
  padding: 5.5rem 4.5rem;
  background-color: ${getColor('blackContrast')};

  &:nth-of-type(even) {
    margin-left: calc(${getVar('maxWidth')} - (2 * ${getVar('b2bTileWidth')}));
  }

  &.hide {
    display: none;
  }

  img {
    width: 12rem;
    height: 12rem;
    display: block;
    margin: 0 auto;
  }

  h4 {
    text-align: center;
    margin-top: 6rem;
    margin-bottom: 3rem;
  }

  p {
    font-size: 1.8rem;
    text-align: center;
    font-weight: 300;
  }

  @media screen and (max-width: 1200px) {
    max-width: calc(50% - 2.5rem);
    margin-bottom: 5rem;

    &:nth-of-type(even) {
      margin-left: 5rem;
    }
  }

  @media screen and (max-width: 1023px) {
    max-width: 100%;

    &:nth-of-type(even) {
      margin-left: 0;
    }
  }
`;

const ButtonsContainer = styled.div`
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  & > button.MuiButton-root.erButton {
    min-width: 42rem;
    margin-right: 0;

    &.noLink {
      margin-bottom: 3.5rem;
    }
  }
`;

/* ******************************************************  Export  ****************************************************** */
export const B2BApisStyles = {
  ApisContainer,
  ContentContainer,
  LogoImage,
  TextContainer,
  TilesContainer,
  Tile,
  ButtonsContainer,
};
