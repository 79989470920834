import { Subject, Observable } from 'rxjs';

const alert = new Subject();

const showError = (errorTranslation: string): void => {
  alert.next(errorTranslation);
};

export const errorService: {
  error: Observable<string>;
  showError(errorTranslation: string): void;
} = {
  error: alert.asObservable() as Observable<string>,
  showError,
};
