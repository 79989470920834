import { createInstance } from '@datapunt/matomo-tracker-react';
import { environment } from '../environment';

// Only track events when built/deployed, do not track for dev builds in localhost
const isProductionBuild = process.env.NODE_ENV === 'production';

export const matomoInstance = createInstance({
  urlBase: environment.matomoUrlBase,
  siteId: environment.matomoSiteId,
  disabled: !isProductionBuild,
  configurations: {
    disableCookies: true,
  },
});
