import { ApiListItemModel } from './api-list-item.model';

import chargePlannerIcon from '../../../../../assets/images/home/api-icons/api-charge-planner.svg';
import directionsIcon from '../../../../../assets/images/home/api-icons/api-directions.svg';
import distancePlannerIcon from '../../../../../assets/images/home/api-icons/api-distance.svg';
//TODO: Unfinished Prod
import chargingStationIcon from '../../../../../assets/images/home/api-icons/api-charging-station.svg';
// import routeIcon from '../../../../../assets/images/home/api-icons/api-route.svg';
import elevationIcon from '../../../../../assets/images/home/api-icons/api-elevation.svg';
import geoCodingIcon from '../../../../../assets/images/home/api-icons/api-geo-coding.svg';
import tilesIcon from '../../../../../assets/images/home/api-icons/api-tile-service.svg';

export const API_LIST_ITEMS: ApiListItemModel[] = [
  {
    icon: chargePlannerIcon,
    translationString: 'home.operators.api.chargePlanner',
    link: 'XXX',
  },
  {
    icon: directionsIcon,
    translationString: 'home.operators.api.directions',
    link: 'XXX',
  },
  {
    icon: distancePlannerIcon,
    translationString: 'home.operators.api.distance',
    link: 'XXX',
  },
  {
    icon: chargingStationIcon,
    translationString: 'home.operators.api.chargingStation',
    link: 'XXX',
  },
  //TODO: Unfinished Prod
  // {
  //   icon: routeIcon,
  //   translationString: 'home.operators.api.route',
  //   link: 'XXX',
  // },
  {
    icon: elevationIcon,
    translationString: 'home.operators.api.elevation',
    link: 'XXX',
  },
  {
    icon: geoCodingIcon,
    translationString: 'home.operators.api.geoCoding',
    link: 'XXX',
  },
  {
    icon: tilesIcon,
    translationString: 'home.operators.api.tiles',
    link: 'XXX',
  },
];
