/* ******************************************************  Imports  ****************************************************** */
import { ButtonClassKey } from '@material-ui/core';
import { StyleRules } from '@material-ui/core';
import { getColor } from '../../colors/colors';

/* ******************************************************  Override  ****************************************************** */
export const CustomButton: Partial<StyleRules<ButtonClassKey, {}>> | undefined =
  {
    /* ******************************************************  root  ****************************************************** */
    root: {
      '&.erButton': {
        fontSize: '1.6rem',
        fontWeight: 700,
        padding: 0,
        height: 'unset',
        borderRadius: '2.8rem',
        // eslint-disable-next-line no-useless-computed-key
        ['@media (max-width: 499px)']: {
          width: '100% !important',
          marginRight: '0 !important',
          marginLeft: '0 !important',
        },

        '& a': {
          display: 'flex',
          alignItems: 'center',
          height: '6.5rem',
          padding: '0 3.5rem',
          color: 'inherit !important',
        },

        '& img': {
          marginLeft: '1rem',
        },

        '&.noLink': {
          display: 'flex',
          alignItems: 'center',
          height: '6.5rem',
          padding: '0 3.5rem',
        },

        '&:disabled.Mui-disabled': {
          opacity: '0.1',
          pointerEvents: 'none',
        },
      },
    },

    /* ******************************************************  outlined  ****************************************************** */
    outlined: {
      color: `${getColor('primaryContrast')}`,
      border: `0.1rem solid ${getColor('primaryContrast', 0.1)}`,
      transition: '0.5s',
      textTransform: 'none',

      '&:disabled.Mui-disabled': {
        color: `${getColor('primaryContrast')}`,
      },

      '&:hover': {
        border: `0.1rem solid ${getColor('primaryContrast', 1)}`,
      },
    },

    /* ******************************************************  outlinedPrimary  ****************************************************** */
    outlinedPrimary: {
      border: `0.1rem solid ${getColor('primary', 0.1)}`,

      '&:hover': {
        border: `0.1rem solid ${getColor('primary', 1)}`,
      },
    },

    /* ******************************************************  contained  ****************************************************** */
    contained: {
      textTransform: 'uppercase',
      boxShadow: `0 1.8rem 4rem -1.2rem ${getColor(
        'primaryContrast',
        0.42
      )} !important`,
      color: `${getColor('primary')}`,
      backgroundColor: `${getColor('primaryContrast')}`,
      marginRight: '1.5rem',
      marginBottom: '2rem',

      '&:disabled.Mui-disabled': {
        color: `${getColor('primary')}`,
        backgroundColor: `${getColor('primaryContrast')}`,
      },

      '&.orange': {
        color: `${getColor('primaryContrast')}`,
        backgroundColor: `${getColor('apiOrange')}`,
        boxShadow: `0 1.8rem 4rem -1.2rem ${getColor(
          'apiOrange',
          0.42
        )} !important`,
      },

      '&.green': {
        color: `${getColor('primaryContrast')}`,
        backgroundColor: `${getColor('operatorGreen')}`,
        boxShadow: `0 1.8rem 4rem -1.2rem ${getColor(
          'operatorGreen',
          0.42
        )} !important`,
      },

      '&.white': {
        color: `${getColor('adminBlack')}`,
        backgroundColor: `${getColor('primaryContrast')}`,
        boxShadow: `0 1.8rem 4rem -1.2rem ${getColor(
          'primaryContrast',
          0.42
        )} !important`,

        '&:disabled.Mui-disabled': {
          color: `${getColor('adminBlack')}`,
          backgroundColor: `${getColor('primaryContrast')}`,
        },
      },

      '&.black': {
        color: `${getColor('primaryContrast')}`,
        backgroundColor: `${getColor('adminBlack')}`,
        boxShadow: `0 1.8rem 4rem -1.2rem ${getColor(
          'adminBlack',
          0.42
        )} !important`,

        '&:disabled.Mui-disabled': {
          color: `${getColor('primaryContrast')}`,
          backgroundColor: `${getColor('adminBlack')}`,
        },
      },
    },

    /* ******************************************************  containedPrimary  ****************************************************** */
    containedPrimary: {
      boxShadow: `0 1.8rem 4rem -1.2rem ${getColor(
        'primary',
        0.42
      )} !important`,
    },
  };
