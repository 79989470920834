/* ******************************************************  Imports  ****************************************************** */
import { useEffect } from 'react';
import { useAppAnalytics } from 'analytics';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';

import { HomeLandingStyles } from './HomeLanding.styles';

import heroImage from '../../../../assets/images/home/home-landing-hero.jpg';
import mouseIcon from '../../../../assets/images/home/home-landing-scroll.svg';
import { useResize } from '../../../shared/hooks/useResize';
import { Fade } from 'react-awesome-reveal';

/* ******************************************************  Component  ****************************************************** */
export const HomeLanding = () => {
  /* ******************************************************  State  ****************************************************** */
  const { t } = useTranslation();

  /* ******************************************************  useResize()  ****************************************************** */
  const windowSize = useResize();

  useEffect(() => {}, [windowSize]);

  /* *************************************************** APP INSIGHTS **************************************************** */
  const { trackEvent } = useAppAnalytics();

  /* ******************************************************  Template  ****************************************************** */
  return (
    <HomeLandingStyles.LandingContainer>
      <HomeLandingStyles.TextContainer
        direction="left"
        triggerOnce={true}
        duration={1000}
        delay={1000}
      >
        <p className="topText">{t('home.landing.top')}</p>
        <h1 className="mainText">{t('home.landing.main')}</h1>
        <p className="subText">{t('home.landing.sub')}</p>

        <Fade direction="down" delay={2000} duration={1000} triggerOnce={true}>
          <Button
            className="erButton noLink"
            variant="contained"
            onClick={() => {
              window.scrollTo(0, 1300);
              trackEvent({
                action: '[Home - Landing Section] Check Out Our Solutions Clicked',
                category: 'Home',
              });
            }}
          >
            {t('home.landing.button')}
            <img src={mouseIcon} alt="Scroll icon" />
          </Button>
        </Fade>
      </HomeLandingStyles.TextContainer>

      <HomeLandingStyles.HeroImageContainer
        direction={windowSize.width > 1150 ? 'right' : undefined}
        duration={1000}
        triggerOnce={true}
      >
        <HomeLandingStyles.HeroImage src={heroImage} alt="Woman charging electric car" />
      </HomeLandingStyles.HeroImageContainer>
    </HomeLandingStyles.LandingContainer>
  );
};
