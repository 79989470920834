/* ******************************************************  Imports  ****************************************************** */
import styled from '@emotion/styled';
import { getColor } from '../../../theme/colors/colors';
import { getVar } from '../../../theme/ui-variables/ui-variables';

/* ******************************************************  Styles  ****************************************************** */
const LandingContainer = styled.div`
  width: 100%;
  background-color: ${getColor('primary')};
  padding: calc(${getVar('headerHeight')} + 10rem) 0 ${getVar('headerHeight')} 0;
`;

const Heading = styled.h2`
  color: ${getColor('primaryContrast')};
  width: 100%;
  max-width: ${getVar('maxWidth')};
  margin: 0 auto;
`;

const Updated = styled.p`
  color: ${getColor('primaryContrast')};
  width: 100%;
  max-width: ${getVar('maxWidth')};
  margin: 3rem auto 0 auto;
  font-size: 1.8rem;
`;

const PrivacyPolicyContainer = styled.div`
  width: 100%;
  max-width: ${getVar('maxWidth')};
  margin: 0 auto;
  padding: 15rem 0;

  & .noAccordion {
    margin-bottom: 3rem;
    padding: 0 1rem;

    @media screen and (max-width: 400px) {
      padding: 0 2rem;
    }
  }

  & .privacyParagraph {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2rem;
    padding-bottom: 3rem;

    &.quote {
      padding: 0 3rem 3rem 3rem;
      font-style: italic;

      @media screen and (max-width: 400px) {
        padding: 0 1rem;
      }

      &:last-child {
        padding: 0 3rem 3rem 3rem;

        @media screen and (max-width: 400px) {
          padding: 0 1rem 3rem 1rem;
        }
      }
    }

    &:last-child {
      padding: 0;

      @media screen and (max-width: 400px) {
        padding: 0;
      }
    }

    & span {
      display: block;
    }
  }

  & h5 {
    line-height: 2rem;
    padding: 2rem 0 3rem 0;
    font-size: 1.6rem;
    font-weight: 800;

    @media screen and (max-width: 400px) {
      padding: 2rem 1rem 3rem 1rem;
    }
  }

  & ul {
    list-style-type: disc;
    padding: 0 3rem 3rem 6rem;
    font-size: 1.6rem;
    margin-top: -1rem;

    @media screen and (max-width: 400px) {
      padding: 0 1rem 3rem 2rem;
    }

    & li {
      margin-top: 1rem;
    }
  }
`;

const StyledDiv = styled.div``;

const StyledLink = styled.a`
  &[href] {
    color: ${getColor('primary')};
    text-decoration: underline;
    cursor: pointer;
  }
`;

/* ******************************************************  Export  ****************************************************** */
export const PrivacyPolicyStyles = {
  LandingContainer,
  Heading,
  Updated,
  PrivacyPolicyContainer,
  StyledDiv,
  StyledLink,
};
