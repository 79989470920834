/* ******************************************************  Imports  ****************************************************** */
import { useTranslation } from 'react-i18next';

import { StoreButtons } from '../store-buttons/StoreButtons';

import { AppsChargingStyles } from './AppsCharging.styles';
import phoneImage from '../../../../assets/images/apps/apps-3.png';
import { useAppAnalytics } from 'analytics';
import { Fade } from 'react-awesome-reveal';

/* ******************************************************  Component  ****************************************************** */
export const AppsCharging = () => {
  /* ******************************************************  Component  ****************************************************** */
  const { t } = useTranslation();

  /* *************************************************** APP INSIGHTS **************************************************** */
  const { trackEvent } = useAppAnalytics();

  const handleButtonClicked = (button: string) => {
    if (button === 'apple') {
      trackEvent({
        action: '[Mobile & Web App - Charging Stop Section] App Store Button Clicked',
        category: 'Mobile & Web App',
      });
    } else {
      trackEvent({
        action: '[Mobile & Web App - Charging Stop Section] Google Play Button Clicked',
        category: 'Mobile & Web App',
      });
    }
  };

  /* ******************************************************  Template  ****************************************************** */
  return (
    <AppsChargingStyles.ChargingContainer>
      <AppsChargingStyles.ChargingContent>
        <Fade direction="left" duration={1500} fraction={0.79} triggerOnce={true}>
          <AppsChargingStyles.TextWrapper>
            <h2 className="mainText blue">{t('apps.charging.main')}</h2>
            <p className="subText">{t('apps.charging.sub')}</p>

            <StoreButtons buttonClicked={handleButtonClicked} />
          </AppsChargingStyles.TextWrapper>
        </Fade>

        <Fade direction="right" duration={1500} fraction={0.79} triggerOnce={true}>
          <AppsChargingStyles.PhoneImageContainer>
            <AppsChargingStyles.PhoneImage
              src={phoneImage}
              alt="3D smartphone model with ElectricRoutes application screen"
            />
          </AppsChargingStyles.PhoneImageContainer>
        </Fade>
      </AppsChargingStyles.ChargingContent>
    </AppsChargingStyles.ChargingContainer>
  );
};
