/* ******************************************************  Imports  ****************************************************** */
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';

import { B2BLandingStyles } from './B2BLanding.styles';

import heroImage from '../../../../assets/images/b2b/b2b-landing-hero.png';
import mouseIcon from '../../../../assets/images/home/home-landing-scroll.svg';
import { useResize } from '../../../shared/hooks/useResize';
import { useEffect } from 'react';
import { useAppAnalytics } from 'analytics';
import { Fade } from 'react-awesome-reveal';

/* ******************************************************  Component  ****************************************************** */
export const B2BLanding = () => {
  /* ******************************************************  State  ****************************************************** */
  const { t } = useTranslation();

  /* ******************************************************  useResize()  ****************************************************** */
  const windowSize = useResize();

  useEffect(() => {}, [windowSize]);

  /* *************************************************** APP INSIGHTS **************************************************** */
  const { trackEvent } = useAppAnalytics();

  /* ******************************************************  Template  ****************************************************** */
  return (
    <B2BLandingStyles.LandingContainer>
      <Fade direction="left" triggerOnce={true} duration={1000} delay={1000}>
        <B2BLandingStyles.TextContainer>
          <p className="topText">{t('b2b.landing.top')}</p>
          <h1 className="mainText">{t('b2b.landing.main')}</h1>
          {/* <p className='subText'>{t('b2b.landing.sub')}</p> */}

          <Fade direction="down" delay={2000} duration={1000} triggerOnce={true}>
            <Button
              className="erButton noLink white"
              variant="contained"
              onClick={() => {
                window.scrollTo(0, windowSize.height + 150);
                trackEvent({
                  action: '[B2B Offer - Landing Section] Check Out Our Solutions Clicked',
                  category: 'B2B',
                });
              }}
            >
              {t('b2b.landing.button')}
              <img src={mouseIcon} alt="Scroll icon" />
            </Button>
          </Fade>
        </B2BLandingStyles.TextContainer>
      </Fade>

      <B2BLandingStyles.HeroImageContainer
        direction={windowSize.width > 1150 ? 'right' : undefined}
        duration={1000}
        triggerOnce={true}
      >
        <B2BLandingStyles.HeroImage src={heroImage} alt="Woman charging electric car" />
      </B2BLandingStyles.HeroImageContainer>
    </B2BLandingStyles.LandingContainer>
  );
};
