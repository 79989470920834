import { StoreButtonsStyles } from './StoreButtons.styles';

import { Zoom } from 'react-awesome-reveal';
import { AppleButton } from './buttons/AppleButton';

/**
 * Duplicate of StoreButtons, but displaying only Apple/App Store button.
 *
 * TODO: Once Android Auto app starts getting marketed and StoreButtons replaces
 * this component in AppsInCar to show both Google and Apple buttons, this
 * whole component can be deleted.
 */
export const AppleStoreButtonSection = ({
  buttonClicked,
  delayAnimation,
}: {
  buttonClicked: (val: 'apple' | 'google') => void;
  delayAnimation?: number;
}) => {
  return (
    <StoreButtonsStyles.ButtonsContainer>
      <Zoom duration={500} delay={delayAnimation || 1000} triggerOnce cascade>
        <AppleButton onClick={() => buttonClicked('apple')} />
      </Zoom>
    </StoreButtonsStyles.ButtonsContainer>
  );
};
