import styled from '@emotion/styled';
import { Switch } from '@material-ui/core';
import { useDataTrackingEnabled } from 'core/shared/hooks/useDataTrackingEnabled';
import { FunctionComponent } from 'react';

// to vertically align accordion row content
const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

// to position switch at the far right end
const RowContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;

export const DataTrackingPolicyRow: FunctionComponent<{ heading: string }> = ({ heading }) => {
  const [dataTrackingEnabled, setDataTrackingEnabled] = useDataTrackingEnabled();

  return (
    <Container>
      {heading}
      <RowContainer>
        <Switch
          name="Data Tracking"
          checked={dataTrackingEnabled}
          onChange={(_, value) => {
            setDataTrackingEnabled(value);
          }}
          onClick={(e) => {
            // prevent expanding of accordion on toggle
            e.stopPropagation();
          }}
        />
      </RowContainer>
    </Container>
  );
};
