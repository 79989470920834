/* ******************************************************  Imports  ****************************************************** */
import styled from '@emotion/styled';
import { getColor } from '../../../theme/colors/colors';
import { getVar } from '../../../theme/ui-variables/ui-variables';

/* ******************************************************  Styles  ****************************************************** */
const LandingContainer = styled.div`
  width: 100%;
  background-color: ${getColor('primary')};
  padding: calc(${getVar('headerHeight')} + 10rem) 0 ${getVar('headerHeight')} 0;
`;

const Heading = styled.h2`
  color: ${getColor('primaryContrast')};
  width: 100%;
  max-width: ${getVar('maxWidth')};
  margin: 0 auto;
`;

const ImprintContainer = styled.div`
  width: 100%;
  max-width: ${getVar('maxWidth')};
  margin: 0 auto;
  padding: 7.8rem 0 15rem 0;
`;

const SubtitleContainer = styled.div`
  margin-bottom: 2.4rem;
  margin-top: 7.2rem;
`;

const Divider = styled.div`
  height: 0.1rem;
  margin-bottom: 2.4rem;
  background-color: ${getColor('adminBlack', 0.3)};
`;

const Row = styled.div`
  padding: 2.4rem 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 0.1rem solid ${getColor('adminBlack', 0.05)};

  @media screen and (max-width: 750px) {
    flex-direction: column;
  }
`;

const StyledH4 = styled.h4`
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  color: ${getColor('text')};
`;

const StyledH5 = styled.div`
  font-style: normal;
  font-weight: 800;
  font-size: 1.6rem;
  color: ${getColor('text')};
  width: 40%;

  @media screen and (max-width: 750px) {
    width: 100%;
  }
`;

const StyledText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  color: ${getColor('text')};
  width: 60%;

  @media screen and (max-width: 750px) {
    width: 100%;
    margin-top: 1rem;
  }
`;

const StyledText2 = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  color: ${getColor('text')};
  border-bottom: 0.1rem solid ${getColor('adminBlack', 0.05)};
  padding: 2.4rem 0;
`;

const StyledMiniRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1.6rem;
`;

const StyledIcon = styled.img`
  margin-right: 1rem;
`;

const StyledLink = styled.a`
  &[href] {
    color: ${getColor('primary')};
    text-decoration: underline;
    cursor: pointer;
  }
`;

/* ******************************************************  Export  ****************************************************** */
export const ImprintStyles = {
  ImprintContainer,
  LandingContainer,
  Heading,
  SubtitleContainer,
  Divider,
  Row,
  StyledH4,
  StyledH5,
  StyledText,
  StyledText2,
  StyledMiniRow,
  StyledIcon,
  StyledLink,
};
