/* ******************************************************  Imports  ****************************************************** */
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import TwentyTwenty from 'react-twentytwenty';
import { Button } from '@material-ui/core';

import { contactFormTopicService } from '../../../services/contact-form-topic.service';

import { B2BWhiteLabelStyles } from './B2BWhiteLabel.styles';
import Logo from '../../../../assets/images/logos/full-logo-whitelabel.svg';
import Wireframe from '../../../../assets/images/b2b/whitelabel-0.png';
import MockBlue from '../../../../assets/images//b2b/whitelabel-1.png';
import MockPink from '../../../../assets/images//b2b/whitelabel-2.png';
import MockGreen from '../../../../assets/images//b2b/whitelabel-3.png';
import { useAppAnalytics } from 'analytics';
import { Fade } from 'react-awesome-reveal';

/* ******************************************************  Component  ****************************************************** */
export const B2BWhiteLabel = () => {
  /* ******************************************************  State  ****************************************************** */
  const [sliderPosition, setSliderPosition] = useState<number>(0.5);
  const [sliderImage, setSliderImage] = useState<string>(MockPink);

  const { t } = useTranslation();

  /* ******************************************************  Logic  ****************************************************** */
  const switchImage = (image: string) => {
    setSliderImage(image);
    setSliderPosition(0.5);
  };

  /* *************************************************** APP INSIGHTS **************************************************** */
  const { trackEvent } = useAppAnalytics();

  /* ******************************************************  Template  ****************************************************** */
  return (
    <B2BWhiteLabelStyles.WhiteLabelContainer>
      <B2BWhiteLabelStyles.ContentContainer>
        <B2BWhiteLabelStyles.LogoImage src={Logo} alt="ElectricRoutes WhiteLabel logo" />

        <Fade direction="down" duration={1000} triggerOnce={true}>
          <B2BWhiteLabelStyles.TextContainer>
            <h2 className="mainText">{t('b2b.whiteLabel.main')}</h2>
            <p>{t('b2b.whiteLabel.sub')}</p>
          </B2BWhiteLabelStyles.TextContainer>
        </Fade>

        <B2BWhiteLabelStyles.SliderContainer
          direction="up"
          triggerOnce={true}
          duration={1000}
          fraction={0.8}
        >
          <TwentyTwenty
            left={<img src={Wireframe} alt="" />}
            right={<img src={sliderImage} alt="" />}
            slider={<B2BWhiteLabelStyles.CustomSlider />}
            position={sliderPosition}
            onChange={(event: number) => setSliderPosition(event > 0.995 ? 0.995 : event)}
          />
        </B2BWhiteLabelStyles.SliderContainer>

        <B2BWhiteLabelStyles.ButtonsContainer>
          <B2BWhiteLabelStyles.SliderSwitch
            onClick={() => switchImage(MockBlue)}
            style={{ color: '#33CCFF' }}
            className={sliderImage === MockBlue ? 'active' : ''}
          />
          <B2BWhiteLabelStyles.SliderSwitch
            onClick={() => switchImage(MockPink)}
            style={{ color: '#D76CA2' }}
            className={sliderImage === MockPink ? 'active middle' : 'middle'}
          />
          <B2BWhiteLabelStyles.SliderSwitch
            onClick={() => switchImage(MockGreen)}
            style={{ color: '#01D17B' }}
            className={sliderImage === MockGreen ? 'active' : ''}
          />
        </B2BWhiteLabelStyles.ButtonsContainer>

        <B2BWhiteLabelStyles.ButtonsContainer>
          <Button
            className="erButton noLink white"
            variant="contained"
            onClick={() => {
              window.scrollTo(0, document.body.scrollHeight);
              contactFormTopicService.changeTopic('WHITE_LABEL');
              trackEvent({
                action: '[B2B Offer - White Label Section] Contact Our Experts Clicked',
                category: 'B2B',
              });
            }}
          >
            {t('b2b.whiteLabel.contactOurExperts')}
          </Button>
        </B2BWhiteLabelStyles.ButtonsContainer>
      </B2BWhiteLabelStyles.ContentContainer>
    </B2BWhiteLabelStyles.WhiteLabelContainer>
  );
};
