/* ******************************************************  Imports  ****************************************************** */
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';

import { GetCustomTranslation } from '../../../shared/components/GetCustomTranslation';
import { contactFormTopicService } from '../../../services/contact-form-topic.service';

import { B2BOperatorStyles } from './B2BOperator.styles';
import logoBlue from '../../../../assets/images/logos/full-logo-challenge-blue.svg';
import { B2B_OPERATOR_DATA } from './operator.data';
import { useAppAnalytics } from 'analytics';
import { Fade } from 'react-awesome-reveal';

/* ******************************************************  Component  ****************************************************** */
export const B2BOperator = () => {
  /* ******************************************************  State  ****************************************************** */
  const { t } = useTranslation();

  /* *************************************************** APP INSIGHTS **************************************************** */
  const { trackEvent } = useAppAnalytics();

  /* ******************************************************  Template  ****************************************************** */
  return (
    <B2BOperatorStyles.OperatorContainer id="operatorPortal">
      <B2BOperatorStyles.LandingContainer>
        <Fade direction="left" triggerOnce={true} duration={1000}>
          <B2BOperatorStyles.LandingTextContainer>
            <img src={logoBlue} alt="ElectricRoutes Operator Portal logo" />

            <h2
              className="mainText"
              dangerouslySetInnerHTML={GetCustomTranslation('b2b.operator.landing.main')}
            ></h2>
            <p className="subText">{t('b2b.operator.landing.sub')}</p>

            <Fade direction="down" triggerOnce={true} duration={1000} delay={1000}>
              <Button
                className="erButton green noLink"
                variant="contained"
                onClick={() => {
                  window.scrollTo(0, document.body.scrollHeight);
                  contactFormTopicService.changeTopic('OPERATOR_PORTAL');
                  trackEvent({
                    action: '[B2B Offer - Operator Portal Section] Contact Our Experts Clicked',
                    category: 'B2B',
                  });
                }}
              >
                {t('b2b.operator.landing.contactOurExperts')}
              </Button>
            </Fade>
          </B2BOperatorStyles.LandingTextContainer>
        </Fade>
      </B2BOperatorStyles.LandingContainer>

      <B2BOperatorStyles.ContentContainer>
        <B2BOperatorStyles.TilesContainer>
          {B2B_OPERATOR_DATA.map((item, index) => (
            <B2BOperatorStyles.Tile
              key={item.title}
              direction={index % 2 === 0 ? 'left' : 'right'}
              duration={1000}
              triggerOnce={true}
            >
              <div>
                <img src={item.icon} alt="" />

                <h4 className="topText">{t(item.title)}</h4>
                {/*
                //TODO: Unfinished Prod
              */}
                {/* <p>{t(item.text)}</p> */}
              </div>
            </B2BOperatorStyles.Tile>
          ))}
        </B2BOperatorStyles.TilesContainer>

        <B2BOperatorStyles.ButtonsContainer>
          <Button
            className="erButton green noLink"
            variant="contained"
            onClick={() => {
              window.scrollTo(0, document.body.scrollHeight);
              contactFormTopicService.changeTopic('OPERATOR_PORTAL');
              trackEvent({
                action: '[B2B Offer - Operator Portal Section] Request Access Clicked',
                category: 'B2B',
              });
            }}
          >
            {t('b2b.operator.content.requestAccess')}
          </Button>
        </B2BOperatorStyles.ButtonsContainer>
      </B2BOperatorStyles.ContentContainer>
    </B2BOperatorStyles.OperatorContainer>
  );
};
