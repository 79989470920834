/* ******************************************************  Imports  ****************************************************** */
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MatomoProvider } from '@datapunt/matomo-tracker-react';

import { ThemeProvider } from '@material-ui/core/styles';
import { setThemeColor } from './core/theme/colors/colors';
import { muiTheme } from './core/theme/mui-theme/mui-theme';

import { APP_ROUTES } from './core/shared/appRoutes';

import { LanguageModel, LANGUAGES } from './core/shared/components/language-switcher/language.data';
import { LanguageContext, LanguageContextModel } from './core/language-context';

import { HomePage } from './core/pages/home/Home';
import { AppsPage } from './core/pages/apps/AppsPage';
import { B2BPage } from './core/pages/b2b/B2BPage';
import { AboutPage } from './core/pages/about/AboutPage';
import { ScrollToTop } from './core/shared/components/ScrollToTop';
import { Footer } from './core/shared/components/footer/Footer';
import { ErrorSnackbar } from './core/shared/components/error-snakcbar/ErrorSnackbar';
import { PrivacyPolicyEN } from './core/pages/legal/privacy-policy/PrivacyPolicyEN';
import { PrivacyPolicyDE } from './core/pages/legal/privacy-policy/PrivacyPolicyDE';
import { ImprintEN } from './core/pages/legal/imprint/ImprintEN';
import { ImprintDE } from './core/pages/legal/imprint/ImprintDE';
import { NotFoundPage } from './core/pages/not-found/NotFoundPage';
import { Header } from './core/shared/components/header/Header';
import { matomoInstance } from './analytics';

/* ******************************************************  Component  ****************************************************** */
export const App = () => {
  /* ******************************************************  Language and context  ****************************************************** */
  const { i18n } = useTranslation();

  const [languageContextValue, setLanguageContextValue] = useState<LanguageModel>('en');

  const languageProviderValue: LanguageContextModel = {
    languageContextValue,
    setLanguageContextValue,
  };

  /* ******************************************************  Custom console.log()  ****************************************************** */
  useEffect(() => {
    // Custom console log on first page load
    console.log(
      `%cRide. Charge. Repeat.`,
      'color:#0040FF; font-family: Roboto; font-size: 24px; font-weight: bold'
    );
    console.log(
      '%cELECTRIC ROUTES',
      'background-image: url(https://www.electricroutes.com/static/media/full-logo-blue.f93046e2.svg);background-repeat:no-repeat; background-position: 0 13px ;padding: 2rem 15rem;color:transparent'
    );
  }, []);

  /* ******************************************************  useEffect()  ****************************************************** */
  useEffect(() => {
    setThemeColor('light');

    /* Set language for translation */
    const currentLang: any = navigator.language.split('-')[0];

    const computedLanguage: LanguageModel =
      LANGUAGES.indexOf(currentLang) !== -1 ? currentLang : 'en';

    i18n.changeLanguage(computedLanguage);

    setLanguageContextValue(computedLanguage);
  }, [i18n]);

  /* ******************************************************  Template  ****************************************************** */
  return (
    <MatomoProvider value={matomoInstance}>
      <ThemeProvider theme={muiTheme}>
        <LanguageContext.Provider value={languageProviderValue}>
          <Router>
            <ScrollToTop />
            <Header />

            <Switch>
              <Route path={APP_ROUTES.about}>
                <AboutPage />
              </Route>
              <Route path={APP_ROUTES.apps}>
                <AppsPage />
              </Route>
              <Route path={APP_ROUTES.b2b}>
                <B2BPage />
              </Route>
              <Route path={`${APP_ROUTES.privacyPolicy}-en`}>
                <PrivacyPolicyEN />
              </Route>
              <Route path={`${APP_ROUTES.privacyPolicy}-de`}>
                <PrivacyPolicyDE />
              </Route>
              <Route path={`${APP_ROUTES.imprint}-en`}>
                <ImprintEN />
              </Route>
              <Route path={`${APP_ROUTES.imprint}-de`}>
                <ImprintDE />
              </Route>
              <Route path={APP_ROUTES.home} exact>
                <HomePage />
              </Route>
              <Route path="*">
                <NotFoundPage />
              </Route>
            </Switch>

            <Footer />
          </Router>
          <ErrorSnackbar />
        </LanguageContext.Provider>
      </ThemeProvider>
    </MatomoProvider>
  );
};
