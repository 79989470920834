/* ******************************************************  Imports  ****************************************************** */
import { useTranslation } from 'react-i18next';

import { StoreButtons } from '../store-buttons/StoreButtons';

import { AppsLandingStyles } from './AppsLanding.styles';
import appsLandingImage from '../../../../assets/images/apps/apps-landing.png';
import { GetCustomTranslation } from '../../../shared/components/GetCustomTranslation';
import { useAppAnalytics } from 'analytics';
import { Fade } from 'react-awesome-reveal';

/* ******************************************************  Component  ****************************************************** */
export const AppsLanding = () => {
  /* ******************************************************  Component  ****************************************************** */
  const { t } = useTranslation();

  /* *************************************************** APP INSIGHTS **************************************************** */
  const { trackEvent } = useAppAnalytics();

  const handleButtonClicked = (button: string) => {
    if (button === 'apple') {
      trackEvent({
        action: '[Mobile & Web App - Landing Section] App Store Button Clicked',
        category: 'Mobile & Web App',
      });
    } else {
      trackEvent({
        action: '[Mobile & Web App - Landing Section] Google Play Button Clicked',
        category: 'Mobile & Web App',
      });
    }
  };

  /* ******************************************************  Template  ****************************************************** */
  return (
    <AppsLandingStyles.LandingContainer>
      <Fade delay={500} duration={1500} fraction={0.79} triggerOnce={true}>
        <AppsLandingStyles.PhoneImage
          src={appsLandingImage}
          alt="3D smartphone model with screens of ElectricRoutes mobile app"
        />
      </Fade>

      <AppsLandingStyles.TextContainerFade direction="right" duration={1000} triggerOnce>
        <AppsLandingStyles.TextContainer>
          <h2
            className="mainText black"
            dangerouslySetInnerHTML={GetCustomTranslation('apps.landing.main')}
          ></h2>
          <p className="subText">{t('apps.landing.sub')}</p>

          <StoreButtons buttonClicked={handleButtonClicked} />
        </AppsLandingStyles.TextContainer>
      </AppsLandingStyles.TextContainerFade>
    </AppsLandingStyles.LandingContainer>
  );
};
