/* ******************************************************  Imports  ****************************************************** */
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

/* ******************************************************  Styles  ****************************************************** */
const NavigationContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 2rem;
  width: 100%;
`;

const LinksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const MenuContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &.legal {
    margin-top: 6rem;
  }
`;

const MenuHeading = styled.p`
  font-weight: 700;
  font-size: 2rem;
`;

const StyledLink = styled.a`
  font-weight: 400;
  font-size: 1.6rem;
  margin-top: 3.5rem;
  display: block;

  &[href]:hover {
    text-decoration: underline;
  }
`;

const MenuLink = styled(NavLink)`
  font-weight: 400;
  font-size: 1.6rem;
  margin-top: 3.5rem;
  display: block;

  &[href]:hover {
    text-decoration: underline;
  }
`;

const LanguageContainer = styled.div`
  margin-top: 2.5rem;

  & > div {
    margin-left: -1rem;
    justify-content: flex-end;

    /* & > button.MuiIconButton-root {
      font-size: 1.6rem;
      font-weight: 400;
    } */
  }
`;

const CopyrightContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 6rem;

  @media screen and (max-width: 1150px) {
    position: absolute;
    bottom: -15rem;
  }

  @media screen and (max-width: 499px) {
    flex-wrap: wrap;
    bottom: -20rem;
  }
`;

const CopyrightText = styled.p`
  font-size: 1.6rem;
  font-weight: 400;
  opacity: 0.4;
  width: 50%;

  & > span {
    display: block;
    width: 20rem;
  }

  @media screen and (max-width: 1150px) {
    width: 100%;
  }

  @media screen and (max-width: 499px) {
    & > span {
      width: 100%;
      margin-bottom: 3rem;
    }
  }
`;

const LogoImage = styled.img`
  //For responsive > FullHD
  height: 5.2rem;
  //For responsive > FullHD

  &.white {
    filter: brightness(0) invert(1);
  }

  @media screen and (max-width: 499px) {
    height: 4.2rem;
  }
`;

/* ******************************************************  Export  ****************************************************** */
export const FooterNavigationStyles = {
  NavigationContainer,
  LinksContainer,
  MenuContainer,
  MenuHeading,
  StyledLink,
  MenuLink,
  LanguageContainer,
  CopyrightContainer,
  CopyrightText,
  LogoImage,
};
