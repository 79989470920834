import {
  AccordionClassKey,
  AccordionDetailsClassKey,
  AccordionSummaryClassKey,
  StyleRules,
} from '@material-ui/core';
import { getColor } from '../../colors/colors';

export const CustomAccordion:
  | Partial<StyleRules<AccordionClassKey, {}>>
  | undefined = {
  root: {
    '&.MuiPaper-elevation1': {
      boxShadow: 'none',
      background: 'none',
    },
    '&:before': {
      backgroundColor: getColor('text', 0.1),
    },
  },
  rounded: {
    '&:first-child': {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    '&:last-child': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
};

export const CustomAccordionDetails:
  | Partial<StyleRules<AccordionDetailsClassKey, {}>>
  | undefined = {
  root: {
    display: 'block',
  },
};

export const CustomAccordionSummary:
  | Partial<StyleRules<AccordionSummaryClassKey, {}>>
  | undefined = {
  root: {
    minHeight: '5.8rem',
    '& .MuiIconButton-label': {
      color: getColor('text'),
    },
  },
  content: {
    color: getColor('text'),
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '1.6rem',
  },
};
