import Axios from 'axios-observable';
import { environment } from '../environment';

const createApi = (baseURL: string) => {
  return Axios.create({
    baseURL,
    headers: { 'x-api-key': environment.xApiKey },
  });
};

export const applicationService = createApi(
  environment.services.applicationService
);
