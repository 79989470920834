export const lightTheme = {
  primary: '#0040FF',
  primaryContrast: '#FFFFFF',
  primaryHover: '#002699',
  primaryDisabled: '#CCE6FF',

  accent: '#33CCFF',
  accentContrast: '#FFFFFF',
  accentHover: '#0BA6DA',
  accentDisabled: '#E5F9FF',

  error: '#FF6A4C',
  errorContrast: '#FFFFFF',
  errorHover: '#992500',
  errorDisabled: '#FFB199',
  errorMessage: '#F2330D',

  // ---------------------  Non MaterialUI colors  --------------------- //
  background: '#F5F5F5',
  whiteContrast: '#F6F7F9',
  blackContrast: '#2B2F35',

  text: '#333D4D',
  textGrey: '#B3BDCC',
  textApps: '#52464B',

  adminBlack: '#14181F',
  operatorGreen: '#1DC969',
  apiOrange: '#FF7008',
};

export const darkTheme = {
  primary: '#0040FF',
  primaryContrast: '#FFFFFF',
  primaryHover: '#002699',
  primaryDisabled: '#CCE6FF',

  accent: '#33CCFF',
  accentContrast: '#FFFFFF',
  accentHover: '#0BA6DA',
  accentDisabled: '#E5F9FF',

  error: '#FF6A4C',
  errorContrast: '#FFFFFF',
  errorHover: '#992500',
  errorDisabled: '#FFB199',
  errorMessage: '#F2330D',

  // ---------------------  Non MaterialUI colors  --------------------- //
  background: '#F5F5F5',
  whiteContrast: '#F6F7F9',
  blackContrast: '#2B2F35',

  text: '#333D4D',
  textGrey: '#B3BDCC',
  textApps: '#52464B',

  adminBlack: '#14181F',
  operatorGreen: '#1DC969',
  apiOrange: '#FF7008',
};
