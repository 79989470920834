import { APP_ROUTES } from './../../appRoutes';
interface HeaderLinkDataModel {
  text: string;
  route: string;
  exact?: boolean;
}

export const HeaderLinksData: HeaderLinkDataModel[] = [
  {
    text: 'header.home',
    route: APP_ROUTES.home,
    exact: true,
  },
  {
    text: 'header.apps',
    route: APP_ROUTES.apps,
  },
  {
    text: 'header.b2b',
    route: APP_ROUTES.b2b,
  },
  {
    text: 'header.about',
    route: APP_ROUTES.about,
  },
];
