/* ******************************************************  Imports  ****************************************************** */
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { getColor } from '../../../../theme/colors/colors';

const ping = keyframes`
  0% {box-shadow: 0 0 0 0 ${getColor('primaryContrast', 0.4)}}

  70% {box-shadow: 0 0 0 3rem ${getColor('primaryContrast', 0)}}

  100% {box-shadow: 0 0 0 0 ${getColor('primaryContrast', 0)}}
`;

/* ******************************************************  Styles  ****************************************************** */
const ItemContainer = styled.a`
  //TODO: Unfinished Prod
  pointer-events: none;

  display: flex;
  align-items: center;

  width: 100%;
  height: 6.5rem;
  margin-bottom: 3.5rem;
  padding: 0 2.65rem;
  overflow: hidden;

  border-radius: 2.4rem;
  background-color: ${getColor('primaryContrast', 0.1)};

  font-size: 2rem;
  font-weight: 700;
  color: ${getColor('primaryContrast')};

  transition: 0.5s;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    background-color: ${getColor('apiOrange', 0.5)};

    & img.arrow {
      animation: ${ping} 1.5s infinite;
    }
  }
`;

const Text = styled.span`
  flex: auto;
  margin: 0.5rem 2rem 0 2rem;
`;

const ApiIcon = styled.img`
  //For responsive > FullHD
  height: 3.2rem;
  //For responsive > FullHD
`;

const ArrowIcon = styled.img`
  border-radius: 50%;
  width: 2rem;
  //For responsive > FullHD
  height: 2rem;
  //For responsive > FullHD
`;

/* ******************************************************  Export  ****************************************************** */
export const ApiListItemStyles = {
  ItemContainer,
  Text,
  ApiIcon,
  ArrowIcon,
};
