/* ******************************************************  Imports  ****************************************************** */
import styled from '@emotion/styled';
import { getColor } from '../../../theme/colors/colors';
import { getVar } from '../../../theme/ui-variables/ui-variables';

/* ******************************************************  Styles  ****************************************************** */
const LandingContainer = styled.div`
  width: 100%;
  height: 80%;
  padding: ${getVar('headerHeight')} 0 13rem 0;
  background-color: ${getColor('primary')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Text = styled.h2`
  color: ${getColor('primaryContrast')};
  text-align: center;
  width: 100%;
  max-width: ${getVar('maxWidth')};
  margin: 0 auto;
`;

/* ******************************************************  Export  ****************************************************** */
export const AboutLandingStyles = {
  LandingContainer,
  Text,
};
